import React, {useState} from 'react';
import {Button, message, Modal, Popconfirm, Space} from 'antd';
import {addAreaApi, upDateArea, deleteArea} from '../../../api';
import {GETAREASETTINGLIST} from '../../../api/Urls';
import {useTranslation} from 'react-i18next';
import EditForms from '../../../components/forms/EditForms';
import CustomTables from '../../../components/customTable';
import './style.css';
import {timeFormat} from '../../../components/baseTime';
import {storage} from '../../../utils/storage';


const Area = () => {
  const {t} = useTranslation();
  const homeType =storage.getData( 'userInfo')?.buildingType===2?'map':'building';
  const [updateList, setUpdateList] = useState(0);
  const [onSaving, setOnSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initData, setInitData] = useState(null);
  const [modalTitle, setModalTitle] = useState('add');
  const columnsForm=[
    {
      label: 'id',
      name: 'areaId',
      type: 'number',
      hidden: true,
    },
    {
      label: 'areaName',
      name: 'areaName',
      type: 'primaryInput',
      required: true,
      message: 'Please input area name!',
      message2: '请输入区域名!',
    },
    {
      label: 'buildingLevel',
      name: 'buildingAndlevelName',
      type: 'AreaForm',
      required: homeType!=='map',
      disabled: homeType!=='map',
      message: 'Please select building name and level name!',
      message2: '请选择楼栋和楼层!',
      hidden: homeType==='map',
    },
    {
      label: 'overCrowding',
      name: 'maxWaitingNumber',
      type: 'number',
      min: 0,
      max: 9999,
      required: true,
      message: 'Please input over crowding!',
      message2: '请输入最大等待人数!',
      hidden: false,
    },
    {
      label: 'interval',
      name: 'intervalTime',
      type: 'number',
      min: 1,
      max: 9999,
      unit: 'min',
      required: true,
      message: 'Please input interval!',
      message2: '请输入逗留时间!',
      hidden: false,
    },
    {
      label: 'description',
      name: 'areaDescribe',
      type: 'textArea',
    },
  ];
  const onEdit = (data) => {
    setIsModalVisible(true);
    setModalTitle('edit');
    setInitData({
      ...data,
      buildingAndlevelName: [data.buildingId, data.buildingLevelId],
    });
  };

  const handleDelete = (id)=>{
    deleteArea(id, t('flag')).then((res)=>{
      message.success(res.msg);
      setIsModalVisible(false);
    }) .catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => setUpdateList(updateList + 1));
  };

  const onFinish = (values)=>{
    setOnSaving(true);
    if (homeType!=='map') {
      values.buildingId = values.buildingAndlevelName[0];
      values.buildingLevelId = values.buildingAndlevelName[1];
    }
    delete values.buildingAndlevelName;
    console.log(values);

    if (!values.areaDescribe) {
      delete values.areaDescribe;
    }
    if (modalTitle==='add') {
      delete values.areaId;
    }
    const RequestPromise = modalTitle==='add'?
        addAreaApi(values, t('flag')):
        upDateArea(values, t('flag'));

    RequestPromise.then((res)=>{
      message.success(res.msg);
      setOnSaving(false);
      setIsModalVisible(false);
      setModalTitle('add');
      setInitData(null);
      setUpdateList(updateList + 1);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>{
      setOnSaving(false);
    });
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setModalTitle('add');
    setInitData(null);
  };

  /*eslint-disable*/
  const columns = homeType!=='map'?[
    {
      title: t('id'),
      dataIndex: 'areaId',
      key: 'areaId',
      width: 60,
    },
    {
      title: t('areaName'),
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      search:true,
    },
    {
      title: t('buildingName'),
      dataIndex: 'buildingName',
      key: 'buildingName',
      onExport: true,
      search:true,
    },
    {
      title: t('levelName'),
      dataIndex: 'buildingLevelName',
      key: 'buildingLevelName',
      onExport: true,
      search:true,
    },
    {
      title: t('maxWaitingNumber'),
      dataIndex: 'maxWaitingNumber',
      key: 'maxWaitingNumber',
      onExport: true,
      render: (maxWaitingNumber, record)=>maxWaitingNumber+'/'+record.intervalTime+' min',
    },
    {
      title: t('describe'),
      dataIndex: 'areaDescribe',
      key: 'areaDescribe',
      onExport: true,
    },
    {
      title: t('createTime'),
      dataIndex: 'createTime',
      key: 'createTime',
      onExport: true,
      render: (createTime)=>timeFormat(createTime),
      exportRender: (createTime)=>timeFormat(createTime.createTime),
    },
    {
      title: t('operation'),
      key: 'Operation',
      width: 180,
      render: (record)=>(
        <Space>
          <Button type="link" size="small" onClick={() => {
            onEdit(record);
          }}>
            {t('edit')}
          </Button>
          <Popconfirm
            title={t('cameraDelMsg')}
            onConfirm={() => handleDelete(record.areaId)}
            color="#102864"
          >
            <Button type="link">{t('delete')}</Button>
          </Popconfirm>
        </Space>),
    },
  ]:[
    {
      title: t('id'),
      dataIndex: 'areaId',
      key: 'areaId',
      width: 60,
    },
    {
      title: t('areaName'),
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      search:true,
    },
    {
      title: t('maxWaitingNumber'),
      dataIndex: 'maxWaitingNumber',
      key: 'maxWaitingNumber',
      onExport: true,
      render: (maxWaitingNumber, record)=>maxWaitingNumber+'/'+record.intervalTime+' min',
    },
    {
      title: t('describe'),
      dataIndex: 'areaDescribe',
      key: 'areaDescribe',
      onExport: true,
    },
    {
      title: t('createTime'),
      dataIndex: 'createTime',
      key: 'createTime',
      onExport: true,
      render: (createTime)=>timeFormat(createTime),
      exportRender: (createTime)=>timeFormat(createTime.createTime),
    },
    {
      title: t('operation'),
      key: 'Operation',
      width: 180,
      render: (record)=>(
        <Space>
          <Button key={1} type="link" size="small" onClick={() => {
            onEdit(record);
          }}>
            {t('edit')}
          </Button>
          <Popconfirm
            title={t('cameraDelMsg')}
            onConfirm={() => handleDelete(record.areaId)}
            color="#102864"
          >
            <Button type="link">{t('delete')}</Button>
          </Popconfirm>
        </Space>),
    },
  ];
  /*eslint-disable*/
  
  return (
      <div>
        <CustomTables
            addFuc={() => {
              setIsModalVisible(true);
              setModalTitle('add');
            }}
            columns={columns}
            InitApi={GETAREASETTINGLIST}
            updateList={updateList}/>
        <Modal
            title={t(modalTitle)}
            destroyAll
            destroyOnClose
            visible={isModalVisible}
            footer={null}
            width={700}
            onCancel={onCancel}>
          <EditForms
              columnsForm={columnsForm}
              loading={onSaving}
              initData={initData}
              onCancel={onCancel}
              isEdit={modalTitle === 'edit'}
              onFinish={onFinish}
          />
        </Modal>
      </div>
  );
};

export default Area;
