import React, {useEffect, useState} from 'react';
import CustomTable from '../../components/customTable';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  // Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  // Switch,
  Drawer,
  Tag,
  Typography,
  message, Modal,
} from 'antd';

import {
  alarmTypeFuc,
  // alarmTypeFuc,
  AlarmTypeSelectOption, BuildCascader,
} from '../../components/baseTypeSelect';
import {GETALARMLIST} from '../../api/Urls';
import {
  getAlarmDetailsData,
  getAlertBaseMsgData,
  getAreaSettingList,
  getCameraListData,
  updateAlertStatus,
} from '../../api';
import fallBack from '../../assets/imgError.png';
import './style.css';
import moment from 'moment';
import {useSearchParams} from 'react-router-dom';
// import {STTodayValue} from '../../components/baseTime';
import DetailsSimpleModal from '../analytics/components/DetailsSimpleModal';
import DetailsDrawer from './components/DetailsDrawer';
import {storage} from '../../utils/storage';
// import PropTypes from 'prop-types';
const homeType =storage.getData( 'userInfo')?.buildingType===2?'map':'building';
const Alerts = () => {
  const {t} = useTranslation();
  const [params]= useSearchParams();
  const [form] = Form.useForm();
  const [processForm] = Form.useForm();
  const [areaOptions, setAreaOptions] = useState([]);
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [baseMsg, setBaseMsg] = useState({
    allNum: 0,
    processedNum: 0,
    unprocessedNum: 0,
  });
  const [updateList, setUpdateList] = useState(0);
  const [filters, setFilters] = useState({
    cameraId: params.get('cameraId'),
    startTime: params.get('startTime'),
    endTime: params.get('endTime'),
    id: params.get('id'),
  });
  const [cameraList, setCameraList] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoImage, setVideoImage] = useState('');
  const [details, setDetails] = useState([]);
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const getFullAreaList = ()=>{
    getAreaSettingList({
      pageNum: 1,
      pageSize: 100000,
    }, t('flag')).then((res)=>{
      setAreaOptions(res.data.items);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  const onFinish = (values) => {
    console.log(values, 'values提交的数据');
    const data={};
    Object.keys(values).forEach((key)=>{
      if (values[key]) {
        if (key==='date') {
          console.log(values.date);
          data.startTime=moment(values.date[0]).valueOf();
          data.endTime=moment(values.date[1]).valueOf();
        } else {
          data[key]=values[key];
        }
      }
    });
    if (homeType!=='map'&& data?.buildingLevelArea?.length>0) {
      console.log(areaOptions, 'areaOptions');
      data.buildingName=areaOptions?.filter((item)=>item.buildingId===data.buildingLevelArea[0])[0]?.buildingName;
      data.levelName=areaOptions?.filter((item)=>item.buildingLevelId===data.buildingLevelArea[1])[0]?.buildingLevelName;
      data.areaId=data.buildingLevelArea[2];
      delete data.buildingLevelArea;
    } else {
      data.areaId=data.buildingLevelArea;
      delete data.buildingLevelArea;
    }
    data.pageNum=1;
    console.log(data, 'data提交的数据');
    setFilters(data);
    setUpdateList(updateList + 1);
  };
  const getAlarmBaseMsg = () => {
    setIsStatsLoading(true);
    getAlertBaseMsgData(t('flag')).then((res) => {
      setBaseMsg(res.data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      setIsStatsLoading(false);
    });
  };
  const totalTime=(details)=>{
    if (details.length>0) {
      const index=details.length - 1;
      const time =(moment(details[index].leaveTime).valueOf()- moment(details[0].entryTime).valueOf())/1000;
      return time;
    }
    return 0;
  };
  const getCameraList = (areaID) => {
    getCameraListData(areaID, t('flag')).then((res) => {
      setCameraList(res.data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    });
  };
  const viewDetail=(id)=>{
    getAlarmDetailsData(id, t('flag')).then((res)=>{
      if (res.data.details) {
        setVideoUrl(res.data.details[0].videoUrl);
        setDetails(res.data.details);
        setDetailDrawer(true);
      } else {
        setShowDetail(true);
        setVideoUrl(res.data.videoUrl);
        setVideoImage(res.data.imageUrls.map((item)=>{
          return {
            showImageUrl: item,
          };
        }));
      }
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    });
  };
  const handleProcess = (values)=>{
    values.dealComment=values.dealComment || '';
    updateAlertStatus(values, t('flag')).then((res)=>{
      message.success(res.msg);
      setModalVisible(false);
      getAlarmBaseMsg();
      setUpdateList(updateList+1);
      processForm.resetFields();
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    });
  };

  const columns =homeType==='map'? [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      onExport: true,
      // eslint-disable-next-line react/display-name
      render: (id)=>(<Button
        type="link"
        onClick={() => {
          viewDetail(id);
        }}
      >
        {id}
      </Button>
      ),
    },
    {
      title: t('alertId'),
      dataIndex: 'alertId',
      key: 'alertId',
      onExport: true,
    },
    {
      title: t('recordImage'),
      dataIndex: 'recordImage',
      key: 'recordImage',
      // eslint-disable-next-line react/display-name
      render: (recordImage) => (
        <Image fallback={fallBack} src={recordImage} alt="Alert Image" className="img80"/>
      ),
    },
    {
      title: t('areaName'),
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
    },
    {
      title: t('cameraName'),
      dataIndex: 'cameraName',
      key: 'cameraName',
      onExport: true,
    },
    {
      title: t('alarmType'),
      dataIndex: 'eventType',
      key: 'eventType',
      render: (eventType)=>alarmTypeFuc(eventType),
      exportRender: (record)=>alarmTypeFuc(record.eventType),
      onExport: true,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      onExport: true,
      // eslint-disable-next-line react/display-name
      render: (status)=>(
        status===1?
          (<Tag color="success">{t('closeTicket')}</Tag>):(<Tag color="error">{t('openTicket')}</Tag>)),
      exportRender: (state) => state.state === 1 ? t('closeTicket') : t('openTicket'),
    },
    {
      title: t('alarmTime'),
      dataIndex: 'alarmTime',
      key: 'alarmTime',
      render: (alarmTime)=>moment(alarmTime).format('YYYY-MM-DD HH:mm:ss'),
      exportRender: (record)=>moment(record.alarmTime).format('YYYY-MM-DD HH:mm:ss'),
      onExport: true,
    },
    {
      title: t('comment'),
      dataIndex: 'dealComment',
      key: 'dealComment',
      onExport: true,
    },
    {
      title: t('action'),
      key: 'Action',
      width: 260,
      // eslint-disable-next-line react/display-name
      render: (record) => <Space>
        {record.status === 0 && (<Button type="link"
          onClick={() => {
            setModalVisible(true);
            processForm.setFieldsValue({
              id: record.id,
            });
          }}>{t('process')}</Button>)}</Space>,
    },
  ]: [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      onExport: true,
      // eslint-disable-next-line react/display-name
      render: (id)=>(<Button
        type="link"
        onClick={() => {
          viewDetail(id);
        }}
      >
        {id}
      </Button>
      ),
    },
    {
      title: t('alertId'),
      dataIndex: 'alertId',
      key: 'alertId',
      onExport: true,
    },
    {
      title: t('recordImage'),
      dataIndex: 'recordImage',
      key: 'recordImage',
      // eslint-disable-next-line react/display-name
      render: (recordImage) => (
        <Image fallback={fallBack} src={recordImage} alt="Alert Image" className="img80"/>
      ),
    },
    {
      title: t('buildingName'),
      dataIndex: 'buildingName',
      key: 'buildingName',
      onExport: true,
    },
    {
      title: t('levelName'),
      dataIndex: 'levelName',
      key: 'levelName',
      onExport: true,
    },
    {
      title: t('areaName'),
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
    },
    {
      title: t('cameraName'),
      dataIndex: 'cameraName',
      key: 'cameraName',
      onExport: true,
    },
    {
      title: t('alarmType'),
      dataIndex: 'eventType',
      key: 'eventType',
      render: (eventType)=>alarmTypeFuc(eventType),
      exportRender: (record)=>alarmTypeFuc(record.eventType),
      onExport: true,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      onExport: true,
      // eslint-disable-next-line react/display-name
      render: (status)=>(
        status===1?
          (<Tag color="success">{t('closeTicket')}</Tag>):(<Tag color="error">{t('openTicket')}</Tag>)),
      exportRender: (state) => state.state === 1 ? t('closeTicket') : t('openTicket'),
    },
    {
      title: t('alarmTime'),
      dataIndex: 'alarmTime',
      key: 'alarmTime',
      render: (alarmTime)=>moment(alarmTime).format('YYYY-MM-DD HH:mm:ss'),
      exportRender: (record)=>moment(record.alarmTime).format('YYYY-MM-DD HH:mm:ss'),
      onExport: true,
    },
    {
      title: t('comment'),
      dataIndex: 'dealComment',
      key: 'dealComment',
      onExport: true,
    },
    {
      title: t('action'),
      key: 'Action',
      width: 260,
      // eslint-disable-next-line react/display-name
      render: (record) => <Space>
        {record.status === 0 && (<Button type="link"
          onClick={() => {
            setModalVisible(true);
            processForm.setFieldsValue({
              id: record.id,
            });
          }}>{t('process')}</Button>)}</Space>,
    },
  ];
  const columnsDrawer=[
    {
      title: t('entryTime'),
      dataIndex: 'entryTime',
      key: 'entryTime',
    },
    {
      title: t('leaveTime'),
      dataIndex: 'leaveTime',
      key: 'leaveTime',
    },
    {
      title: t('action'),
      dataIndex: 'videoUrl',
      key: 'videoUrl',
      // eslint-disable-next-line react/display-name
      render: (videoUrl)=><Button type="link" onClick={()=>setVideoUrl(videoUrl)}>View Details</Button>,
    },
  ];
  useEffect(() => {
    getAlarmBaseMsg();
    getFullAreaList();
    getCameraList(0);
    if (params.get('cameraId')) {
      form.setFieldsValue({
        cameraId: parseInt(params.get('cameraId')),
      });
    }
    if (params.get('startTime')) {
      form.setFieldsValue({
        date: [moment(moment(moment().format('YYYY-MM-DD 00:00:00')).valueOf()), moment(moment().valueOf())],
      });
    }
    if (params.get('id')) {
      form.setFieldsValue({
        'id': params.get('id'),
      });
    }
  }, []);

  return (
    <div>
      <Spin spinning={isStatsLoading}>
        <Card bordered={false}>
          <Row gutter={[16, 8]}>
            <Col span={8} className="text-center">
              <Space size="large">
                <Space className='top-font'>{t('all')}</Space>

                <Typography.Text>{baseMsg.allNum}</Typography.Text>
                <Typography.Text className="text-gray">{t('since')}: {baseMsg.all_Since}</Typography.Text>
              </Space>
            </Col>
            <Col span={8} className="text-center">
              <Space size="large">
                <Space className='top-font'>{t('closeTicket')}</Space>

                <Typography.Text>{baseMsg.processedNum}</Typography.Text>
                <Typography.Text className="text-gray">{t('since')}: {baseMsg.pro_Since}</Typography.Text>
              </Space>
            </Col>
            <Col span={8} className="text-center">
              <Space size="large">
                <Space className='top-font'>{t('openTicket')}</Space>
                <Typography.Text>{baseMsg.unprocessedNum}</Typography.Text>
                <Typography.Text className="text-gray">{t('since')}: {baseMsg.un_Since}</Typography.Text>
              </Space>
            </Col>
          </Row>
        </Card>
      </Spin>
      <Form onFinish={onFinish} form={form} layout="inline" className="margin-t-16">

        <Form.Item
          name="buildingLevelArea"
          label={homeType!=='map'?t('buildingLevelArea'):t('area')}
          className="padding-b-16"
        >{homeType==='map'?( <Select style={{width: 160}}>
          {areaOptions.map(({areaId, areaName}) => (
            <Select.Option key={areaId + 'area'} value={areaId}>{
              areaName
            }</Select.Option>
          ))}
        </Select>):(<BuildCascader type='multilayer' onChange={(v) => {
          if (v&&v.length === 3) {
            getCameraList(v[v.length - 1]);
          } else {
            getCameraList(0);
          }
          form.setFieldsValue({
            cameraId: null,
          });
        }}/>)}


        </Form.Item>

        <Form.Item
          className="padding-b-16"
          label={t('cameraName')} name='cameraId'>
          <Select style={{width: 160}}>
            <Select.Option key="All" value="">{t('all')}</Select.Option>
            {cameraList.map((item) => (
              <Select.Option key={`camera${item.id}`} value={item.id}>{item.cameraName}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="padding-b-16"
          label={t('alarmType')} name='alarmType'>
          <AlarmTypeSelectOption/>
        </Form.Item>

        <Form.Item
          label={t('status')}
          name="status"
          className="padding-b-16"
        >
          <Select style={{width: 160}}>
            <Select.Option key="processed" value={'1'}>{t('closeTicket')}</Select.Option>
            <Select.Option key="unprocessed" value={'0'}>{t('openTicket')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="padding-b-16"
          label={t('alertDate')} name="date">
          <DatePicker.RangePicker
            showTime={{format: 'HH:mm'}}
            format="YYYY-MM-DD HH:mm"
            style={{width: '100%'}}
          />
        </Form.Item>

        <Form.Item
          className="padding-b-16"
          label={t('alertId')}
          name="alertId">
          <Input className="theme-input"/>
        </Form.Item>
        <Form.Item
          className="padding-b-16"
          label={t('id')}
          name="id">
          <Input className="theme-input" type='number' />
        </Form.Item>
        <div className="button-right">
          <Form.Item
            noStyle
            className="padding-b-16"
          >

            <Button type="normal" htmlType="submit">{t('search')}</Button>
            <Button htmlType="reset" className="margin-l-8" >{t('reset')}</Button>
          </Form.Item>
        </div>


      </Form>
      <CustomTable rowId={'alertId'} InitApi={GETALARMLIST} updateList={updateList} columns={columns} params={filters}/>
      <Modal
        width={800}
        title={t('details')}
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        footer={null}
        destroyOnClose
      >
        <DetailsSimpleModal type="videoImages" videoUrl={videoUrl} details={videoImage}/>
      </Modal>
      <Modal
        title='Process'
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          processForm.resetFields();
        }}
        width={482}
        footer={null}
        destroyOnClose>
        <div className='setting-form-wrap' style={{padding: '0px 48px 0 16px'}}>
          <div style={{marginBottom: 8}}>{t('comment')}</div>
          <Form
            form={processForm}
            onFinish={(values)=>{
              handleProcess(values);
            }}>
            <Form.Item name="id" hidden>
              <Input.TextArea rows={8}/>
            </Form.Item>
            <Form.Item name="dealComment">
              <Input.TextArea rows={8}/>
            </Form.Item>
            <Form.Item>
              <div>
                <Button className="transparent-btn marginR16" onClick={()=>{
                  setModalVisible(false);
                  processForm.resetFields();
                }}>{t('cancel')}</Button>
                <Button className="full-btn marginR16" htmlType="submit" style={{float: 'right'}}>
                  {t('process')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Drawer
        width={800}
        title={t('details')}
        placement="right"
        onClose={()=>{
          setDetails([]);
          setDetailDrawer(false);
        }}
        visible={detailDrawer}>
        <DetailsDrawer detailList={details} columnsDrawer={columnsDrawer} videoUrl={videoUrl} />
        <h2 className="paddingL16 marginT16">Possible Dwell Time: {totalTime(details)}s</h2>
      </Drawer>
    </div>
  );
};

Alerts.propTypes = {};

export default Alerts;
