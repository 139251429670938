import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Col, message, Row, Skeleton, Form, Select, Button, Space, Modal} from 'antd';
import {Link} from 'react-router-dom';
import {
  getAlarmBarChartMsgData,
  getAlarmLineChartMsgData,
  getAlarmPieChartMsgData,
  getHomeBasicMsgData, getHomeMapAlertListData,
} from '../../api';
import './style.css';
import {ZoomInOutlined} from '@ant-design/icons';
import {AlarmTypeSelectOption, alarmTypeFuc} from '../../components/baseTypeSelect';
import PieCharts from './components/PieCharts';
import LineCharts from './components/LineCharts';
import BarCharts from './components/BarCharts';
import FloorMaps from './components/FloorMaps';
import Maps from './components/Maps';
import {storage} from '../../utils/storage';

const Home = () => {
  const {t} = useTranslation();
  const initDateType=1;

  const homeType =storage.getData( 'userInfo')?.buildingType===1?'building':'map';

  // Total Counts
  const [loading, setLoading] = useState(false);
  const [cameraNumber, setCameraNumber] = useState(null);
  const [cameraOnlineNumber, setCameraOnlineNumber] = useState(null);
  const [todayIncident, setTodayIncident] = useState(null);
  const [allVisitors, setAllVisitors] = useState(null);
  const [newVisitors, setNewVisitors] = useState(null);
  const [returnVisitors, setReturnVisitors] = useState(null);
  const [indexMapResponse, setIndexMapResponse] = useState([]);

  // charts
  const [alarmType, setAlarmType] = useState('');
  const [chartModalShow, setChartModalShow] = useState(false);
  const [chartModalTitle, setChartModalTitle] = useState(null);

  const [pieDate, setPieDate] = useState(initDateType);
  const [pieData, setPieData] = useState([]);
  const [pieDataLoading, setPieDataLoading] = useState(true);

  const [barDate, setBarDate] = useState(initDateType);
  const [barData, setBarData] = useState([]);
  const [barDataLoading, setBarDataLoading] = useState(true);

  const [lineDate, setLineDate] = useState(initDateType);
  const [lineData, setLineData] = useState([]);
  const [lineDataLoading, setLineDataLoading] = useState(true);
  // search select
  const DateTypeSelect=(props)=>(
    <Select
      {...props}
    >
      <Select.Option key="Today" value={1}>{t('1day')}</Select.Option>
      <Select.Option key="Last3days" value={2}>{t('3days')}</Select.Option>
      <Select.Option key="Last7days" value={3}>{t('7days')}</Select.Option>
      <Select.Option key="Last30days" value={4}>{t('30days')}</Select.Option>
    </Select>);

  // charts filter
  const onFinish = (values) => {
    setAlarmType(values.alarmType);
    getAlarmBarChartMsg(values);
    getAlarmLineChartMsg(values);
    getAlarmPieChartMsg(values);
  };

  const getBasicMsg = (data) => {
    setLoading(true);
    getHomeBasicMsgData(t('flag')).then((res) => {
      setCameraNumber(res.data.cameraNumber);
      setCameraOnlineNumber(res.data.cameraOnlineNumber);
      setTodayIncident(res.data.todayIncident);
      setAllVisitors(res.data.allVisitors);
      setNewVisitors(res.data.newVisitors);
      setReturnVisitors(res.data.returnVisitors);
    }).catch((error) => {
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => setLoading(false));
  };

  const getAlarmPieChartMsg = (values) => {
    setPieDataLoading(true);
    setPieDate(values.dateType);
    getAlarmPieChartMsgData(values, t('flag')).then((res)=>{
      const pieData = [];
      res.data.map((item) => {
        const type = alarmTypeFuc(item.alarmType);
        pieData.push({alarmType: type, value: parseInt(item.value)});
      });
      setPieData(pieData);
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>{
      setPieDataLoading(false);
    });
  };
  const getAlarmBarChartMsg=(values)=>{
    setBarDataLoading(true);
    setBarDate(values.dateType);
    getAlarmBarChartMsgData(values, t('flag')).then((res)=>{
      setBarData(res.data);
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>{
      setBarDataLoading(false);
    });
  };
  const getAlarmLineChartMsg=(values)=>{
    setLineDataLoading(true);
    setLineDate(values.dateType);
    getAlarmLineChartMsgData(values, t('flag')).then((res)=>{
      if (res.code===200) {
        const LineData = [];
        res.data.forEach((item) => {
          const type = alarmTypeFuc(item.alarmType!==0?item.alarmType:alarmType);
          LineData.push({date: item.staticsDate, value: item.alarmCount, category: type});
          // }
        });
        setLineData(LineData);
      }
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setLineDataLoading(false);
    });
  };
  const getMapAlert=()=>{
    if (homeType!=='map') {
      getHomeMapAlertListData({
        dateType: 1,
        building: 1,
      }, t('flag')).then((res) => {
        console.log(res, 'setIndexMapResponse');
        setIndexMapResponse(res.data);
      }).catch((error) => {
        message.error({
          content: error ? error.toString() : t('apiError'),
          key: 'netError',
          duration: 2,
        });
      }).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getMapAlert();
    getBasicMsg();
    getAlarmBarChartMsg({dateType: initDateType});
    getAlarmLineChartMsg({dateType: initDateType});
    getAlarmPieChartMsg({dateType: initDateType});
  }, [
    t('flag'),
  ]);

  return (
    <div>
      <Card bordered={false} size="small">
        {!loading ? (<Row gutter={[0, 0]} className="border-b padding8">
          <Col span={4} className="text-center">
            <Link to="/console/camera/218/238">{t('cameraNumber')}</Link>
          </Col>
          <Col span={4} className="text-center">
            <Link to="/console/camera/218/238" onClick={() => {
            }}>{t('onlineCamera')}</Link>
          </Col>
          <Col span={4} className="text-center">
            <Link to="/console/alerts/215" onClick={() => {
            }}>{t('todayAlerts')}</Link>
          </Col>
          <Col span={4} className="text-center">
            <Link to="/console/visitHistory/216/230" onClick={() => {
            }}>{t('allVisitors')}</Link>
          </Col>
          <Col span={4} className="text-center">
            <Link to="/console/visitHistory/216/230/?source=1" onClick={() => {
            }}>{t('returnVisitors')}</Link>
          </Col>
          <Col span={4} className="text-center">
            <Link to="/console/visitHistory/216/230?source=2" onClick={() => {
            }}>{t('newVisitors')}</Link>
          </Col>
        </Row>) : (
          <Row gutter={[0, 0]} className="border-b padding8">
            <Col span={24}><Skeleton.Button active block/></Col>
          </Row>
        )}
        {!loading ? (<Row gutter={[0, 0]} className="padding8">
          <Col span={4} className="text-center">
            {cameraNumber ? cameraNumber : 0} / 1000
          </Col>
          <Col span={4} className="text-center">
            {cameraOnlineNumber} / {cameraNumber ? cameraNumber : 0}
          </Col>
          <Col span={4} className="text-center">
            {todayIncident||0}
          </Col>
          <Col span={4} className="text-center">
            {allVisitors||0}
          </Col>
          <Col span={4} className="text-center">
            {returnVisitors||0}
          </Col>
          <Col span={4} className="text-center">
            {newVisitors||0}
          </Col>
        </Row>) : (
          <Row gutter={[0, 0]} className="padding8">
            <Col span={24}><Skeleton.Button active block/></Col>
          </Row>
        )}
      </Card>
      <Row gutter={[16, 16]} className="marginLR0">
        <Col span={11}>

          <Form onFinish={onFinish} layout="inline" className="margin-t-16 margin-b-16">
            <Row
              gutter={[16, 16]}
            >
              <Col span={12}>
                <Form.Item label={t('alarmType')} name="alarmType" initialValue={''}>
                  <AlarmTypeSelectOption />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t('date')} initialValue={initDateType} name="dateType">
                  <DateTypeSelect/>
                </Form.Item>
              </Col>
              <Col span={4} className="text-right">
                <Form.Item>
                  <Button
                    type="normal"
                    htmlType="submit"
                  >
                    {t('searchBtn')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title={t('alertIncident')} size="small" className="height360" extra={
                <Space size="small">
                  <DateTypeSelect
                    value={pieDate}
                    onChange={(value) => getAlarmPieChartMsg({
                      dateType: value,
                      alarmType,
                    })}
                    className='cards-select'
                    size='small'
                  />

                  <Button
                    type="link"
                    shape="circle"
                    onClick={() => {
                      setChartModalShow(true);
                      setChartModalTitle('alertIncident');
                    }}
                    icon={<ZoomInOutlined/>}
                  />
                </Space>
              }>
                <PieCharts Height={'290px'} Data={pieData} Loading={pieDataLoading}/>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={t('incidentSource')} size="small" className="height360" extra={
                <Space size="small">
                  <DateTypeSelect
                    value={barDate}
                    onChange={(value) => getAlarmBarChartMsg({
                      dateType: value,
                      alarmType,
                    })}
                    className='cards-select'
                    size='small'
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      setChartModalShow(true);
                      setChartModalTitle('incidentSource');
                    }}
                    shape="circle"
                    icon={<ZoomInOutlined/>}
                  />
                </Space>
              }>
                <BarCharts Height='290px' Data={barData} Loading={barDataLoading} Legend={false}/>
              </Card>
            </Col>
            <Col span={24}>
              <Card title={t('trend')} size="small" className="height360" extra={
                <Space size="small">
                  <DateTypeSelect
                    value={lineDate}
                    onChange={(value) => getAlarmLineChartMsg({
                      dateType: value,
                      alarmType,
                    })}
                    className='cards-select'
                    size= 'small'
                  />
                  <Button type="link" onClick={() => {
                    setChartModalShow(true);
                    setChartModalTitle('trend');
                  }} shape="circle" icon={<ZoomInOutlined/>}/>
                </Space>
              }>
                <LineCharts Height='290px' Data={lineData} Loading={lineDataLoading}/>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={13} className="padding-t-20">
          {homeType==='map'?(<Maps />):(
            <FloorMaps
              indexMapResponse={indexMapResponse}
              allAlarmType=''
              dateType={1}
            />
          )}
        </Col>
      </Row>
      <Modal
        width={880}
        title={t(chartModalTitle)}
        visible={chartModalShow}
        onCancel={()=>{
          setChartModalShow(false);
          setChartModalTitle(null);
        }} footer={null}>
        <Row>
          {chartModalTitle==='alertIncident'&&(<Col offset={2} span={20}>
            <PieCharts Height={'420px'} Data={pieData} Loading={pieDataLoading}/>
          </Col>)}
          {chartModalTitle==='trend'&&(<div className="padding32 width80P">
            <LineCharts Height={'420px'} Data={lineData} Loading={lineDataLoading}/>
          </div>)}
          {chartModalTitle==='incidentSource'&&(<div className="padding32 width80P">
            <BarCharts Height={'420px'} Data={barData} Loading={barDataLoading} Legend={true}/>
          </div>)}
        </Row>
      </Modal>
    </div>
  );
};

Home.propTypes = {};

export default Home;
