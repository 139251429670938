import React, {useState} from 'react';
import {Button, message, Modal, Popconfirm, Space, Image, Upload, Form} from 'antd';
import {addNewLevel, updateLevel, deleteLevel} from '../../../api';
import {LEVELLIST} from '../../../api/Urls';
import {columnsForm} from './config';
import {useTranslation} from 'react-i18next';
import EditForms from '../../../components/forms/EditForms';
import CustomTables from '../../../components/customTable';
import ErrorImg from '../../../assets/imgError.png';

const Level =() => {
  const {t} = useTranslation();
  /*eslint-disable*/
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: t('buildingName'),
      dataIndex: 'buildingName',
      key: 'buildingName',
      onExport: true,
      search:true,
    },
    {
      title: t('levelName'),
      dataIndex: 'levelName',
      key: 'levelName',
      onExport: true,
      search:true,
    },
    {
      title: t('photo'),
      dataIndex: 'levelPlanUrl',
      key: 'levelPlanUrl',
      render: (r)=>(<Image src={r} alt='error' preview={true} style={{width: 80, height: 80}} fallback={ErrorImg}/>),
    },
    {
      title: t('operation'),
      key: 'Operation',
      render: (record)=>(
        <Space>
          <Button type="link" size="small" onClick={() =>
            onEdit(record)
          }>
            {t('edit')}
          </Button>
          <Popconfirm
            title={t('cameraDelMsg')}
            onConfirm={() => handleDelete(record.id)}
            color="#102864"
          >
            <Button type="link">{t('delete')}</Button>
          </Popconfirm>
        </Space>),
    },
  ];
  /*eslint-disable*/
  const [updateList, setUpdateList] = useState(0);
  const [onSaving, setOnSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initData, setInitData] = useState(null);
  const [modalTitle, setModalTitle] = useState('add');

  const onEdit = async (data) => {
    setIsModalVisible(true);
    setModalTitle('edit');
    const array = [...fileList];
    array[currentImgIndex].file=data.levelPlanUrl;
    array[currentImgIndex].url=data.levelPlanUrl;
    await setFileList(array);
    data.image = array;
    setInitData({
      ...data,
    });
  };

  const handleDelete = (id)=>{
    deleteLevel(id, t('flag')).then((res)=>{
      message.success(res.msg);
      setIsModalVisible(false);
    }).catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => setUpdateList(updateList + 1));
  };

  const onFinish = (values)=>{
    console.log(11, values, fileList);
    setOnSaving(true);
    const formData = new FormData();
    formData.append('buildingId', values.buildingId);
    formData.append('levelName', values.levelName);

    if (fileList[0].file.originFileObj) {
      formData.append('file', fileList[0].file.originFileObj);
    }
    if (modalTitle!=='add') {
      formData.append('id', values.id);
      formData.append('levelPlanUrl', values.image[0].url);
    }

    const RequestPromise = modalTitle==='add'?
        addNewLevel(formData, t('flag')):
        updateLevel(formData, t('flag'));

    RequestPromise.then((res)=>{
      message.success(res.msg);
      setOnSaving(false);
      setIsModalVisible(false);
      setModalTitle('add');
      setInitData(null);
      setUpdateList(updateList + 1);
      setFileList([{file: null, url: ''}]);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>{
      setOnSaving(false);
    });
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setModalTitle('add');
    setInitData(null);
    setFileList([{file: null, url: ''}]);
  };

  const MAXSIZE = 5*1024*1024;// 5M
  const [fileList, setFileList] = useState([{file: null, url: ''}]);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const propsObj = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: ()=>{},
    onChange: (e)=>{
      if (e.file.size<MAXSIZE) {
        const array = [...fileList];
        array[currentImgIndex].file=e.file;
        getBase64(e.file.originFileObj, (imageUrl)=>{
          array[currentImgIndex].url=imageUrl;
          setFileList(array);
        });
      } else {
        message.warn('The picture is too large, please upload the picture less than 5MB.');
      }
    },
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const changeColumnsForm = () => {
    columnsForm.forEach((item)=>{
      if (item.type==='image'){
        item.image =(<Form.Item
            hidden={item.hidden}
            name={'image'}
            label={t('image')}
            key={item.name+item.type}
            rules={[{
              required: item.required,
              message: item['message'+t('flag')],
            }]}
        >
          <div className="bs-upload-box">
            {/* <Space size="small" wrap className=""> */}
              {fileList.map((item, index) => (
                  <div className="bs-upload-item" key={`image_${index}`}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        {...propsObj}
                        onClick={() => setCurrentImgIndex(index)}
                    >
                      {item.file === null ? <div style={{fontSize: 20}}>+</div> :
                          <div className="uploadBox">
                            <div className="upload_delete" onClick={(e) =>{
                              const array = [...fileList];
                              array[index] = {file: null, url: ''};
                              setFileList(array);
                              form.setFieldsValue({'image': null});
                              e.stopPropagation();
                            }}>X</div>
                            <img src={item.url} alt="avatar" style={{maxWidth: 68, maxHeight: 60}} />
                          </div>}
                    </Upload>
                  </div>
              ))}
            {/* </Space> */}
          </div>
        </Form.Item>)
      }
    })
    return columnsForm
  }
  return (
    <div>
      <CustomTables
        addFuc={() => {
          setIsModalVisible(true);
          setModalTitle('add');
        }}
        columns={columns}
        InitApi={LEVELLIST}
        updateList={updateList}/>
      <Modal
        title={t(modalTitle)}
        destroyAll
        destroyOnClose
        visible={isModalVisible}
        footer={null}
        width={700}
        onCancel={onCancel}>
        <EditForms
          columnsForm={changeColumnsForm()}
          loading={onSaving}
          initData={initData}
          onCancel={onCancel}
          isEdit={modalTitle === 'edit'}
          onFinish={onFinish}
        />
      </Modal>
    </div>
  );
};

export default Level;
