import React, {useState} from 'react';
import {Button, message, Modal, Popconfirm, Space, Switch} from 'antd';
import {addBuilding as addBuildingApi, updateBuilding, deleteBuilding} from '../../../api';
import {BUILDINGLIST} from '../../../api/Urls';
import {columnsForm} from './config';
import {useTranslation} from 'react-i18next';
import EditForms from '../../../components/forms/EditForms';
import CustomTables from '../../../components/customTable';

const Building =() => {
  const {t} = useTranslation();
  /*eslint-disable*/
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: t('buildingName'),
      dataIndex: 'buildingName',
      key: 'buildingName',
      onExport: true,
      search: true,
    },
    {
      title: t('buildingFullName'),
      dataIndex: 'buildingFullName',
      key: 'buildingFullName',
      onExport: true,
      search: true,
    },
    {
      title: t('cameraNumber'),
      dataIndex: 'cameraNumber',
      key: 'cameraNumber',
      onExport: true,
      search: true,
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
      width: 500,
      onExport: true,
    },
    {
      title: t('switch'),
      dataIndex: 'isEnable',
      key: 'isEnable',
      width: 180,
      render: (record, rowData) => (
        <Switch size="small" checked={parseInt(record)===1} onChange={()=> {
          setModalTitle('edit');
          onChange(record, rowData)
        }}/>),
    },
    {
      title: t('action'),
      key: 'Operation',
      width: 180,
      render: (record)=>(
        <Space>
          <Button type="link" size="small" onClick={() => {
            onEdit(record);
          }}>
            {t('edit')}
          </Button>
          <Popconfirm
            title={t('cameraDelMsg')}
            onConfirm={() => handleDelete(record.id)}
            color="#102864"
          >
            <Button type="link">{t('delete')}</Button>
          </Popconfirm>
        </Space>),
    },
  ];
  /*eslint-disable*/

  const [updateList, setUpdateList] = useState(0);
  const [onSaving, setOnSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initData, setInitData] = useState(null);
  const [modalTitle, setModalTitle] = useState('add');
  const onEdit = (data) => {
    setIsModalVisible(true);
    setModalTitle('edit');
    setInitData({
      ...data,
    });
  };

  const onFinish = (values, type)=>{
    setOnSaving(true);

    const RequestPromise = (modalTitle === 'add'&&!type) ?
            addBuildingApi(values, t('flag')):
        updateBuilding(values, t('flag'));

    RequestPromise.then((res)=> {
      message.success(res.msg);
      setOnSaving(false);
      setIsModalVisible(false);
      setModalTitle('add');
      setInitData(null);
      setUpdateList(updateList + 1);
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setOnSaving(false);
    });
  };
  const handleDelete = (id)=>{
    deleteBuilding(id, t('flag')).then((res)=>{
      message.success(res.msg);
      setIsModalVisible(false);
    }) .catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => setUpdateList(updateList + 1));
  };

  const onChange=(checked, rowData)=> {
    if (checked===1) {
      rowData.isEnable =0;
    } else {
      rowData.isEnable =1;
    }
    onFinish(rowData,'edit');
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setModalTitle('add');
    setInitData(null);
  };
  return (
    <div>
      <CustomTables
        addFuc={() => {
          setIsModalVisible(true);
          setModalTitle('add');
          setInitData(null);
        }}
        columns={columns}
        InitApi={BUILDINGLIST}
        updateList={updateList}/>
      <Modal
        title={t(modalTitle)}
        destroyAll
        destroyOnClose
        visible={isModalVisible}
        footer={null}
        width={700}
        onCancel={onCancel}>
        <EditForms
          columnsForm={columnsForm}
          loading={onSaving}
          initData={initData}
          onCancel={onCancel}
          isEdit={modalTitle === 'edit'}
          onFinish={onFinish}
        />
      </Modal>
    </div>
  );
};

export default Building;
