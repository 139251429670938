import moment from 'moment';

export const STTodayValue= moment(moment().format('YYYY-MM-DD 00:00:00')).valueOf();
export const ETTodayValue=moment().valueOf();
export const TodayMoment = [moment(STTodayValue), moment(ETTodayValue)];

export const timeFormat=(value)=> {
  if (value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};
