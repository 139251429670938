import React from 'react';
import PropTypes from 'prop-types';
import {Pie} from '@ant-design/charts';
import {Spin} from 'antd';

const PieCharts =({Height, Data, Loading})=> {
  const config = {
    appendPadding: 10,
    data: Data,
    theme: 'dark',
    angleField: 'value',
    colorField: 'alarmType',
    radius: 0.9,
    label: {
      type: 'outer',
      content: ({percent}) => `${(percent * 100).toFixed(0)}%`,
    },
    legend: 'horizontal',
    tooltip: {
      formatter: (datum) => {
        return {name: datum.alarmType, value: datum.value};
      },
    },
    loading: Loading,
    loadingTemplate: (<div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,.9)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Spin spinning={true}/>
    </div>),

    interactions: [{type: 'element-active'}],
    color: [
      '#5887F6',
      '#59CD90',
      '#EA526F',
      '#FFBABA',
      '#28C4BD',
      '#099CFC',
      '#CE66CA',
      '#5AD4FE',
      '#D75353',
      '#3F6BD3',
      '#FFB05D',
      '#FF5EC2',
      '#D75353',
    ],
    // pieStyle: function pieStyle(_ref) {
    //   const alarmType = _ref.alarmType;
    //   console.log(_ref);
    //   switch (alarmType) {
    //     case 'Waiting Number':
    //       return {fill: '#5887F6'};
    //     case 'Dwell Time':
    //       return {fill: '#59CD90'};
    //     case 'Falling':
    //       return {fill: '#EA526F'};
    //     case 'Social Distancing':
    //       return {fill: '#FFBABA'};
    //     case 'NOT Wearing Mask':
    //       return {fill: '#28C4BD'};
    //     case 'Person of Interest':
    //       return {fill: '#099CFC'};
    //     case 'Running':
    //       return {fill: '#CE66CA'};
    //     case 'Loitering':
    //       return {fill: '#5AD4FE'};
    //     case 'Vigorous Activity >= 2 person':
    //       return {fill: '#D75353'};
    //     case 'Overstayers':
    //       return {fill: '#3F6BD3'};
    //     case 'New Item Detected':
    //       return {fill: '#FFB05D'};
    //     case 'NOT Wearing Mask Properly':
    //       return {fill: '#FF5EC2'};
    //     default:
    //       return {fill: '#D75353'};
    //   }
    // },
  };
  return (

    <Pie {...config} style={{height: Height}}/>

  );
};

PieCharts.propTypes = {
  Data: PropTypes.arrayOf(PropTypes.object).isRequired,
  Height: PropTypes.string.isRequired,
  Loading: PropTypes.bool.isRequired,
};

export default PieCharts;
