/* eslint-disable max-len */
import * as Urls from './Urls';
import {request} from './ajax';

// login
export const userLogin = (params, flag) => request(Urls.LOCALSIGNIN, params, 'POST', flag, {
  'Content-Type': 'application/x-www-form-urlencoded',
  'token': null,
  'building': null,
});
export const getVerify = (flag) => request(Urls.GETVERIFY, null, 'GET', flag, {
  'Content-Type': 'application/json',
  'token': null,
  'building': null,
});

// header

export const getAlarmsMsgCountsData = (flag) => request(Urls.GETALARMSMSGCOUNTS, null, 'GET', flag);
// alarm Type List

export const getAlarmTypeNameListData = (flag) => request(Urls.ALARMTYPENAMELIST, null, 'GET', flag);

// home
export const getHomeBasicMsgData = (flag) => request(Urls.BASICMSG, null, 'GET', flag);
export const getMapAreaListData = (flag) => request(Urls.AREASEARCH, null, 'GET', flag);
export const getAlarmPieChartMsgData = (params, flag) => request(Urls.ALARMPIECHARTMSG, params, 'POST', flag);
export const getAlarmBarChartMsgData = (params, flag) => request(Urls.ALARMHISTOGRAMCHARTMSG, params, 'POST', flag);
export const getAlarmLineChartMsgData = (params, flag) => request(Urls.ALARMTRENDCHARTMSG, params, 'POST', flag);
export const getHomeMapAlertListData = (params, flag) => request(Urls.MAPALERTLIST, params, 'POST', flag, {
  'Content-Type': 'application/x-www-form-urlencoded',
});

// Algorithm Settings
export const getAlarmSettingData = (flag) => request(Urls.GETALARMSETTING, null, 'GET', flag);
export const updateAlarmSetting = (params, flag) => request(Urls.UPDATEALARMSETTING, params, 'POST', flag, {
  'Content-Type': 'application/json',
});

// System Setting
export const getEmailData = (flag) => request(Urls.GETEMAIL, null, 'GET', flag);
export const updateEmail = (params, flag) => request(Urls.UPDATEEMAIL, params, 'POST', flag);

// level of Layout View
export const addNewLevel=(params, flag)=>request(Urls.LEVELADD, params, 'POST', flag, {
  'Content-Type': 'multipart/form-data',
});
export const updateLevel=(params, flag)=>request(Urls.LEVELUPDATE, params, 'POST', flag, {
  'Content-Type': 'multipart/form-data',
});
export const getEvelListData=(params, flag)=>request(Urls.LEVELLIST, params, 'POST', flag);
export const deleteLevel = (params, flag) => request(Urls.LEVELDELETE, params, 'DELETE', flag);

// building of Layout View
export const getBuildingNameListData = (flag) => request(Urls.BUILDINGOPTIONS, null, 'GET', flag);
export const addBuilding = (params, flag) => request(Urls.BUILDINGADD, params, 'POST', flag);
export const getBuildListData = (params, flag) => request(Urls.BUILDINGLIST, params, 'POST', flag);
export const updateBuilding = (params, flag) => request(Urls.BUILDINGUPDATE, params, 'PUT', flag);
export const deleteBuilding = (params, flag) => request(Urls.BUILDINGDEL, params, 'DELETE', flag);

// base search
export const getAreaListData = (flag) => request(Urls.GETAREALIST, null, 'GET', flag);
export const getNameListData = (flag) => request(Urls.GETNAMELIST, null, 'GET', flag);

export const getSearchData = (url, data, flag) => request(url, data, 'POST', flag, {
  'Content-Type': 'multipart/form-data',
});
export const getSearchDetailData = (url, data, flag) => request(url, data, 'POST', flag);
export const addPersonOfInterestData = (id, flag) => request(`${Urls.ADDPERSONOFINTEREST}/${id}`, {}, 'PUT', flag);
// area
export const addAreaApi = (params, flag) => request(Urls.ADDAREA, params, 'POST', flag);
export const getAreaSettingList = (params, flag) => request(Urls.GETAREASETTINGLIST, params, 'POST', flag);
export const upDateArea = (params, flag) => request(Urls.UPDATEAREA, params, 'POST', flag);
export const deleteArea=(params, flag)=>request(Urls.DELETEAREA+params, params, 'POST', flag);

// Roles
export const getRolesListData=(params, flag)=>request(Urls.GETROLELIST, params, 'POST', flag);
export const getMenuListData=(params, flag)=>request(Urls.GETMENULIST, params, 'POST', flag);
export const getMenuListDataNewChange=(params, flag)=>request(Urls.GETADDMENUTREE, params, 'POST', flag);
export const addRole=(params, flag)=>request(Urls.ADDROLE, params, 'POST', flag);
export const deleteRole=(params, flag)=>request(Urls.REMOVEROLE, params, 'DELETE', flag);
export const updateRole = (params, flag) => request(Urls.UPDATEROLE, params, 'PUT', flag);
export const getMenusData = (params, flag) => request(Urls.GETMENUBYID + params, null, 'GET', flag);

// Users
export const getUserListData=(params, flag)=>request(Urls.GETUSERLIST, params, 'POST', flag);
export const deleteUser=(params, flag)=>request(Urls.REMOVEUSER, params, 'DELETE', flag);
export const saveUser=(params, flag)=>request(Urls.ADDUSER, params, 'POST', flag);
export const updateUser=(params, flag)=>request(Urls.UPDATEUSER, params, 'PUT', flag);
export const resetPwd=(params, flag)=>request(Urls.RESETPASSWORD, params, 'PUT', flag);

// VisitHistory
export const getListData=(params, flag)=>request(Urls.VISITERLIST, params, 'POST', flag);
export const getDetailData=(params, flag)=>request(Urls.GETMEMBERRECORD+params, params, 'POST', flag);

// VisitCount
export const getStatisticsData=(params, flag)=>request(Urls.VISITORCOUNTLIST, params, 'POST', flag);

// Cameras
export const addCameras=(params, flag)=>request(Urls.ADDNEWCAMERA, params, 'POST', flag);
export const getCamerasList=(params, flag)=>request(Urls.CAMERALIST, params, 'POST', flag);
export const deleteCameras=(params, flag)=>request(Urls.DELETECAMERA+params, params, 'POST', flag);
export const updateCameras=(params, flag)=>request(Urls.UPDATECAMERA, params, 'POST', flag);
export const updateCamerasStatus=(params, flag)=>request(Urls.UPDATECAMERASTATUS, params, 'PUT', flag);
export const camerasUpLoad=(params, flag)=>request(Urls.CAMERAUPLOAD, params, 'POST', flag, {
  'Content-Type': 'multipart/form-data',
});
export const updateCameraLocation= (params, flag) => request(Urls.CAMERALOCATION, params, 'POST', flag);

// ContactTracing
export const getAlarmDetailsData=(id, flag)=>request(Urls.GETALARMDETAIL+id, null, 'GET', flag);
export const getTracingListData=( params, flag)=>request(Urls.GETTRACINGLIST, params, 'POST', flag);

// tableList

export const getTableListData=(url, params, flag)=>request(url, params, 'POST', flag);

// Alarm Settings
export const addAlarmType=(params, flag)=>request(Urls.ALARMTYPEADD, params, 'POST', flag);
export const updateAlarmType=(params, flag)=>request(Urls.ALARMTYPEUPDATE, params, 'PUT', flag);
export const getStaffNameListData=(id, flag)=>request(Urls.GETSTAFFNAMELIST, null, 'GET', flag);
export const getAlarmTypeListData=( params, flag)=>request(Urls.ALARMTYPELIST, params, 'POST', flag);

// Resource Management
export const getResourceData=(flag)=>request(Urls.GETRESOURCEDATALIST, null, 'GET', flag);
export const getCurrentResourceData=(flag)=>request(Urls.GETCURRENTRESOURCEDATA, null, 'GET', flag);

// Real-time Monitoring
export const getMonitorListData=( params, flag)=>request(Urls.GETMONITORLIST, params, 'POST', flag);
export const getMonitorDetailsData= (cID, flag)=>request(Urls.GETMONITORDETAILS+cID, null, 'GET', flag);
export const getMonitorDetailsListData =(cID, pageSize, flag)=>request(Urls.GETMONITORINGALARMLIST+cID+'/'+pageSize, null, 'GET', flag);

// alert

export const getAlertBaseMsgData =(flag)=>request(Urls.GETALARMBASEMSG, null, 'GET', flag);
export const getCameraListData =(cID, flag)=>request(Urls.GETAREACAMERA+cID, null, 'GET', flag);
export const updateAlertStatus = (params, flag)=>request(Urls.PROCESSALARM, params, 'PUT', flag);

// Face
export const deleteFaceData = (fID, flag)=>request(Urls.DELETEFACEDATA+fID, null, 'POST', flag);

// StaffContact
export const addStaffContact = (params, flag)=>request(Urls.STAFFADD, params, 'POST', flag,
    {
      'Content-Type': 'application/json',
    },
);
export const updateStaffContact = (params, flag)=>request(Urls.STAFFUPDATE, params, 'PUT', flag, {
  'Content-Type': 'application/json',
});
export const deleteStaffContact=(id, flag)=>request(Urls.STAFFDELETE, id, 'DELETE', flag);

export const algorithmData = (flag)=>request(Urls.ALGORITHM, null, 'GET', flag);
export const licenseUpload = (params, flag)=>request(Urls.LICENSEUPLOAD, params, 'POST', flag, {
  'Content-Type': 'multipart/form-data',
});
export const getConcat = (params, flag)=>request(Urls.CONCAT, params, 'GET', flag);
export const licenseDownload = (flag)=>request(Urls.LICENSEDOWNLOAD, null, 'GET', flag,
    {
      'Content-Type': 'application/octet-stream',
      // 'responseType': 'blob',
    },
);

export const getInitMsgData= (flag)=>request(Urls.SENDMSMCOUNT, null, 'GET', flag);

export const getAlgoData = (flag)=>request(Urls.GETALGORITHMS, null, 'POST', flag);

export const deleteSingleAlgo = (id, flag)=>request(Urls.DELETEALGO, id, 'DELETE', flag);

export const updateSingleAlgo = (params, flag)=>request(Urls.UPDATEALGO, params, 'POST', flag,
    {
      'Content-Type': 'application/json',

    });

export const licenseAfterUpload = (flag)=>request(Urls.LICENSETOEXPIRE, null, 'GET', flag);

export const addNewSingleAlogo = (params, flag)=>request(Urls.SAVENEWALGO, params, 'POST', flag,
    {
      'Content-Type': 'application/json',

    },
);

export const getAlgoNameList = (flag)=>request(Urls.GETALGORITHMLIST, null, 'POST', flag);
// face compare
export const faceCompare = (params, flag)=>request(Urls.FACECOMPARE, params, 'POST', flag, {
  'Content-Type': 'application/json',

});
export const imageUpload = (params, flag)=>request(Urls.IMAGEUPLOAD, params, 'POST', flag, {
  'Content-Type': 'multipart/form-data',
});

// license API
export const getAIListByTrialType = (params, flag)=>request(Urls.GETCAMERAMANAGEAILIST + params, null, 'GET', flag);
// 图片视频识别
export const getVideoAnalysisTask = (flag)=>request(Urls.GETVIDEOTASKLIST, null, 'POST', flag);
export const getTaskDetail=(id, flag)=>request(Urls.DETAILVIATASKID+id, null, 'GET', flag);
export const faceAndImageAi = (params, flag)=>request(Urls.IMAGEANDVIDEOAI, params, 'POST', flag, {
  'Content-Type': 'application/json',

});
export const getAIlistArray=(flag)=>request(Urls.GETALLAILIST, null, 'GET', flag);

// 获取级联选择器
export const getCaSadList=(flag)=>request(Urls.GETCASADERDATA, null, 'GET', flag, {'Content-Type': 'application/json'});

// 获取级联选择器二级
export const getCaSadListChildSecond=(id, flag)=>request(Urls.GETCASADERDATACHILD+id, null, 'GET', flag);

// 获取级联选择器三级
export const getCaSadListChildThird=(id, flag)=>request(Urls.GETCASADERDATACHILDAREA+id, null, 'GET', flag);

export const getBuildingIdOption=(flag)=>request(Urls.GETBUILDINGID, null, 'GET', flag);

// 解绑楼宇
export const unBindingBuilding=(id, flag)=>request(Urls.UNBINDINGBUILDING+id, null, 'GET', flag);
// 获取全局通知
export const getNotice=(flag)=>request(Urls.AIOUTTIMEMODAL, null, 'GET', flag);
// 检测每个算法页面是否过期，过期则是提醒
export const getOutDateMessage=(id, flag)=>request(Urls.PAGEAIOUTDATENOTICE+id, null, 'GET', flag);

