import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Popover, Radio, Row, Spin, Tabs, Empty} from 'antd';
import {Link} from 'react-router-dom';
import {alarmTypeFuc, alarmColorFuc} from '../../../components/baseTypeSelect';
import {ETTodayValue, STTodayValue} from '../../../components/baseTime';
import {useTranslation} from 'react-i18next';


const FloorMaps = ({indexMapResponse}) => {
  const {t} = useTranslation();
  const [loading, setLoading]= useState(true);
  const [buildKey, setBuildKey] = useState('');
  const [floorKey, setFloorKey] = useState('');
  const [buildList, setBuildList] = useState([]);
  const [maps, setMaps] = useState({});
  // const [alarmTypes] = useState([]);
  const mapData = (values) => {
    if (values.length > 0) {
      setBuildKey(values[0].buildingId.toString());
      setFloorKey(values[0].buildingLevelResponseList[0].buildingLevelId);
      setMaps(values[0].buildingLevelResponseList[0]);
      setLoading(false);
    } else {
      setMaps({});
      setBuildKey('');
      setFloorKey('');
      setLoading(false);
    }
    setBuildList(values);
  };

  useEffect(() => {
    mapData(indexMapResponse);
  }, [indexMapResponse]);
  return (
    <Spin spinning={loading} style={{marginTop: loading?'150px':'0px'}}>
      {!buildList.length>0 &&<Empty style={{marginTop: '150px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {buildList.length>0 &&<Tabs type="card" activeKey={buildKey} onChange={(key) => {
        setBuildKey(key);
        const buildLists=buildList.find((item) => item.buildingId === parseInt(key));
        setFloorKey(buildLists.buildingLevelResponseList[0].buildingLevelId);
        setMaps(buildLists.buildingLevelResponseList[0]);
      }}>
        {buildList.length>0 && buildList.map((item) => (
          <Tabs.TabPane tab={item.buildingName} key={item.buildingId} style={{padding: 8}}>
            <Radio.Group value={floorKey}
              onChange={(e) => {
                const value = e.target.value;
                setFloorKey(value);
                setMaps(item.buildingLevelResponseList.find((sub) => sub.buildingLevelId === value));
              }}>
              {item.buildingLevelResponseList.map((subItem) => (
                <Radio
                  value={subItem.buildingLevelId}
                  key={subItem.buildingLevelId + subItem.levelName}>
                  {subItem.levelName}
                </Radio>
              ))}
            </Radio.Group>
            {maps.levelPlanUrl && (<div className="map-box" style={{marginTop: 16}}>
              <div className="map">
                <img
                  className="mapBg"
                  src={maps.levelPlanUrl}
                />
                {maps.cameraIndexResponseList.length > 0 && (
                  maps.cameraIndexResponseList.map((item) => {
                    if (item.indexMapMessageResponse.length > 0) {
                      return (
                        <Popover
                          key={item.id}
                          content={(
                            <div className="tips">
                              <Row gutter={[8, 8]}>
                                <Col span={16} className='c_title text-white'>
                                  {t('camera')}:
                                </Col>
                                <Col className="text-right c_title text-white" span={8}>
                                  {item.cameraName}
                                </Col>
                              </Row>
                              {item.indexMapMessageResponse.map((subItem, index) => (
                                <>
                                  {subItem.alarmCount > 0 ? (
                                    <>
                                      <Row key={item.cameraName + index+0} gutter={[8, 8]}>
                                        <Col span={16} className="text-white">
                                          <span
                                            className="colors"
                                            style={{backgroundColor: alarmColorFuc(subItem.alarmType)}} />
                                          {alarmTypeFuc(subItem.alarmType)}
                                        </Col>
                                        <Col className="text-right text-white" span={8}>
                                          {subItem.alarmCount}
                                        </Col>
                                      </Row>
                                      {/* <Row key={item.cameraName + index+1} gutter={[8, 8]}>
                                        <Col span={16} className="text-white">
                                          {t('coordinate')}
                                        </Col>
                                        <Col className="text-right text-white" span={8}>
                                          {subItem.coordinate}
                                        </Col>
                                      </Row> */}
                                    </>
                                ) : null}
                                </>
                              ))}
                              <Row gutter={[8, 8]}>
                                {/* `/alert/215/?
                                    cameraId=${item.id}&startTime=${STTodayValue}&endTime=${ETTodayValue}` */}
                                <Col span={24} className="text-right">
                                  <Link
                                    to={`/console/alerts/215/?cameraId=${item.id}&startTime=${STTodayValue}&endTime=${ETTodayValue}`}
                                  >
                                    {t('details')}
                                  </Link>
                                </Col>
                              </Row>
                            </div>)}
                          trigger="click">
                          <div
                            style={{left: `calc(${item.coordinate?.match(/-?\d+(\.\d+)?/g)[0]}% - 85px)`,
                              top: `calc(${item.coordinate?.match(/-?\d+(\.\d+)?/g)[1]}% - 85px)`}}
                            className={item.indexMapMessageResponse.length > 0 ? `area cameras` : `cameras`}>
                            <div className="camera"/>
                            <div className={item.indexMapMessageResponse.length > 0 ? `point` : ''}/>
                          </div>
                        </Popover>
                      );
                    }
                    return (
                      <div
                        key={item.id}
                      >
                        <div className="camera-offline"
                          style={{left: `calc(${item.coordinate?.match(/-?\d+(\.\d+)?/g)[0]}% - 85px)`,
                            top: `calc(${item.coordinate?.match(/-?\d+(\.\d+)?/g)[1]}% - 85px)`}}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>)}
          </Tabs.TabPane>
        ))}
      </Tabs>}
    </Spin>
  );
};

FloorMaps.propTypes = {
  indexMapResponse: PropTypes.arrayOf(PropTypes.object).isRequired,
  // allAlarmType: PropTypes.string.isRequired,
  // dateType: PropTypes.number.isRequired,
  // mapLoading: PropTypes.bool.isRequired,
  // / alarmTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FloorMaps;
