import qs from 'querystring';
// import React from 'react';
import axios from 'axios';
import {storage} from '../utils/storage';
// import {Modal} from 'antd';
// import {Link} from 'react-router-dom';

// const loginOut=1;
axios.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  // console.log('response', response);
  const headers = response.headers;
  if (headers) {
    if (headers['content-type'] === 'application/octet-stream;charset=utf-8') {
      return response.data;
    }
  }

  switch (response.data.code) {
    case 200:
      return response.data;
    case 401: {
      // if (loginOut===1) {
      storage.clearData('local', 'alarmType');
      storage.clearData('local', 'userInfo');
      if (!window.__POWERED_BY_QIANKUN__) {
        window.location.href = '/login';
      } else {
        window.location.href = window.location.origin + '/login';
      }
      return Promise.reject(response.data.msg);
    }
    default:
      return Promise.reject(response.data.msg);
  }
}, (error) => {
  return Promise.reject(error);
});


export const request = async (url, params, type, flag, header = {}) => {
  const userInfo = storage.getData('userInfo');
  const i18nextLng = storage.getData('i18nextLng') === 'en' ? '1' : '2';
  const token = userInfo ? userInfo.token : null;
  if (window.__POWERED_BY_QIANKUN__) {
    url = '/api/aigel' + url;
  }
  const building = userInfo ? (userInfo.buildingType === 1 ? 1 : 2) : null;
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'token': token,
    'flag': flag || i18nextLng,
    'building': building,
    ...header,
  };
  if (headers['Content-Type'] === 'application/octet-stream') {
    return axios({
      headers: headers,
      url,
      method: 'get',
      responseType: 'blob',
    });
  }
  let options = {
    method: type.toUpperCase(),
    headers: headers,
  };
  if (type.toUpperCase() === 'GET') {
    let p = '';
    if (params) {
      Object.keys(params).forEach((key) => {
        p += key + '=' + params[key];
      });
      p = '?' + p;
    }

    options = {
      ...options,
      url: `${url}${p}`,
    };
  } else if (type.toUpperCase() === 'DELETE') {
    options.url = url + params;
  } else {
    options = {
      ...options,
      data: qs.stringify(params),
      url,
    };
  }

  if (headers['Content-Type'] === 'application/json' || headers['Content-Type'] === 'multipart/form-data') {
    options.data = params;
  }
  return await axios(options);
};


