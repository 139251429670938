import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, message, Radio, Row, Select, Space, Upload} from 'antd';
import './style.css';
import {useTranslation} from 'react-i18next';
import {getAreaListData, getNameListData} from '../../../api';
import moment from 'moment';
// import {getSearchConfig} from '../seacrhConfig';
// import {useParams} from 'react-router-dom';

const BaseSearchFrom = ({config, onSearch}) => {
  const {t} = useTranslation();
  const [searchForm]= Form.useForm();
  // const locationParams = useParams();
  // const [config, setConfig]=useState({});
  // const [loading, setLoading]= useState(false);
  // const [clockEnd, setClockEnd] = useState(moment().format('YYYY-MM-DD HH:mm'));
  // const [clockStart, setClockStart] = useState(moment().format('YYYY-MM-DD 00:00'));
  // useEffect(() => {
  //   setInterval(() => {
  //     setClockEnd(moment().format('YYYY-MM-DD HH:mm'));
  //     setClockStart(moment().format('YYYY-MM-DD 00:00'));
  //   }, 3000);
  // }, []);
  const [areaList, setAreaList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [ICList, setICList] = useState([]);
  // const [resetFile, setResetFile] = useState(false);
  const [fileList, setFileList] = useState([
    {file: null, url: ''},
    {file: null, url: ''},
    {file: null, url: ''},
    {file: null, url: ''},
  ]);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  //  设置不可选择时间
  const [dates, setDates] = useState(config.periodDisableDateInstall?.defaultValue);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 0;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 0;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    console.log('onOpenChange');
    if (open) {
      setDates([null, null]);
      searchForm.setFieldsValue({'period': [null, null]});
    } else {
      setDates([...dates]);
    }
  };
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: (info)=>{
    },
    onChange: (e)=>{
      if (e.file.size<config.files.MAXSIZE) {
        const array = [...fileList];
        array[currentImgIndex].file=e.file;
        getBase64(e.file.originFileObj, (imageUrl)=>{
          array[currentImgIndex].url=imageUrl;
          setFileList(array);
        });
      } else {
        message.warn(t('overSizeMsg'));
      }
    },
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const initData=()=>{
    const init={};
    Object.keys(config).forEach((key)=>{
      if (key==='radio') {
        config.radio.forEach((item)=>{
          init[item.name]=item.defaultValue;
        });
      } else if (key!=='files') {
        init[key]=config[key].defaultValue;
      } else if (key==='period') {
        init[key]=config[key].defaultValue;
      }
    });
    searchForm.setFieldsValue(init);
    if (config.name&&nameList.length<1) {
      getNameListData(t('flag')).then((res) => {
        setICList(res.data.icNumber);
        setNameList(res.data.memberName);
      }).catch((error) => {
        message.error({
          content: error.toString(),
          key: 'netError',
          duration: 2,
        });
      });
    }
    if (config.areaId&&areaList.length<1) {
      getAreaListData(t('flag')).then((res) => {
        setAreaList(res.data);
      }).catch((error) => {
        message.error({
          content: error.toString(),
          key: 'netError',
          duration: 2,
        });
      });
    }
    setFileList([
      {file: null, url: ''},
      {file: null, url: ''},
      {file: null, url: ''},
      {file: null, url: ''},
    ]);
  };
  const onFinish=(values)=> {
    // const formData = new FormData();
    const data = {};
    console.log(values, '获取的上传参数');
    Object.keys(values).forEach((key) => {
      if (key === 'period') {
        const startTime = values[key] ?
          moment(moment(values[key][0]).format('YYYY-MM-DD HH:mm:00')).valueOf() :
          config[key].defaultResValue[0];
        const endTime = values[key] ?
          moment(moment(values[key][1]).format('YYYY-MM-DD HH:mm:00')).valueOf() :
          config[key].defaultResValue[1];
        data.startTime = startTime;
        data.endTime = endTime;
      } else if (config[key]) {
        data[key] = values[key] || config[key].defaultResValue;
      } else {
        // const radio = (config.radio.find((item) => item.name === key));
        data[key] = values[key];
      }
    });
    if (config.files) {
      const files = [];
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].file !== null) {
          files.push(fileList[i].file);
        }
      }
      console.log(files, 'files de 值');
      // data.files=files[0] ? files[0].originFileObj : config.files.defaultResValue;
      data.files=files ? files[0]?.originFileObj : config.files.defaultResValue;
    }
    console.log(data, 'data&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
    onSearch(data);
  };
  const onError= ( values )=>{
    message.error(t(values.errorFields[0].errors[0]));
  };

  useEffect(()=>{
    setTimeout(()=>{
      initData();
    }, 300);
  }, [config]);

  return (
    <div className="basic-search">
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        name="basicSearch"
        form={searchForm}
        onFinish={onFinish}
        onFinishFailed={onError}
      >
        <div className="bs-box">
          <div className="bs-title">
            {t('basicSearchTitle')}
          </div>
          <div className="bs-box-body">
            <div className="bs-content">
              {config.files&&(<>
                <Row>
                  <Col span={4} className="text-right lineH32">
                    {config.files.required && <span style={{color: 'red'}}> * </span>}
                    {t('image')}:
                  </Col>
                  <Col span={20} className="bs-upload-info">
                    {t('bsUploadInfo')}
                  </Col>
                </Row>
                <div className="bs-upload-box">
                  {/* <Space size="small" wrap className="padding-l-16 padding-t-8">*/}
                  {fileList.map((item, index) => (
                    <div className="bs-upload-item" key={`image_${index}`}>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        {...props}
                        onClick={() => setCurrentImgIndex(index)}
                      >
                        {item.file === null ? <div style={{fontSize: 20}}>+</div> :
                            <div className="uploadBox">
                              <div className="upload_delete" onClick={(e) => {
                                const array = [...fileList];
                                array[index] = {file: null, url: ''};
                                setFileList(array);
                                e.stopPropagation();
                              }}>X
                              </div>
                              <img src={item.url} alt="avatar" style={{maxWidth: 68, maxHeight: 60}}/>
                            </div>}
                      </Upload>
                    </div>
                  ))}
                  {/* </Space>*/}
                </div>
              </>)}
              {config.icNumber && (
                <Form.Item
                  label={t(config.icNumber.label)}
                  name={config.icNumber.name}
                  rules={[{
                    required: config.icNumber.required,
                    message: config.icNumber.message,
                  }]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder={t('placeholder')}
                    className="basic_search_from_item"
                  >
                    {ICList.map((item) => (
                      <Select.Option value={item} key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {config.name && (
                <Form.Item
                  label={t(config.name.label)}
                  name={config.name.name}
                  rules={[{
                    required: config.name.required,
                    message: config.name.message,
                  }]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder={t('placeholder')}
                    className="basic_search_from_item"
                  >
                    {nameList.map((item) => (
                      <Select.Option value={item} key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>)}
              {config.period&& (
                <Form.Item
                  name={config.period.label}
                  rules={[{
                    required: config.period.required,
                    message: config.period.message,
                  }]}
                  label={t(config.period.label)}
                  initialValue={config.period.disableDataBool?dates:config.period?.defaultValue}
                >
                  {config.period.disableDataBool?<DatePicker.RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [moment('00:00', 'HH:mm'), moment('11:59', 'HH:mm')],
                    }}
                    format="YYYY-MM-DD HH:mm"
                    size="large"
                    className="basic_search_from_item"
                    // value={dates}
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => {
                      console.log(val, 'onCalendarChange的val的值');
                      setDates(val);
                    }}
                    onChange={(val) => {
                      console.log(val, 'onChange的val的值');
                      setDates(val);
                      searchForm.setFieldsValue({'period': val});
                    }}
                    onOpenChange={onOpenChange}
                    onBlur={() => console.log('blur has been triggered')}
                  /> : <DatePicker.RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [moment('00:00', 'HH:mm'), moment('11:59', 'HH:mm')],
                    }}
                    // showTime={{format: 'HH:mm'}}
                    format="YYYY-MM-DD HH:mm"
                    size="large"
                    className="basic_search_from_item"
                  // value={dates || value}
                  />}
                </Form.Item>)}
              {config.periodDisableDateInstall &&(
                <Form.Item
                  name={config.periodDisableDateInstall.label}
                  rules={[{
                    required: config.periodDisableDateInstall.required,
                    message: config.periodDisableDateInstall.message,
                  }]}
                  label={t(config.periodDisableDateInstall.label)}
                  initialValue={dates}
                >
                  <DatePicker.RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [moment('00:00', 'HH:mm'), moment('11:59', 'HH:mm')],
                    }}
                    format="YYYY-MM-DD HH:mm"
                    size="large"
                    className="basic_search_from_item"
                    // value={dates}
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => {
                      console.log(val, 'onCalendarChange的val的值');
                      setDates(val);
                    }}
                    onChange={(val) => {
                      console.log(val, 'onChange的val的值');
                      setDates(val);
                      searchForm.setFieldsValue({'period': val});
                    }}
                    onOpenChange={onOpenChange}
                    onBlur={() => console.log('blur has been triggered')}
                  />
                </Form.Item>)}
              {config.areaId &&(
                <Form.Item
                  label={t(config.areaId.label)}
                  name={config.areaId.name}
                  rules={[{
                    required: config.areaId.required,
                    message: config.areaId.message,
                  }]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder={t('placeholder')}
                    className="basic_search_from_item"
                  >
                    {areaList.map((item)=>(
                      <Select.Option value={item.id} key={item.id + 'area'}>{item.areaName}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>)}
              {config.radio&&(config.radio.map((item)=>(
                <Form.Item
                  key={'radio'+item.name}
                  label={t(item.label)}
                  name={item.name}
                  initialValue={item.defaultValue}
                  rules={[{
                    required: item.required,
                    message: item.message,
                  }]}
                >
                  <Radio.Group
                    // name={item.name}
                  >
                    {item.valueItem.map((subItem)=>(
                      <Radio
                        value={subItem.value}
                        key={subItem.label+subItem.name}
                      >{t(subItem.label)}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )))}
              <Form.Item
                noStyle
              >
                <Space className="btn-group-box">
                  <Button
                    htmlType="reset"
                    onClick={()=>{
                      if (config.periodDisableDateInstall) {
                        searchForm.setFieldsValue({'period': config.periodDisableDateInstall?.defaultValue});
                      }
                      setFileList([
                        {file: null, url: ''},
                        {file: null, url: ''},
                        {file: null, url: ''},
                        {file: null, url: ''},
                      ]);
                      const data = {};
                      Object.keys(config).forEach((key)=>{
                        if (key==='radio') {
                          config.radio.forEach((item)=>{
                            data[item.name]=item.defaultValue;
                          });
                        } else if (key!=='files') {
                          data[key]=config[key].defaultValue;
                        }
                      });
                      // onFinish({...data});
                      console.log(fileList, 'fileList?????????????????');
                    }}
                    className="transparent-btn width100">
                    {t('reset')}
                  </Button>
                  <Button type="primary" htmlType="submit" className="full-btn width100">
                    {t('submit')}
                  </Button>
                </Space>
              </Form.Item>
              {config.infos&&(<p className="bs-info">{t(config.infos)}</p>)}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

BaseSearchFrom.propTypes = {
  config: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default BaseSearchFrom;
