// login
export const LOCALSIGNIN= '/api/user/login';
export const GETVERIFY='/api/user/getVerify';

// header
export const GETALARMSMSGCOUNTS='/api/alarm/isExistUnProcessAlarm';

// alarmType
export const ALARMTYPENAMELIST='/api/alarmType/alarmTypeNameList';

// home
export const BASICMSG = '/api/home/basicMsg';
export const ALARMPIECHARTMSG = '/api/home/alarmPieChartMsg';
export const ALARMTRENDCHARTMSG = '/api/home/alarmTrendChartMsg';
export const ALARMHISTOGRAMCHARTMSG = '/api/home/alarmHistogramChartMsg';
export const MAPALERTLIST='/api/home/maps';
export const AREASEARCH='/api/home/areaSearch';

// System Management
export const GETALARMSETTING= '/api/alarmSetting/list';
export const UPDATEALARMSETTING= '/api/alarmSetting/update';
export const GETEMAIL = '/api/systemSetting/areaSetting/getEmail';
export const UPDATEEMAIL = '/api/systemSetting/areaSetting/updateEmail';
// level
export const LEVELADD = '/api/buildingLevel/addLevel';
export const LEVELLIST = '/api/buildingLevel/LevelList';
export const LEVELUPDATE = '/api/buildingLevel/updateLevel';
export const LEVELDELETE = '/api/buildingLevel/deleteLevel/';
// building get list
export const BUILDINGOPTIONS = '/api/building/buildingNameList';
export const BUILDINGADD = '/api/building/addBuilding';
export const BUILDINGLIST = '/api/building/buildList';
export const BUILDINGUPDATE = '/api/building/updateBuilding';
export const BUILDINGDEL = '/api/building/deleteBuilding/';
// area
export const ADDAREA = '/api/systemSetting/areaSetting/add';
export const GETAREASETTINGLIST = '/api/systemSetting/areaSetting/list';
export const UPDATEAREA = '/api/systemSetting/areaSetting/update';
export const DELETEAREA = '/api/systemSetting/areaSetting/remove/';

// Roles
export const GETROLELIST = '/api/role/rolesList';
export const GETMENULIST = '/api/permission/menuList';
export const ADDROLE = '/api/role/addRole';
export const REMOVEROLE = '/api/role/deleteRole/';
export const UPDATEROLE = '/api/role/updateRole';
export const GETMENUBYID = '/api/role/menus/';
export const GETADDMENUTREE = '/api/permission/menuTree';
// users
export const GETUSERLIST = '/api/user/getUserList';
export const REMOVEUSER = '/api/user/deleteUser/';
export const ADDUSER = '/api/user/saveUser';
export const UPDATEUSER = '/api/user/updateUser';
export const RESETPASSWORD = '/api/user/resetPwd';
// VisitHistory
export const VISITERLIST = '/api/memberRecord/list';
export const GETMEMBERRECORD = '/api/memberRecord/detail/';
// VisitCount
export const VISITORCOUNTLIST = '/api/memberRecord/statistics';
// Cameras
export const ADDNEWCAMERA = '/api/camera/add';
export const CAMERALIST = '/api/camera/list';
export const DELETECAMERA = '/api/camera/remove/';
export const UPDATECAMERA = '/api/camera/update';
export const UPDATECAMERASTATUS ='/api/camera/updateRecognitionStatus';
export const CAMERAUPLOAD='/api/camera/upload';
export const CAMERALOCATION='/api/camera/location';

// search data
export const GETAREALIST = '/api/area/nameList';
export const GETNAMELIST='/api/faceMessage/nameList';

export const GETFACERECOGNITIONDETAIL = '/api/faceRecognition/structure/detail';
export const GETFACERECOGNITIONLIST ='/api/faceRecognition/search';
export const ADDPERSONOFINTEREST='/api/face/updateFaceRecord';
export const GETDWELLTIMELIST ='/api/waitingTime/search';
export const GETINTERESTLIST ='/api/personOfInterest/search';
export const GETINTERESTDETAIL='/api/faceRecognition/structure/detail';
export const GETFALLINGLIST = '/api/falling/list';
export const GETSMOKELIST='/api/smoking/search';
export const GETSOCIALLIST='/api/social/search';
export const GETLOITERLIST='/api/loiter/search';
export const GETTRESPASSINGLIST ='/api/trespassing/search';
export const GETOVERSTAYERSLIST='/api/overstayers/search';
export const GETGROUPINGLIST='/api/group/list';
export const GETPEOPLECOUNTLIST='/api/peopleCount/list';


// ContactTracing
export const GETALARMDETAIL='/api/alarm/detail/';
export const GETTRACINGLIST='/api/tracing/list';
export const GETCOMPARELIST='/api/tracing/compareList';

// Alarm Settings
export const ALARMTYPEADD = '/api/alarmType/addAlarmType';
export const GETSTAFFNAMELIST = '/api/staff/getStaffNameList';
export const ALARMTYPELIST = '/api/alarmType/alarmTypeList';
export const ALARMTYPEUPDATE = '/api/alarmType/updateAlarmType';

// Resource Management
export const GETRESOURCEDATALIST='/api/statistics/chart';
export const GETCURRENTRESOURCEDATA='/api/statistics/info';

// Real-time Monitoring
export const GETMONITORLIST= '/api/monitoring/list';
export const GETMONITORDETAILS='/api/monitoring/cameraDetails/';
export const GETMONITORINGALARMLIST ='/api/monitoring/alarmList/';

// alert
export const GETALARMLIST = '/api/alarm/list';
export const GETALARMBASEMSG = '/api/alarm/baseMsg';
export const GETAREACAMERA = '/api/systemSetting/areaCamera/';
export const PROCESSALARM = '/api/alarm/dealUnProcessAlarm';

// FACELIST

export const FACELIST = '/api/face/list';
export const ADDNEWFACE = '/api/face/add';
export const UPDATEFACE = '/api/face/update';
export const DELETEFACEDATA = '/api/face/remove/';

//
// staff add
export const STAFFADD = '/api/staff/addStaff';
// staff list
export const STAFFLIST = '/api/staff/getStaffList';
// staff update
export const STAFFUPDATE = '/api/staff/updateStaff';
// staff delete
export const STAFFDELETE = '/api/staff/deleteStaff/';

// license
export const ALGORITHM = '/api/license/algorithm';
export const LICENSEUPLOAD = '/api/license/upload';
export const CONCAT = '/api/license/concat';
export const LICENSEDOWNLOAD = '/api/license/download';

// setting
export const SENDMSMCOUNT='/api/alarm/sendMsmCount';

// algo
export const GETALGORITHMS ='/api/algorithms/getAll';
export const DELETEALGO='/api/algorithms/delete/';
export const UPDATEALGO='/api/algorithms/update';
export const LICENSETOEXPIRE='/api/license/soonToExpire';
export const SAVENEWALGO='/api/algorithms/save';

export const GETALGORITHMLIST='/api/imageVideo/algorithmList';

// face compare
export const FACECOMPARE='/api/imageVideo/faceComparison';
export const IMAGEUPLOAD='/api/imageVideo/uploadImage';
// 摄像头管理
export const GETCAMERAMANAGEAILIST='/api/license/algorithmByIsTrial/';
// 图片视频识别
export const GETVIDEOTASKLIST='/api/imageVideo/taskList';
export const DETAILVIATASKID='/api/imageVideo/taskDetails/';
export const IMAGEANDVIDEOAI='/api/imageVideo/identify';
// 请求算法接口
export const GETALLAILIST='/api/license/getAllAlgorithm';

// 请求区域级联选择器
export const GETCASADERDATA='/api/building//buildingNameList';
export const GETCASADERDATACHILD='/api/buildingLevel/buildingLevelList/';
export const GETCASADERDATACHILDAREA='/api/systemSetting/areaSetting/leveAreaSelectList/';
// 获取buildingId下拉框
export const GETBUILDINGID='/api/building/buildingNameList';
// 解绑楼宇
export const UNBINDINGBUILDING='/api/camera/unBinding/';
// 算法过期弹窗
export const AIOUTTIMEMODAL='/api/license/tips';
// 入侵检测
export const GETINTRUTIONLIST='/api/intrusionDetect/search';

// 检测每个算法页面是否过期，过期则是提醒
export const PAGEAIOUTDATENOTICE= '/api/license/getTipsByAlgorithm/';

