import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Input, message, Modal, Popconfirm, Space, Spin, Tree} from 'antd';
import {addRole, deleteRole, getMenuListDataNewChange, getMenusData, updateRole} from '../../../api';
import './style.css';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../components/customTable';
import {GETROLELIST} from '../../../api/Urls';

const layout = {
  labelCol: {span: 3},
  wrapperCol: {span: 16},
};
const Roles = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [updateList, setUpdateList] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [menuList, setMenuList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [treeKeys, setTreeKeys] = useState([]);

  const columns = [
    {
      title: t('id'),
      dataIndex: 'roleId',
      key: 'roleId',
    }, {
      title: t('role'),
      dataIndex: 'roleName',
      key: 'roleName',
    }, {
      title: t('action'),
      key: 'action',
      /*eslint-disable*/
      render: (record) => (
        <Space size="middle">
          <a onClick={() => {
            handleMenuById(record.roleId);
            form.setFieldsValue(record);
            setModalType('edit');
          }}>{t('edit')}</a>
          <Popconfirm
            title= {t('flag')==='1'?"Are you sure to delete this role?":"是否删除该角色？"}
            onConfirm={() => handleDelete(record.roleId)}
            okText={t('yes')}
            cancelText={t('no')}
            color="#102864"
          >
            <a>{t('delete')}</a>
          </Popconfirm>
        </Space>
      )
    },
    /*eslint-disable*/
  ];
  const menuTree = (
    <Tree
      checkable
      treeData={menuList}
      defaultExpandAll
      checkedKeys={checkedKeys}
      onCheck={(checkedKeys, info) => {
        setCheckedKeys(checkedKeys);
        setTreeKeys(checkedKeys.concat(info.halfCheckedKeys));
      }}
    />
  );
  const handleRole = (values)=>{
    setLoading(true);
    if (checkedKeys.length===0) {
      message.error(t('flag')==='1'?'Please choose role\'s permission！':"请选择角色权限！");
      setLoading(false);
      return false;
    }
    const data={
      ...values,
      menuIds: treeKeys.join(','),
    }
    
    const RequestPromise = modalType === 'add' ?
      addRole(data, t('flag')) :
      updateRole(data, t('flag'));
    
    RequestPromise.then((res)=> {
      message.success(res.msg);
      setModalVisible(false);
      setUpdateList(updateList + 1);
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setLoading(false);
    });
  };
  const editForm = ( <Form
    {...layout}
    name='settingForm'
    className='setting-form'
    onFinish={handleRole}
    form={form}>
    {modalType==='edit'&&(<Form.Item
      name='roleId'
      label='Id'
      hidden
      shouldUpdate
    >
      <Input autoComplete='off' style={{width: 257}} maxLength={12} disabled/>
    </Form.Item>)}
    <Form.Item
      name='roleName'
      label={t('roleName')}
      rules={[{
        required: true,
        message: t('flag')==='1'?'Please input role name!':'请输入角色名！',
        // type: 'string',
        max: 50,
      }]}
      shouldUpdate
    >
      <Input autoComplete='off' style={{width: 257}} maxLength={50} />
    </Form.Item>
    <Card title={t('permission')} style={{maxHeight: 480, overflow: 'scroll'}} >
      {menuTree}
    </Card>
    <div className='text-right' style={{marginTop: 24}}>
      <Button className='full-btn marginR16' htmlType='submit' >
        {t('submit')}
      </Button>
    </div>
  </Form>);
  const handleMenuById = (id)=>{
    getMenusData(id, t('flag')).then((res)=>{
      const a = [];
      const b = [];
      const c = [];
      res.data.children.forEach((item)=>{
        if (item.hasChildren) {
          item.children.forEach((inner)=>{
            b.push(inner.id);
          });
        } else {
          c.push(item.id);
        }
        a.push(item.id);
      });
      setCheckedKeys(b.concat(c));
      setTreeKeys(a.concat(b));
      // setTreeKeys(checkedKeys);
      setModalVisible(true);
    }) .catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    })
        .finally(() => setLoading(false));
  };
  
  const handleDelete = (id)=>{
   
    deleteRole(id, t('flag')).then((res)=>{
      message.success(res.msg);
      setModalVisible(false);
      setUpdateList(updateList + 1);
    }) .catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  const getMenus = () => {
    // setLoading(true);
    getMenuListDataNewChange({pageSize: 999, pageNum: 1}, t('flag'))
        .then((res) => {
          setMenuList(res.data.children.map((item)=>(
            {
              title: t('flag')==='1'?item.text:item.cnName,
              key: item.id,
              children: item.children.map((inner)=>(
                {
                  title: t('flag')==='1'?inner.text:inner.cnName,
                  key: inner.id,
                }
              )),
            }
          )));
          // setLoading(false);
        })
      .catch((error) => {
        message.error({
          content: error.toString(),
          key: 'netError',
          duration: 2,
        });
      });
  };
  const addNewRole = () => {
    setCheckedKeys([]);
    setModalVisible(true);
    form.resetFields();
    setModalType('add');
  };
  useEffect(() => {
    getMenus();
  }, [t('flag')]);
  return <div>
      <Spin spinning={loading}>
      <CustomTable
        hiddenExport={true}
        InitApi={GETROLELIST}
        updateList={updateList}
        columns={columns}
        addFuc={addNewRole}/>
      <Modal
        title={t(modalType)}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          // form.resetFields();
        }}
        className={modalType === 'add' ? 'bg-theme role-add-modal' : 'bg-theme role-edit-modal'}
        width={960}
        footer={null}
        // className='settings-modal'
        destroyOnClose>
        <div style={{padding: '16px 48px'}}>
          {editForm}
        </div>
      </Modal>
      </Spin>
  </div>;
};

Roles.propTypes = {

};

export default Roles;
