import moment from 'moment';
export const momentArr = [
  moment(moment().format('YYYY-MM-DD 00:00'), 'YYYY-MM-DD HH:mm'),
  moment(moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm')];
export const momentData = [
  moment(moment().format('YYYY-MM-DD 00:00'), 'YYYY-MM-DD HH:mm').valueOf(),
  moment(moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').valueOf()];

export const getSearchConfig = (pageID) => {
  switch (parseInt(pageID)) {
    // Face Recognition
    case 213:
      return {
        files: {
          name: 'files',
          required: false,
          defaultResValue: [],
          // defaultValue: [],
          MAXSIZE: 5 * 1024 * 1024,
        },
        icNumber: {
          label: 'ic',
          name: 'icNumber',
          required: false,
          message: '',
          defaultValue: null,
          defaultResValue: '',
        },
        name: {
          label: 'name',
          name: 'name',
          required: false,
          message: '',
          defaultValue: null,
          defaultResValue: '',
        },
        period: {
          label: 'period',
          nameList: ['startTime', 'endTime'],
          required: false,
          message: 'periodMsg',
          defaultValue: null,
          defaultResValue: [0, 0],
        },
        areaId: {
          label: 'area',
          name: 'areaId',
          required: false,
          message: 'areaMsg',
          defaultResValue: '',
          defaultValue: null,
        },
        infos: 'footerImageInfo',
      };
    // Persons of Interest
    case 220:
      return {
        files: {
          name: 'files',
          required: false,
          defaultResValue: '',
          MAXSIZE: 5 * 1024 * 1024,
        },
        icNumber: {
          label: 'ic',
          name: 'icNumber',
          required: false,
          message: '',
          defaultValue: null,
          defaultResValue: '',
        },
        name: {
          label: 'name',
          name: 'name',
          required: false,
          message: '',
          defaultValue: null,
          defaultResValue: '',
        },
        period: {
          label: 'period',
          nameList: ['startTime', 'endTime'],
          required: true,
          message: 'periodMsg',
          defaultValue: momentArr,
          defaultResValue: momentData,
        },
        areaId: {
          label: 'area',
          name: 'areaId',
          required: false,
          message: 'areaMsg',
          defaultResValue: '',
          defaultValue: null,
        },
        infos: 'footerImageInfo',
      };
    // Mask
    case 224:
      return {
        files: {
          name: 'files',
          required: false,
          defaultResValue: '',
          MAXSIZE: 5 * 1024 * 1024,
        },
        icNumber: {
          label: 'ic',
          name: 'icNumber',
          required: false,
          message: '',
          defaultResValue: '',
        },
        name: {
          label: 'name',
          name: 'name',
          required: false,
          message: '',
          defaultResValue: '',
        },
        period: {
          label: 'period',
          nameList: ['startTime', 'endTime'],
          required: false,
          message: 'periodMsg',
          defaultValue: null,
          defaultResValue: [0, 0],
        },
        areaId: {
          label: 'area',
          name: 'areaId',
          required: false,
          message: 'areaMsg',
          defaultValue: null,
          defaultResValue: '',
        },
        radio: [{
          label: 'mask',
          name: 'wearingMask',
          defaultResValue: 3,
          defaultValue: 3,
          valueItem: [
            {
              value: 2,
              label: 'yes',
            },
            {
              value: 1,
              label: 'no',
            },
          ],
        }],
        infos: 'footerImageInfo',
      };
    // dwell time
    case 244:
      return {
        files: {
          name: 'files',
          required: false,
          defaultResValue: '',
          MAXSIZE: 5 * 1024 * 1024,
        },
        icNumber: {
          label: 'ic',
          name: 'icNumber',
          required: false,
          message: '',
          defaultValue: null,
          defaultResValue: '',
        },
        name: {
          label: 'name',
          name: 'name',
          required: false,
          message: '',
          defaultValue: null,
          defaultResValue: '',
        },
        period: {
          label: 'period',
          nameList: ['startTime', 'endTime'],
          required: true,
          message: 'periodMsg',
          defaultValue: momentArr,
          defaultResValue: momentData,
          disableDataBool: true,
          hadInitTime: true,
        },
        areaId: {
          label: 'area',
          name: 'areaId',
          required: false,
          message: 'areaMsg',
          defaultResValue: '',
          defaultValue: null,
        },
        infos: 'footerImageInfo',
      };
    default:
      return {
        period: {
          label: 'period',
          nameList: ['startTime', 'endTime'],
          required: false,
          message: 'periodMsg',
          defaultValue: null,
          defaultResValue: [0, 0],
        },
        areaId: {
          label: 'area',
          name: 'areaId',
          required: false,
          message: 'areaMsg',
          defaultResValue: '',
          defaultValue: null,
        },
      };
  }
};
