import React, {useState, useEffect} from 'react';
import CustomTable from '../../components/customTable';
import {useTranslation} from 'react-i18next';
import {ExclamationCircleOutlined, InfoCircleOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  DatePicker,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Tooltip,
  Upload,
  Tag,
} from 'antd';
import imgError from '../../assets/imgError.png';
import {ADDNEWFACE, FACELIST, UPDATEFACE} from '../../api/Urls';
import {deleteFaceData, getSearchData, getBuildingIdOption} from '../../api';
import './style.css';
import {timeFormat} from '../../components/baseTime';
import moment from 'moment';

const MAXSIZE = 5 * 1024 * 1024;// 5M
const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {span: 24},
};

const FaceInformation = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [buildingIdOption, setBuildingIdOption] = useState([]);
  const [updateList, setUpdateList] = useState(0);
  const [modalTitle, setModalTitle] = useState('add');
  const [isModalShow, setIsModalShow] = useState(false);

  const [onSaving, setOnSaving] = useState(false);
  const [faceImageUrl, setFaceImageUrl] = useState(null);
  const [faceFile, setFaceFile] = useState(null);
  const [faceImageLoading, setFaceImageLoading] = useState(false);
  const [bodyImageUrl, setBodyImageUrl] = useState(null);
  const [bodyFile, setBodyFile] = useState(null);
  const [bodyImageLoading, setBodyImageLoading] = useState(false);

  const onCancel = () => {
    setIsModalShow(false);
    setFaceImageUrl(null);
    setBodyImageUrl(null);
    setFaceFile(null);
    setBodyFile(null);
    form.resetFields();
    setModalTitle('add');
  };

  const onFinish = (values) => {
    setOnSaving(true);
    const url = modalTitle === 'edit' ? UPDATEFACE : ADDNEWFACE;
    if (values.birthday) {
      values.birthday=moment(values.birthday).format('YYYY-MM-DD');
      const age=moment(values.birthday).fromNow(true).split('年');
      values.memberAge= age.length>1?age[0].toString():'0';
      console.log(age.length, values.memberAge);
    }

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if (key === 'file') {
          if (faceFile) {
            formData.append('file', faceFile);
          }
        } else if (key === 'bodyFile') {
          if (bodyFile) {
            formData.append('bodyFile', bodyFile);
          }
        } else if (key === 'age') {
          formData.append(key, values[key] ? parseInt(values[key]) : '');
        } else {
          formData.append(key, values[key] || '');
        }
      }
    });
    getSearchData(url, formData, t('flag')).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
      setIsModalShow(false);
      form.resetFields();
      setModalTitle('add');
      setFaceImageUrl(null);
      setBodyImageUrl(null);
      setFaceFile(null);
      setBodyFile(null);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      setOnSaving(false);
    });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const fileProps = (setFileFuc, setLoadingFuc, setUrlFuc) => {
    return {
      multiple: false,
      showUploadList: false,
      listType: 'picture-card',
      accept: 'image/*',
      customRequest: (info) => {
        if (info.file.size < MAXSIZE) {
          setFileFuc(info.file);
          setLoadingFuc(true);
          getBase64(info.file, (imageUrl) => {
            setUrlFuc(imageUrl);
            setLoadingFuc(false);
          });
        } else {
          message.warn(t('overSizeMsg'));
        }
      },
    };
  };
  const uploadButton = (loadingStatus) => (
    <div>
      {loadingStatus ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>{t('uploadPhoto')}</div>
    </div>
  );

  const onEdit = (data) => {
    setIsModalShow(true);
    setModalTitle('edit');
    setFaceImageUrl(data.faceImageShowUrl);
    setBodyImageUrl(data.bodyImageShowUrl);
    form.setFieldsValue({
      ...data,
      file: data.faceImageShowUrl,
      bodyFile: data.bodyImageShowUrl,
      memberAge: data.memberAge !== 0 ? data.memberAge : null,
      birthday: data.birthday?moment(data.birthday):null,
      gender: data.gender !== 0 ? data.gender : '',
      buildingId: data.buLiDingId?.toString().split(',').map((item)=>parseInt(item)),
    });
  };
  const onDelete = (item) => {
    const title = t('flag') === '1' ? 'Do you want to delete this face information?' : '是否删除此条人脸信息？';
    Modal.confirm({
      title: title,
      icon: <ExclamationCircleOutlined/>,
      onOk() {
      },
      onCancel() {
        deleteFaceData(item.id, t('flag')).then((res) => {
          message.success(res.msg);
          setUpdateList(updateList + 1);
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        });
      },
      okText: t('no'),
      cancelText: t('yes'),
    });
  };

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: t('faceImage'),
      dataIndex: 'faceImageShowUrl',
      key: 'faceImageShowUrl',
      onExport: true,
      // eslint-disable-next-line react/display-name
      render: (faceImageShowUrl) => (
        <Image alt="faceImage" src={faceImageShowUrl || 'error'} className="img80" fallback={imgError}/>
      ),
    },
    // {
    //   title: t('bodyImage'),
    //   dataIndex: 'bodyImageShowUrl',
    //   key: 'bodyImageShowUrl',
    //   onExport: true,
    //   // eslint-disable-next-line react/display-name
    //   render: (bodyImageShowUrl) => (
    //     <Image alt="bodyImage" src={bodyImageShowUrl || 'error'} className="img80" fallback={imgError}/>
    //   ),
    // },
    {
      dataIndex: 'memberName',
      key: 'memberName',
      title: t('name'),
      onExport: true,
      search: true,
    },
    {
      dataIndex: 'icNumber',
      key: 'icNumber',
      title: t('ic'),
      onExport: true,
      search: true,
    },
    {
      title: t('Building'),
      dataIndex: 'buLiDingId',
      key: 'buildingId',
      width: 300,
      onExport: true,
      // onExport: true,
      render: (buLiDingId) => buLiDingId?.toString().split(',').map((item) => (
        <Tag key={'buildingId' + item}>
          {buildingTypeFucData(item)}
        </Tag>
      )),
      // exportRender: (buLiDingId) => buLiDingId ?
      // buildingTypeFucData(buLiDingId) : '',
    },
    {
      title: t('types'),
      dataIndex: 'memberType',
      key: 'memberType',
      onExport: true,
      filters: [
        {text: t('register'), value: 1},
        {text: t('stranger'), value: 2},
        {text: t('personsInterest'), value: 3},
      ],
      filterMultiple: false,
      render: (type) => {
        if (type === 1) {
          return t('register');
        } else if (type === 2) {
          return t('stranger');
        } else if (type === 3) {
          return t('personsInterest');
        } else {
          return '';
        }
      },
      exportRender: (member) => {
        if (member.memberType === 1) {
          return t('register');
        } else if (member.memberType === 2) {
          return t('stranger');
        } else if (member.memberType === 3) {
          return t('personsInterest');
        }
        return '';
      },
    },
    {
      title: t('age'),
      dataIndex: 'memberAge',
      key: 'memberAge',
      onExport: true,
    },
    {
      title: t('gender'),
      dataIndex: 'gender',
      key: 'gender',
      onExport: true,
      filters: [
        {text: t('male'), value: 2},
        {text: t('female'), value: 1},
      ],
      filterMultiple: false,
      render: (gender) => {
        if (gender === 1) {
          return t('female');
        } else if (gender === 2) {
          return t('male');
        } else {
          return '';
        }
      },
      exportRender: (gender) => {
        if (gender.gender === 1) {
          return t('female');
        } else if (gender.gender === 2) {
          return t('male');
        }
        return '';
      },
    },
    {
      title: t('updateTime'),
      dataIndex: 'updateTime',
      key: 'updateTime',
      render: (updateTime)=>timeFormat(updateTime),
      exportRender: (updateTime)=>timeFormat(updateTime.updateTime),
      onExport: true,
    },
    {
      title: t('action'),
      key: 'Action',
      width: 260,
      // eslint-disable-next-line react/display-name
      render: (record) => (<Space>
        <Button type="link" onClick={() => {
          onEdit(record);
        }}>{t('edit')}</Button>
        <Button type="link" onClick={() => {
          onDelete(record);
        }}>{t('delete')}</Button>
      </Space>),
    },
  ];
  const buildingTypeFucData = (typeCode) => {
    const alarm=buildingIdOption.find((item)=>parseInt(item.value)===parseInt(typeCode));
    if (alarm) {
      return alarm.label;
    } else {
      return '';
    }
  };
  useEffect(() => {
    getBuildingIdOption(t('flag'))
        .then((res) => {
          setBuildingIdOption(res.data.map((item) => ({
            label: item.buildingName,
            value: item.id,
          })));
        })
        .catch((error) => {
          message.error({
            content: error.toString(),
            key: 'netError',
            duration: 2,
          });
        });
  }, []);
  return (
    <div>
      <CustomTable
        InitApi={FACELIST}
        updateList={updateList}
        columns={columns}
        addFuc={() => {
          // form.resetFields();
          setIsModalShow(true);
          setModalTitle('add');
        }}/>
      <Modal
        title={t(modalTitle)}
        destroyAll
        visible={isModalShow}
        footer={null}
        width={700}
        onCancel={onCancel}>
        <Spin spinning={onSaving}>
          <Form
            requiredMark={true}
            {...layout}
            name='faceListForm'
            className="margin-t-32 margin-b-16 bg-theme"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item name='id' hidden>
              <Input disabled={modalTitle==='add'} />
            </Form.Item>
            <Form.Item
              requiredMark={true}
              label={t('faceImage')}
              name="file"
              rules={[{
                required: true,
                message: t('flag') === '1' ? 'Please upload your clear face photo!' : '请上传清晰的面部照片！',
              }]}
            >
              <Upload
                {...fileProps(setFaceFile, setFaceImageLoading, setFaceImageUrl)}
              >
                {faceImageUrl ?
                  <img src={faceImageUrl} alt="avatar" className="img-card"/> :
                  uploadButton(faceImageLoading)}
              </Upload>
            </Form.Item>
            <Form.Item
              label={t('bodyImage')}
              name="bodyFile"
              hidden={true}
              rules={[{
                required: false,
                message: t('flag') === '1' ? 'Please upload your clear body photo!' : '请上传清晰的全身照片！',
              }]}
            >
              <Upload
                {...fileProps(setBodyFile, setBodyImageLoading, setBodyImageUrl)}
              >
                {bodyImageUrl ?
                  <img src={bodyImageUrl} alt="avatar" className="img-card"/> :
                  uploadButton(bodyImageLoading)}
              </Upload>
            </Form.Item>
            <Form.Item
              label={t('name')}
              name="memberName"
              rules={[{
                required: true,
                message: 'please input',
              }]}
            >
              <Input maxLength={100}/>
            </Form.Item>
            <Form.Item
              label={t('ic')}
              name="icNumber"
            >
              <Input maxLength={50}/>
            </Form.Item>
            <Form.Item
              label={t('types')}
              name="memberType"
              rules={[{
                required: true,
                message: 'please select',
              }]}
            >
              <Radio.Group >
                <Radio value={1}>
                  {t('register')}
                </Radio>
                <Radio value={3}>
                  {t('personsInterest')}
                  <Tooltip title={t('personsInterestInfo')}>
                    <InfoCircleOutlined style={{opacity: 0.7}}/>
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t('flag')==='1'?'Birthday':'出生日期'}
              name="birthday"
            >
              <DatePicker disabledDate={(current) => {
                return current > moment().endOf('day');
              }} style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item
              label={t('gender')}
              name="gender"
            >
              <Select>
                <Select.Option value={2}>{t('male')}</Select.Option>
                <Select.Option value={1}>{t('female')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name='buildingId' label={t('Building')} shouldUpdate
              rules={[{
                required: true,
                message: t('flag') === '1' ? 'Please select building!' : '请选择楼宇！',
              }]}>
              <Select options={buildingIdOption} />
            </Form.Item>
            <Form.Item className="text-center" {...tailLayout}>
              <Button onClick={onCancel} className="margin-r-32 transparent-btn">{t('cancel')}</Button>
              <Button className="full-btn" htmlType="submit">
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

FaceInformation.propTypes = {};

export default FaceInformation;
