/* eslint-disable no-tabs */
/* eslint-disable require-jsdoc */
// import {message} from 'antd';
import axios from 'axios';
import {storage} from '../utils/storage';
import {t} from 'i18next';
// import {request} from './ajax';
import * as Urls from './Urls';

export function executeDownload(data, fileName) {
  if (!data) {
    return '';
  }
  // 构建文件【二进制内容】
  const blob = new Blob([data]);
  // 非IE下载
  if ('download' in document.createElement('a')) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName);
  }
}
export function binaryFileDownLoad(url, params, _fileName) {
  const userInfo=storage.getData( 'userInfo');
  const token =userInfo?userInfo.token:null;
  const building=userInfo?(userInfo.buildingType===2?2:1):null;
  return new Promise((_resolve, _reject) => {
    const headers={
    //   'Content-Type': 'application/x-www-form-urlencoded',
      'token': token,
      'flag': t('flag'),
      'building': building,
    //   ...header,
    };
    console.log('headers:', headers);
    return axios.get(
        url,
        params,
        headers,
        {responseType: 'blob'},
    ).then((res) => {
      console.log(res);
    //   if (res.status == 200) {
    //     const {data} = res;
    //     if (data.type === 'application/json') {
    //       const reader = new FileReader();
    //       reader.readAsText(data, 'utf-8');
    //       reader.onload = () => {
    //         const error = JSON.parse(reader.result);
    //         if (error && error.code != 0) {
    //           message.error({
    //             content: error.msg ? error.msg : error.message ? error.message : '数据异常',
    //             duration: 6,
    //           });
    //           reject(error);
    //         }
    //         resolve(error);
    //       };
    //     } else {
    //       if (!fileName) {
    //         // 想让浏览器访问到其他相应头，需要在后服务器上设置Access-Control-Expose-Headers。
    //         // 想要在这里访问到content-disposition中定义的文件名，
    //         // 需要在后端设置headers.add("Access-Control-Expose-Headers", "Content-Disposition");
    //         const contentDisposition = res.headers['content-disposition'];
    //         if (contentDisposition) {
    //           fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], 'UTF-8');
    //         }
    //       }
    //       console.log(data, 'data');
    //       executeDownload(data, fileName);
    //     //   resolve({code: 0, data: res.data});
    //     }
    //   }
    });
  });
}

export const handleExport = () => {
  axios.get(Urls.LICENSEDOWNLOAD, {'responseType': 'blob'},
  ).then((res) => {
    console.log(res);
  });
};
