import React, {useEffect, useState} from 'react';
import {message, Select, Form} from 'antd';
import {getBuildingNameListData} from '../../../../api';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const LevelForm = ({item}) => {
  const {t} = useTranslation();
  const [buildingList, setBuildingList] = useState([]);
  const getBuildingList = (level='', setFn, finishFn)=>{
    getBuildingNameListData(t('flag')).then((res)=>{
      setFn(res.data);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  useEffect(() => {
    getBuildingList('', setBuildingList);
  }, []);
  return (

    <Form.Item
      hidden={item.hidden}
      name={item.name}
      label={t(item.label)}
      key={item.name+item.type}
      rules={[{
        required: item.required,
        message: item['message'+t('flag')],
      }]}
    >
      <Select
        style={{
          width: '100%',
        }}
      >
        {buildingList.map((item)=>(
          <Select.Option key={item.id} value={item.id}>{item.buildingName}</Select.Option>
        ))}
      </Select>
    </Form.Item>

  );
};
LevelForm.propTypes = {
  item: PropTypes.object.isRequired,
};
export default LevelForm;
