import React from 'react';
import {Line} from '@ant-design/charts';
import {Spin} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';


const AreaCharts = ({Data, Loading, Height, color}) => {
  const config = {
    data: Data,
    xField: 'date',
    theme: 'dark',
    yField: 'value',
    seriesField: 'category',
    loading: Loading,
    smooth: true,
    loadingTemplate: (<div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,.9)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Spin spinning={true}/>
    </div>),
    xAxis: {
      range: [0, 1],
      nice: true,
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${moment(v).format('HH:mm')}`,
      },
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${v} %`,
      },
    },
    color: color,
  };
  return <Line {...config} style={{height: Height}}/>;
};

AreaCharts.propTypes = {
  Data: PropTypes.arrayOf(PropTypes.object).isRequired,
  Height: PropTypes.number.isRequired,
  Loading: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

export default AreaCharts;
