import React, {} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Table} from 'antd';
import Player from 'video-react/lib/components/Player';


const DetailsDrawer = ({detailList, columnsDrawer, videoUrl}) => {
  return (
    <div>
      <Row>
        <Col className='video' span={24}>
          <Player fluid={false} width="100%" height={400}>
            <source src={videoUrl}/>
          </Player>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            className="marginT16"
            dataSource={detailList}
            columns={columnsDrawer}
            pagination={{
              pageSize: 8,
              simple: true,
              size: 'small',
              hideOnSinglePage: true,
            }}
          />
        </Col>

      </Row>
    </div>
  );
};

DetailsDrawer.propTypes = {
  columnsDrawer: PropTypes.arrayOf(PropTypes.object).isRequired,
  videoUrl: PropTypes.string.isRequired,
  detailList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DetailsDrawer;
