import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';

import {Row, Col, Select, Card, message} from 'antd';
import {useTranslation} from 'react-i18next';

import romUsage from '../../assets/resourceIcon/disk.png';
import gpuUsage from '../../assets/resourceIcon/gpu.png';
import cpuUsage from '../../assets/resourceIcon/cpu.png';
import ramUsage from '../../assets/resourceIcon/save.png';
import freeRAM from '../../assets/resourceIcon/free.png';

import './style.css';

import {getCurrentResourceData, getResourceData} from '../../api';
import AreaCharts from './components/AreaCharts';
import DualAxesCharts from './components/DualAxesCharts';

const ResourceManagement = () => {
  const defaultValue=1000*60*5;
  const {t} = useTranslation();
  const timer = useRef();
  const [resource, setResource]= useState(null);
  const [romTrend, setRomTrend] = useState([]);
  const [cpuTrend, setCpuTrend] = useState([]);
  const [gpuTrend, setGpuTrend] = useState([]);
  const [ramTrend, setRamTrend] = useState([]);
  const getResource=()=>{
    getResourceData(t('flag')).then((res)=>{
      setResource(res.data[res.data.length-1]);
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    });
  };
  const getCurrentResource=(type)=>{
    getCurrentResourceData(t('flag')).then((res)=>{
      const romList=type?[]:romTrend;
      const gpuList=type?[]:gpuTrend;
      const cpuList = type?[]:cpuTrend;
      const ramList = type?[]:ramTrend;
      const item=res.data;
      // data.forEach(item=>{
      romList.push({
        date: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
        value: parseFloat(item.rom), category: t('flag')==='1'?'ROM Usage':'ROM 利用率',
      });
      gpuList.push({
        date: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
        value: parseFloat(item.gpu), category: t('flag')==='1'?'GPU Usage':'GPU 利用率',
      });
      cpuList.push({
        date: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
        value: parseFloat(item.cpu), category: t('flag')==='1'?'CPU Usage':'CPU 利用率',
      });
      // ramList.push({
      //   'date': moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
      //   '空余内存': parseFloat(item.ram),
      //   'Memory Usage': parseFloat(item.ram),
      //   '已用内存': parseFloat(item.ramFree),
      //   'Free Memory': parseFloat(item.ramFree),
      // });
      ramList.push({
        'date': moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
        '空余内存': parseFloat(item.ramFree).toFixed(1),
        'Free Memory': parseFloat(item.ramFree).toFixed(1),
        'Memory Usage': parseFloat(parseFloat(item.ramTotal)-parseFloat(item.ramFree)).toFixed(1),
        '已用内存': parseFloat(parseFloat(item.ramTotal)-parseFloat(item.ramFree)).toFixed(1),
      });
      // });
      setCpuTrend(cpuList);
      setGpuTrend(gpuList);
      setRamTrend(ramList);
      setRomTrend(romList);
      setResource(res.data);
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    });
  };
  const onRefreshRateChange=(value)=>{
    clearInterval(timer.current);
    getCurrentResource('new');
    timer.current = setInterval(() => {
      getCurrentResource();
    }, value);
  };

  useEffect(()=>{
    getResource();
    getCurrentResource();
    timer.current = setInterval(() => {
      getCurrentResource();
    }, defaultValue);
  }, []);

  useEffect(()=>{
    onRefreshRateChange(defaultValue);
  }, [t('flag')]);
  return (
    <div>
      {t('refreshRate')}
      <Select className="margin-l-8 width300" defaultValue={defaultValue} onChange={onRefreshRateChange}>
        <Select.Option value={1000*60}>test 1min</Select.Option>
        <Select.Option value={1000*60*5}>{t('every5min')}</Select.Option>
        <Select.Option value={1000*60*30}>{t('every30min')}</Select.Option>
      </Select>
      <Row gutter={24}>
        <Col flex={1}>
          <div className="resource-title">
            <Row>
              <Col flex={3}>
                <div className="resource-header">
                  {t('romUsage')}
                </div>
                <div className="resource-value">{resource?.rom||'0%'}</div>
              </Col>
              <Col flex={2} className="text-right">
                <img alt="romUsage" src={romUsage} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col flex={1}>
          <div className="resource-title">
            <Row>
              <Col flex={3}>
                <div className="resource-header">
                  {t('gpuUsage')}
                </div>
                <div className="resource-value">{resource?.gpu||'0%'}</div>
              </Col>
              <Col flex={2} className="text-right">
                <img alt="gpuUsage" src={gpuUsage} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col flex={1}>
          <div className="resource-title">
            <Row>
              <Col flex={3}>
                <div className="resource-header">
                  {t('cpuUsage')}
                </div>
                <div className="resource-value">{resource?.cpu||'0%'}</div>
              </Col>
              <Col flex={2} className="text-right">
                <img alt="cpuUsage" src={cpuUsage} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col flex={1}>
          <div className="resource-title">
            <Row>
              <Col flex={3}>
                <div className="resource-header">
                  {t('ramUsage')}
                </div>
                <div className="resource-value">{resource?.ram||'0%'}</div>
              </Col>
              <Col flex={2} className="text-right">
                <img alt="gpuUsage" src={ramUsage} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col flex={1}>
          <div className="resource-title">
            <Row>
              <Col flex={3}>
                <div className="resource-header">
                  {t('freeRAM')}
                </div>
                <div className="resource-value">
                  {parseFloat(resource?.ramFree)||'0'} GB/ {resource?.ramTotal||'0'}GB
                </div>
              </Col>
              <Col flex={2} className="text-right">
                <img alt="gpuUsage" src={freeRAM} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="margin-t-16">
        <Col span={12}>
          <Card>
            <AreaCharts Data={romTrend} Height={240} Loading={romTrend.length===0} color="#0166FF"/>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <AreaCharts Data={cpuTrend} Height={240} Loading={cpuTrend.length===0} color="#FF9F47"/>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <AreaCharts Data={gpuTrend} Height={240} Loading={gpuTrend.length===0} color="#5FB2FF"/>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <DualAxesCharts Data={ramTrend} Height={240} Loading={ramTrend.length===0} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};


ResourceManagement.propTypes = {};

export default ResourceManagement;
