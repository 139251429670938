import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Button, Form, Input, InputNumber, message, Select, Space, Spin, Radio, Cascader} from 'antd';
import {useTranslation} from 'react-i18next';
// import {MultiAlarmTypeSelectOption} from '../baseTypeSelect';getCaSadListChildSecond getCaSadListChildThird
import {getAreaSettingList, getStaffNameListData, getCaSadList, getCaSadListChildSecond, getCaSadListChildThird} from '../../api';
import LevelForm from '../../pages/layoutView/level/components/LevelForm';
import AreaForm from '../../pages/layoutView/area/components/AreaForm';
// import {storage} from '../../utils/storage';
const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {span: 24},
};

const EditForms = ({columnsForm, onFinish, loading, initData, onCancel, getAIlist, allCasaDerData}) => {
  const [casader, setCasader] = useState([]);
  // const onCasaderChange = (value, selectedOptions) => {
  //   console.log(value, selectedOptions, 'change selectedOptions');
  // };
  const {t} = useTranslation();
  const [settingForm]= Form.useForm();
  const [receiverList, setReceiverList] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const onSubmit = (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key] ? values[key] : '';
    });
    onFinish(data);
    // settingForm.resetFields();
  };

  const getFullAreaList = ()=>{
    getAreaSettingList({
      pageNum: 1,
      pageSize: 100000,
    }, t('flag')).then((res)=>{
      setAreaOptions(res.data.items);
    }).catch((error)=>{
      message.error({
        content: error,
        key: 'netError',
        duration: 2,
      });
    });
  };
  const loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    if (selectedOptions.length === 1) {
      const response2 = await getCaSadListChildSecond(targetOption.value, t('flag'));
      if (response2.code===200) {
        if (response2.data.length > 0) {
          const level2Options = response2.data?.map((item) => ({
            value: item.id,
            key: item.id,
            label: item.levelName,
            isLeaf: false,
          }));
          targetOption.children = level2Options;
          targetOption.loading = false;
        } else {
          targetOption.children = null;
          targetOption.loading = false;
        }
      }
    } else if (selectedOptions.length === 2) {
      const response3 = await getCaSadListChildThird(targetOption.value, t('flag'));
      if (response3.code === 200) {
        const level3Options = response3.data?.items?.map((item) => ({
          value: item.areaId,
          key: item.areaId,
          label: item.areaName,
          isLeaf: true,
        }));

        targetOption.children = level3Options;
        targetOption.loading = false;
      }
    }

    setCasader([...casader]);
  };

  const onChange = (value, selectedOptions) => {
    console.log(value, selectedOptions);
  };

  const getReceiverList=(data)=>{
    getStaffNameListData(data).then((res)=>{
      if (res.code!==200) {
        message.error(res.msg);
      }
      const items = res.data;
      items.map((item, index)=>{
        item.key=index;
      });
      setReceiverList(items);
    }).catch((error)=>{
      message.error({
        content: error, key: 'netError', duration: 2,
      });
    });
  };
  useEffect(()=>{
    columnsForm.forEach((item)=>{
      if (item.type==='alarmSetForm') {
        getReceiverList();
      }
    });
    getFullAreaList();
    getCaSadList(t('flag')).then((res)=>{
      console.log(res.data, 'res一级选项的值');
      const level1Options = res.data.map((item) => ({
        value: item.id,
        key: item.id,
        label: item.buildingName,
        isLeaf: false,
      }));
      setCasader(level1Options);
    }).catch((error)=>{
      message.error({
        content: error,
        key: 'netError',
        duration: 2,
      });
    });
  }, []);
  useEffect(()=>{
    console.log('模态框加载');
    if (initData) {
      console.log(initData, 'initData数据');
      settingForm.setFieldsValue(initData);
    } else {
      settingForm.resetFields();
    }
  }, [initData]);


  return (
    <div className="bg-theme">
      <Spin spinning={loading}>
        <Form
          {...layout}
          name='cameraForm'
          form={settingForm}
          onFinish={onSubmit}
        >
          {columnsForm.map((item)=>{
            switch (item.type) {
              case 'primaryInput':
                return (<Form.Item
                  name={item.name}
                  key={item.name+item.type}
                  label={t(item.label)}
                  hidden={item.hidden}
                  disabled={item.disabled}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }]}>
                  <Input maxLength={item.maxLength||50} disabled={item.disabled}
                    placeholder={item['placeholders'+t('flag')]}
                  />
                </Form.Item>);
              case 'primaryInputLocation':
                return (<Form.Item
                  name={item.name}
                  key={item.name + item.type}
                  label={t(item.label)}
                  hidden={item.hidden}
                  disabled={item.disabled}
                  rules={[{
                    required: item.required,
                    message: item['message' + t('flag')],
                  }, {
                    pattern: new RegExp(item.regular),
                    message: item['regularMessage' + t('flag')],
                  },
                  ]}>
                  <Input maxLength={item.maxLength || 50} disabled={item.disabled}
                    placeholder={item['placeholders' + t('flag')]}
                  />
                </Form.Item>);
              case 'select':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name+item.type}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }]}
                >
                  <Select mode={item.mode||null}>
                    {item.options.map((option)=>(
                      <Select.Option key={option.label1+option.value} value={option.value}>
                        {option['label'+t('flag')]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>);
              case 'selectSpecial':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name + item.type}
                  rules={[{
                    required: item.required,
                    message: item['message' + t('flag')],
                  }]}
                >
                  <Select mode={item.mode || null}>
                    {item.options.map((option) => (
                      <Select.Option key={option.label1 + option.value} value={option.value}>
                        {option['label']}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>);
              case 'alarmTypes':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name+item.type}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }]}
                >
                  {/* <MultiAlarmTypeSelectOption /> */}
                  <Select mode="multiple">
                    {getAIlist.length&&getAIlist.map((item) => (
                      <Select.Option
                        key={item.algorithmName}
                        value={item.id.toString()}
                      >{t('flag') === '1' ? item.algorithmName+`  `+`[${t('startTime')} :${item.startTime.slice(0, 10)}] 
                      [${t('endTime')}: ${item.endTime.slice(0, 10)}]`:
                      item.algorithmNameCn+`  `+`[${t('startTime')}:${item.startTime.slice(0, 10)}][${t('endTime')}:${item.endTime.slice(0, 10)}]`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>);
              case 'textArea':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name+item.type}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }]}
                >
                  <Input.TextArea maxLength={200}/>
                </Form.Item>);
              case 'number':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name+item.type}
                  rules={[{
                    required: item.required,
                    message: item['message' + t('flag')],
                  }]}
                >
                  <InputNumber min={item.min} step={item.step} addonAfter={t(item.unit)}
                    max={item.max} style={{width: '100%'}}/>
                </Form.Item>);
              case 'image':
                return (item.image);
              case 'cascader':
                return (<Form.Item
                  name={item.name}
                  label={t(item.label)}
                  key={item.name + item.type}
                  rules={[{
                    required: item.required,
                    message: item['message' + t('flag')],
                  }]}
                >
                  <Cascader
                    options={casader}
                    value={1111}
                    loadData={loadData}
                    onChange={onChange}
                    changeOnSelect
                    placeholder="please select"
                  />
                </Form.Item>);
              case 'areaForm':
                return (<Form.Item
                  name={item.name}
                  label={t(item.label)}
                  key={item.name + item.type}
                  rules={[{
                    required: item.required,
                    message: item['message' + t('flag')],
                  }]}
                >
                  <Select>
                    {areaOptions.map(({areaId, areaName}) => (
                      <Select.Option key={areaId + 'area'} value={areaId}>{
                        areaName
                      }</Select.Option>
                    ))}
                  </Select>
                </Form.Item>);
              case 'LevelForm':
                return (<LevelForm item={item}/>);
              case 'AreaForm':
                return (<AreaForm item={item}/>);
              case 'alarmSetForm':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name + item.type}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }]}
                >
                  <Select mode="multiple" onChange={(value)=>value.log}>
                    {receiverList.map((item)=>(
                      <Select.Option
                        key={item.id}
                        value={(item.id).toString()}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>);
              case 'primaryInputOfAccess':
                return (<Form.Item
                  name={item.name}
                  key={item.name+item.type}
                  label={t(item.label)}
                  hidden={item.hidden}
                  disabled={item.disabled}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }, {
                    message: t('请输入正确的数字！'),
                    pattern: new RegExp(/^\+?[1-9]\d*$/),
                  }]}>
                  <Input maxLength={item.maxLength||50} disabled={item.disabled}
                  // placeholder={t['placeholder'+t('flag')]}
                  />
                </Form.Item>);
              case 'radio':
                return (<Form.Item
                  hidden={item.hidden}
                  name={item.name}
                  label={t(item.label)}
                  key={item.name + item.type}
                  rules={[{
                    required: item.required,
                    message: item['message'+t('flag')],
                  }]}
                >
                  <Radio.Group disabled={true}>
                    <Radio value={2} >{t('trial')}</Radio>
                    <Radio value={1} >{t('official')}</Radio>
                  </Radio.Group>
                </Form.Item>);
              default:
                return null;
            }
          })}
          <Form.Item className="text-center" {...tailLayout}>
            <Space size="large">
              <Button onClick={()=> {
                settingForm.resetFields();
                onCancel();
              }} htmlType="reset" className="transparent-btn">{t('cancel')}</Button>
              <Button className="full-btn" htmlType="submit">
                {t('submit')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

EditForms.propTypes = {
  columnsForm: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initData: PropTypes.object,
  loading: PropTypes.bool,
  getAIlist: PropTypes.array,
  allCasaDerData: PropTypes.array,
  // filterData: PropTypes.array,
  // setCasaderChange: PropTypes.bool,
};

export default EditForms;
/*eslint-disable*/
