import React, {useEffect, useState} from 'react';

import {Col, DatePicker, Drawer, Image, message, Row, Spin} from 'antd';

import BaseSearchFrom from '../components/BaseSearchFrom';
import ResultList from '../components/ResultList';
import Maps from './components/Maps';

import {useTranslation} from 'react-i18next';
import {getSearchData, getTracingListData} from '../../../api';
import * as Urls from '../../../api/Urls';
import Slider from 'react-slick';
import moment from 'moment';
import ErrorImg from '../../../assets/imgError.png';
import './style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {momentArr, momentData} from '../seacrhConfig';
const dateFormat = 'YYYY-MM-DD HH:mm';

const config={
  icNumber: {
    label: 'ic',
    name: 'icNumber',
    required: false,
    message: '',
    defaultResValue: '',
  },
  name: {
    label: 'name',
    name: 'name',
    required: false,
    message: '',
    defaultResValue: '',
  },
  periodDisableDateInstall: {
    label: 'period',
    nameList: ['startTime', 'endTime'],
    required: true,
    message: 'periodMsg',
    defaultValue: momentArr,
    defaultResValue: momentData,
  },
  areaId: {
    label: 'area',
    name: 'areaId',
    required: false,
    message: 'areaMsg',
    defaultValue: null,
    defaultResValue: '',
  },
  infos: 'footerImageInfo',
};
const baseConfig = {
  searchApi: Urls.GETFACERECOGNITIONLIST,
  detailsApi: Urls.GETFACERECOGNITIONDETAIL,
  comparesApi: Urls.GETCOMPARELIST,
  pageSize: 18,
  interest: false,
  imageKey: 'faceImgUrl',
  timeKey: 'imgTime',
  title: 'analysisListTitle',
};

const ContactTracing = () => {
  const {t} = useTranslation();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const [loading, setLoading]= useState(false);
  const [searchList, setSearchList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 18,
    size: 'small',
    total: 0,
    showSizeChanger: false,
    hideOnSinglePage: true,
  });
  const [showDetail, setShowDetail] = useState(false);
  const [compareData, setCompareData] = useState([]);
  const [alarmList, setAlarmList] = useState([]);
  const [traceList, setTraceList] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  const onSearch = (values, page) => {
    setLoading(true);
    values.pageNum = page || 1;
    values.pageSize = baseConfig.pageSize;
    setStartTime(values.startTime);
    setEndTime(values.endTime);
    const formData = new FormData();
    Object.keys(values).forEach((key)=>{
      formData.append(key, values[key]);
    });

    getSearchData(baseConfig.searchApi, formData, t('flag')).then((res)=>{
      setSearchList(res.data.items);

      setPagination({
        ...pagination,
        current: res.data.pageNum,
        total: parseInt(res.data.totalPage )* parseInt(res.data.pageSize),
        onChange: (page)=>onSearch(values, page),
      });
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>setLoading(false));
  };

  const viewDetails= (item) => {
    setDetailsLoading(true);
    const data = {
      userId: item.userId,
      startTime: startTime,
      endTime: endTime,
    };

    setShowDetail(true);
    getTracingListData(data, t('flag')).then((res) => {
      setCompareData(res.data.compareItems);
      setAlarmList(JSON.parse(JSON.stringify(res.data.alarmItems)));
      setTraceList(JSON.parse(JSON.stringify(res.data.traceItems)));
    }).catch((error) => {
      message.error({
        content: error ? error.toString() : t('apiError'),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => {
      setDetailsLoading(false);
    });
  };

  useEffect(()=>{
    const init={};
    Object.keys(config).forEach((key)=>{
      if (key==='radio') {
        config.radio.forEach((item)=>{
          init[item.name]=item.defaultResValue;
        });
      } else if (key==='period') {
        init.startTime=config.period.defaultResValue[0];
        init.endTime=config.period.defaultResValue[1];
      } else if (key!=='infos') {
        init[key]=config[key].defaultResValue;
      }
    });
    onSearch(init);
  }, []);

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col lg={10} md={12} xl={6}>
          <BaseSearchFrom onSearch={onSearch} config={config}/>
        </Col>
        <Col lg={14} md={12} xl={18}>
          <div className="details-box paddingT8">
            <div className="details-title">{t(baseConfig.title)}</div>
            {/* <div className="details-sort-box">
              <Button className="transparent-btn" onClick={()=> {
                // setSortType('score');
                setSearchList([]);
                setPagination({
                  ...pagination,
                  current: 1,
                  total: 0,
                });
              }}>{t('clear')}</Button>
            </div> */}
            <ResultList
              searchList={searchList}
              pagination={pagination}
              viewDetails={viewDetails}
              imageKey={baseConfig.imageKey}
              interest={baseConfig.interest}
              timeKey={baseConfig.timeKey}
            />
          </div>
        </Col>
      </Row>
      <Drawer
        width={800}
        title={t('details')}
        placement="right"
        visible={showDetail}
        destroyOnClose
        onClose={() => setShowDetail(false)}
      >

        <DatePicker.RangePicker
          showTime={{format: 'HH:mm'}}
          format={dateFormat}
          value={startTime?[moment(startTime), moment(endTime)]:null}
          size="small"
          disabled
        />
        <Spin spinning={detailsLoading}>
          <Maps alarmList={alarmList} traceList={traceList}/>
        </Spin>
        <div className="tracing-suspicious-box">
          <div className="tracing-suspicious-title">同行人员</div>
          <div className="padding-r-16 padding-l-16">
            <Slider {...settings}>
              {compareData&&compareData.map((item) => (
                <div
                  key={item.id}
                  style={{paddingLeft: 16, paddingRight: 16}}
                >
                  <div className="details-image-item" onClick={() => {
                    viewDetails({
                      userId: item.userId,
                      areaId: item.areaId === null ? 0 : item.areaId,
                      startTime: startTime,
                      endTime: endTime,
                    }, 2);
                  }}>
                    <div className="details-image-title">{item.cameraName}</div>
                    <div className="details-image">
                      <Image src={item.faceImage} preview={false} className="details-images" fallback={ErrorImg}/>
                    </div>
                    <div className="details-info">{moment(item.timestamp).format('YYYY-MM-DD, HH:mm:ss')}</div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Drawer>
      {/* <Modal
        title={t('details')}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={800}
        footer={null}
        destroyOnClose>
        <Spin spinning={detailsLoading}>
          {video?
            (<Player fluid={false} width={'100%'} height={350} src={video}/>):
            (<Slider {...settingsAlert}>
              {videoImage&&videoImage.map((item, index) => {
                return (
                  <div key={'img' + index}>
                    <Image preview={false} src={item} height="350px" fallback={ErrorImg}/>
                  </div>
                );
              })}
            </Slider>)
          }
        </Spin>
      </Modal>*/}
    </Spin>
  );
};

ContactTracing.propTypes = {};

export default ContactTracing;
