import React from 'react';
import {Bar} from '@ant-design/charts';
import {Spin} from 'antd';
import PropTypes from 'prop-types';

const BarCharts = ({Data, Height, Loading, Legend}) => {
  const config = {
    // padding: [Legend?30:0, 60, 0, 85],
    data: Data,
    theme: 'dark',
    xField: 'alarmCount',
    yField: 'areaName',
    yAxis: {
      label: {
        // 使用 formatter 函数来自定义标签格式
        formatter: (text, item, index) => {
          // 如果文本长度超过5个字符，则省略中间的部分
          if (text.length >=10 ) {
            return `${text.slice(0, 10)}...`;
          }
          return text;
        },
      },
    },
    seriesField: 'areaName',
    legend: Legend?{
      position: 'top',
      layout: 'horizontal',
    }:Legend,
    loading: Loading,
    // label: {
    //   position: 'right',
    //   style: {
    //     fill: '#eeeeee',
    //   },
    //   offset: 4,
    // },
    tooltip: {
      showTitle: false,
      formatter: (datum) => {
        return {name: datum.areaName, value: datum.alarmCount};
      },
    },
    maxBarWidth: 3,
    minBarWidth: 4,
    loadingTemplate: (<div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,.9)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Spin spinning={true}/>
    </div>),
    color: [

      '#D75353',

      '#FF5EC2',

      '#CE66CA',

      '#099CFC',

      '#3F6BD3',

      '#5AD4FE',

      '#28C4BD',

      '#FFB05D',

      '#FF5353',

      '#FFBABA',
    ],
    columnStyle: {
      lineWidth: .8,
    },
    xAxis: false,
  };
  return <Bar {...config} style={{height: Height}}/>;
};

BarCharts.propTypes = {
  Data: PropTypes.arrayOf(PropTypes.object).isRequired,
  Height: PropTypes.string.isRequired,
  Loading: PropTypes.bool.isRequired,
  Legend: PropTypes.bool.isRequired,
};
export default BarCharts;
