import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Base64 from 'base-64';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, message, Spin, Row, Col, Image} from 'antd';
import {httpLoading, login, userInfo} from '@/store/actions';
import {storage} from '@/utils/storage';
import {TabletOutlined} from '@ant-design/icons';
import {getAlarmTypeNameListData, userLogin, getVerify} from '../../api';
import {useTranslation} from 'react-i18next';
import './style.css';

const LoginPage = ({setLogged, setUserInfo}) => {
  const ng = useNavigate();
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const [codeLoading, setCodeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeImageUrl, setCodeImageUrl]= useState(null);
  const [captchaKey, setCaptchaKey] = useState('');
  const handleLogin = (values)=>{
    setLoading(true);
    userLogin({
      ...values,
      captchaKey,
      password: Base64.encode(`${values.password}_hcyNLB@`),
    }, t('flag')).then((res)=>{
      storage.saveData('local', 'userInfo', res.data);
      storage.saveData('local', 'modalTip', true);
      setUserInfo(res.data);
      setLogged(true);
      getAlarmTypeNameListData(t('flag')).then((res)=>{
        // console.log(res, '"登录获取算法列表!!!!!!!!!!!!"');
        storage.saveData('local', 'alarmType', res.data);
      }).catch((error)=>{}).finally(()=>ng('/console/home/211'));
    }).catch((error)=>{
      getCordImage();
      message.error(error.toString());
    }).finally(()=>setLoading(false));
  };
  const getCordImage=()=>{
    setCodeLoading(true);
    getVerify( t('flag')).then((res)=>{
      setCodeImageUrl(res.data.captcha);
      setCaptchaKey(res.data.captchaKey);
    }).finally(()=>setCodeLoading(false));
  };

  useEffect(()=>{
    getCordImage();
  }, []);

  return (
    <div className={'content-login'} style={{height: '100%'}}>
      <div className="login">
        <div className="login-box">
          <Spin spinning={loading}>
            <div className="login-box-title">{t('loginTitle')}</div>
            <Form
              name="loginForm"
              className="login-form"
              onFinish={(values)=>handleLogin(values)}
              form={form}
            >
              <Form.Item
                name="username"
                rules={[{required: true, message: t('usernameMessage')}]}
              >
                <Input
                  autoComplete='off'
                  placeholder={t('username')}
                  prefix={<span className="icon-font icon-xingzhuang"/>}
                  style={{width: 358}}
                  bordered={false}/>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{required: true, message: t('password')}]}
              >
                <Input.Password
                  autoComplete='off'
                  placeholder={t('password')}
                  prefix={<span className="icon-font icon-bianzu5"/>}
                  style={{width: 358}}
                  bordered={false}/>
              </Form.Item>
              <Row>
                <Col span={14}>
                  <Form.Item
                    size="large"
                    name="captcha"
                    rules={[{required: true, message: t('codeMessage')}]}
                  >
                    <Input
                      size="large"
                      autoComplete='off'
                      placeholder={t('code')}
                      prefix={<TabletOutlined />}
                      bordered={false}/>
                  </Form.Item>
                </Col>
                <Col span={10} className="padding-t-6">
                  <Spin spinning={codeLoading}>
                    <Image
                      src={`${codeImageUrl}`}
                      preview={false}
                      alt='code'
                      width="130px"
                      height={48}
                      onClick={getCordImage}
                    />
                  </Spin>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{width: 350, marginLeft: 101, height: 46}}>
                  {t('loginButton')}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    httpLoading: state.toggleHttpLoading.loading,
    logged: state.toggleLogin.logged,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setHttpLoading: (f)=>dispatch(httpLoading(f)),
    setLogged: (f)=>dispatch(login(f)),
    setUserInfo: (f)=>dispatch(userInfo(f)),
  };
};

LoginPage.propTypes = {
  httpLoading: PropTypes.bool.isRequired,
  setHttpLoading: PropTypes.func.isRequired,
  setLogged: PropTypes.func.isRequired,
  setUserInfo: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPage);
