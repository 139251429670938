import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import {Button, Col, Form, Image, List, message, Row, Select, Space, Spin, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import {BuildCascader} from '../../components/baseTypeSelect';
import {Input} from 'antd/es';
import ErrorImg from '../../assets/imgError.png';
import {getAreaSettingList, getMonitorListData} from '../../api';
import './style.css';
import online from '../../assets/monitoring/online.png';
import MonitoringDetails from './components/MonitoringDetails';
import {storage} from '../../utils/storage';

const RealTimeMonitoring = () => {
  const {t} = useTranslation();
  const homeType =storage.getData( 'userInfo')?.buildingType===2?'map':'building';
  const [loading, setLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    size: 'small',
    total: 0,
    showSizeChanger: false,
    hideOnSinglePage: true,
  });
  const [area, setArea] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [monitoringID, setMonitoringID]= useState(null);
  const getMonitoringList = (data) => {
    setLoading(true);
    const resData={
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      ...data,
    };
    getMonitorListData(resData, t('flag')).then((res)=>{
      setSearchList(res.data.items);
      setPagination({
        ...pagination,
        current: res.data.pageNum,
        pageSize: res.data.pageSize,
        total: res.data.totalPage*res.data.pageSize,
      });
    }).catch((error)=>{

    }).finally(()=>{
      setLoading(false);
    });
  };
  const getFullAreaList = ()=>{
    getAreaSettingList({
      pageNum: 1,
      pageSize: 100000,
    }, t('flag')).then((res)=>{
      setAreaOptions(res.data.items);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  const onSearch = (values) => {
    const resData={
      ...values,
      pageSize: pagination.pageSize,
      pageNum: 1,
    };
    if (values.location) {
      resData.location=typeof area==='object'?(
          area.length===3?area.join(','):area.join(',')+',-1'):
        (area.toString()??'');
    } else {
      delete resData.location;
    }

    getMonitoringList(resData);
  };
  const onSearchByName = (values) => {
    const resData={
      ...values,
      pageSize: pagination.pageSize,
      pageNum: 1,
    };
    getMonitoringList(resData);
  };
  useEffect(() => {
    getMonitoringList();
    getFullAreaList();
  }, []);

  return (
    <div>
      {!monitoringID&&(<Spin spinning={loading}>
        <div className="monitoring-bg">
          <Row>
            <Col flex="auto">
              <Form onFinish={onSearch} layout="inline">
                <Form.Item label={t('status')} name="cameraStatus">
                  <Select style={{width: 140}}>
                    <Select.Option value={-1}>{t('all')}</Select.Option>
                    <Select.Option value={1}>{t('isOpen')}</Select.Option>
                    <Select.Option value={0}>{t('notOpen')}</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={homeType!=='map'?t('buildingLevelArea'):t('area')}
                  name="location">

                  {homeType==='map'?( <Select style={{width: 160}}>
                    {areaOptions.map(({areaId, areaName}) => (
                      <Select.Option key={areaId + 'area'} value={areaId}>{
                        areaName
                      }</Select.Option>
                    ))}
                  </Select>):(<BuildCascader type='multilayer' onChange={setArea}/>)}
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      {t('submit')}
                    </Button>
                    <Button htmlType="reset">
                      {t('reset')}
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
            <Col flex="400px">
              <Form onFinish={onSearchByName} layout="inline" className="flex-right">
                <Form.Item name="cameraName" className="flex-right">
                  <Input placeholder={t('pleaseInputCameraName')} style={{width: 280}}/>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="flex-right">
                    {t('search')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <List
            className="margin-t-16"
            dataSource={searchList}
            pagination={pagination}
            renderItem={(item, index) => (
              <List.Item
                className="monitoring-item"
                key={'list' + index}
              >
                {item.cameraStatus === 0 ?( <div
                  className="monitoring-image-box"
                >
                  <Tooltip title={t('flag')==='1'?
                    'The camera is off, so there is no live feed.':
                    '摄像头已关闭，暂无直播信号。'}>
                    <div className="monitoring-image-bg"/>
                  </Tooltip>
                </div>):( <div
                  className="monitoring-image-box"
                  onClick={() => {
                    setMonitoringID(item.id);
                  }}
                >
                  <div className="monitoring-status">
                    <img src={online} alt="online"/> {t('online')}
                  </div>
                  <Image
                    src={item.cameraPhotos}
                    preview={false}
                    className="monitoring-image" fallback={ErrorImg}/>
                </div>)}
                <div className="monitoring-name">
                  {item.cameraName}
                </div>
                <div className="monitoring-info">
                  {item.cameraLocation==='null,null,null'?t('notBindBuildMessage'):item.cameraLocation}
                </div>
              </List.Item>
            )}
            grid={{
              gutter: 2,
              xl: 3,
              xxl: 4,
            }}
          />
        </div>
      </Spin>)}
      {monitoringID&&(<MonitoringDetails CID={monitoringID} goBack={setMonitoringID}/>)}
    </div>
  );
};

RealTimeMonitoring.propTypes = {};

export default RealTimeMonitoring;
