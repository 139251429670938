import React, {} from 'react';
import PropTypes from 'prop-types';
import {Image, Row, Col} from 'antd';
import {Player} from 'video-react';
import ErrorImg from '../../../assets/imgError.png';
import {VideoCameraOutlined, FieldTimeOutlined} from '@ant-design/icons';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

const DetailsModal = ({detail, maskHad}) => {
  const {t} = useTranslation();
  return (
    <div>
      <div className="text-center">
        {detail.videoUrl?
            (<Player fluid={false} width={'100%'} height={350} src={detail.videoUrl}/>):
            (<Image preview={false} src={detail.bigImgUrl} height="350px" fallback={ErrorImg}/>)
        }
      </div>
      <Row>
        <Col flex="200px" className="padding16">
          <Image preview={false} src={detail.faceImgUrl||ErrorImg} alt="img" fallback={ErrorImg} width="100%"/>
        </Col>
        <Col flex="auto " className="padding16">
          <div className="margin-b-16">
            <VideoCameraOutlined className="margin-r-16"/>
            {detail.cameraName}
          </div>
          <div className="margin-b-16">
            <FieldTimeOutlined className="margin-r-16"/>
            {moment(detail.time).format('YYYY-MM-DD  HH:mm:ss')}
          </div>
          <div className="information-title">{t('information')}</div>
          <div className="information-item-box">
            {detail.sex!==0&&<div className="information-item" key="sex">
              {t('gender')}: {detail.sex === 1 ? t('female') : t('male')}
            </div>}
            {detail.mask!==0&&maskHad&&(<div className="information-item" key="mask">
              {t('mask')}: {detail.mask===2?t('yes') : t('no')}
            </div>)}
          </div>
        </Col>
      </Row>
    </div>
  );
};

DetailsModal.propTypes = {

  detail: PropTypes.objectOf({
    videoUrl: PropTypes.string,
  }).isRequired,
  maskHad: PropTypes.bool,
};

export default DetailsModal;
