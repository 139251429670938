import React, {useEffect, useState, useRef} from 'react';
import ReactPlayer from 'react-player';
// import ImgCrop from 'antd-img-crop';
import moment from 'moment';
import './style.css';
import {Select, Timeline, Button, Upload, Space, Table, Radio, Image, message, Spin, Empty, Modal} from 'antd';
// const {TextArea} = Input;
import {getAlgoNameList, imageUpload, getVideoAnalysisTask, getTaskDetail, faceAndImageAi} from '../../api';
// import imgError from '../../assets/imgError.png';
// import {exportFallImage} from '../../components/fallImage/fallImage';
import {useTranslation} from 'react-i18next';
import {PlusOutlined, LeftOutlined, RightOutlined, PlayCircleFilled, CheckCircleFilled, LoadingOutlined} from '@ant-design/icons';
import {ExclamationCircleOutlined} from '@ant-design/icons';
// import expsImg from '../../assets/expires.png';
// import expdImg from '../../assets/expired.png';

const BasicRecognition = ()=>{
  const {t}=useTranslation();
  const [selectAlgo, setSelectAlgo]=useState([]);
  const [tableLoading, setTableLoading]=useState(false);
  const [resultCheck, setResultCheck]=useState(0);
  // const [firstDataDetail, setFirstDataDetail]=useState();
  // 获取视频帧
  const [selectChangeId, setSelectChangeId]=useState();
  const [updateList, setUpdateList]=useState(0);
  const [uploadVideo, setUploadVideo]=useState();
  const [uploadImage, setUploadImage]=useState();
  const [smallImageIndex, setSmallImageIndex]=useState(-1);
  const [fileSizeStatus, setFileSizeStatus]=useState(true);
  const staticUrl=[
    {name: 'demo1', url: 'http://172.16.10.240:9666/resource/face_register/material/Identification_example/sample1.png', type: 1},
    {name: 'demo2', url: 'http://172.16.10.240:9666/resource/face_register/material/Identification_example/sample2.png', type: 1},
    {name: 'demo3', url: 'http://172.16.10.240:9666/resource/face_register/material/Identification_example/sample3.png',
      type: 1},
  ];
  const getAllAlgo = ()=>{
    getAlgoNameList().then((res)=>{
      setSelectAlgo(res.data);
    });
  };
  const onRadioChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    setResultCheck(e.target.value);
  };
  // 限制上传大小
  const beforeUploadVideo = (file) => {
    const isLt500M = file.size / 1024 / 1024 < 500; // 限制为500MB以下
    if (!isLt500M) {
      message.error(t('isLt500M'));
      setFileSizeStatus(false);
    } else {
      setFileSizeStatus(true);
    }
    return isLt500M;
  };
  const beforeUploadImage = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5; // 限制为5MB以下
    if (!isLt5M) {
      message.error(t('isLt5M'));
      return;
    }
    console.log(file.name, 'fileNAme');
    // 重命名文件
    const newFileName = `${moment().valueOf()}-${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '')}.jpg`;
    const newFile = new File([file], newFileName, {type: file.type});
    return Promise.resolve(newFile);
    // return isLt5M;
  };
  useEffect(()=>{
    getAllAlgo();
    getVideoAnalysisTask(t('flag')).then((res) => {
      console.log(res, '获取任务列表');
      setDataSource(res.data);
      if (res.data?.length>0) {
        // setFirstDataDetail(res.data[0].id);
        getTaskDetail(res.data[0]?.id, t('flag')).then((res)=>{
          console.log('获取详情数据');
          console.log(res, '获取的详情值');
          setViewDetail(res.data);
        }).catch((error)=>{
          message.error(error);
        });
      }
    }).catch((error)=>{
      message.error(error);
    },
    );
    // setTimeout(() => {
    // }, 1000);
  }, [updateList, t('flag')]);
  // 设置定时器定时请求列表
  useEffect(() => {
    const intervalId = setInterval(() => {
      // 获取最新的列表数据
      getVideoAnalysisTask(t('flag')).then((res) => {
        // console.log(res, '获取任务列表');
        setDataSource(res.data);
      }).catch((error)=>{
        message.error(error);
      },
      );
    }, 10000); // 每秒刷新一次
    // 清除定时器
    return () => clearInterval(intervalId);
  }, []);
  const clickStyle='3px solid rgb(23, 125, 220)';
  const normalStyle='1px solid #7B7B7B';
  const [imageStyleTab, setImageStyleTab]=useState(1);
  const [smallImageStyleTab, setSmallImageStyleTab]=useState(-1);
  const [dataSource, setDataSource]=useState([]);
  const [viewDetail, setViewDetail]=useState();
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const props = {
    showUploadList: false,
    multiple: false,
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      console.log(formData.get('file'));
      imageUpload(formData, t('flag')).then((res)=>{
        console.log(res, 'res上传完成后的值');
        message.success(res.msg);
        setUpdateList(updateList+1);
        setImageStyleTab(4);
        setUploadImage(res.data);
      }).catch((error)=>{
        message.error(error);
      });
    },
    onChange(info) {
      // console.log(info, '变更时的info');
      setFileList(info.fileList);
    },
  };

  const propsVideo = {
    showUploadList: false,
    multiple: false,
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      console.log(formData.get('file'));
      imageUpload(formData, t('flag')).then((res)=>{
        console.log(res, 'res上传完成后的值');
        setLoading(false);
        message.success(res.msg);
        setUpdateList(updateList+1);
        setImageStyleTab(5);
        setUploadVideo(res.data);
      }).catch((error)=>{
        message.error(error);
      });
    },
    onChange(info) {
      // if (info.file.status === 'uploading') {
      //   setLoading(true);
      //   return;
      // }
      console.log(fileSizeStatus, 'fileSizeStatus');
      if (fileSizeStatus) {
        setLoading(true);
      } else {
        setLoading(false);
      }
      setFileListVideo(info.fileList);
    },
  };

  const demoImageOne = (
    <div onClick={() => {
      setImageStyleTab(1);
    }} className='image-text-container' style={{
      border: imageStyleTab === 1 ? clickStyle : normalStyle}}>
      <div className='image-items-text'></div>
      <Image
        // width={'86px'}
        // height={'86px'}
        src={staticUrl[0].url}
        preview={false}
      />
    </div>
  );
  const demoImageTwo = (
    <div onClick={() => {
      setImageStyleTab(2);
    }} className='image-text-container' style={{
      border: imageStyleTab === 2 ? clickStyle : normalStyle}}>
      <div className='image-items-text'></div>
      <Image
        src={staticUrl[1].url}
        preview={false}
      />
    </div>
  );
  const demoVideo = (
    <div onClick={() => {
      setImageStyleTab(3);
    }} className='image-text-container' style={{
      border: imageStyleTab === 3 ? clickStyle : normalStyle}}>
      <div className='image-items-text'></div>
      <Image
        src={staticUrl[2].url}
        preview={false}
      />
    </div>
  );
  const onSelectChange=(e)=>{
    console.log(e, 'onSelectChange---e');
    setSelectChangeId(e);
  };
  const demoImageUpload = (
    <div onClick={() => {
      setImageStyleTab(4);
    }} className='image-text-container image-container-upload' style={{
      border: imageStyleTab === 4 ? clickStyle : normalStyle}}>
      <div className='image-items-text'>
        点击更换图片</div>
      {/* <Image
        src={uploadImage}
        preview={false}
      /> */}
      <div className='image-container-out-div'
        style={{backgroundImage: `url(${uploadImage})`}}>
      </div>
    </div>
  );
  const demoImageUploadVideo = (
    <div onClick={() => {
      setImageStyleTab(5);
    }} className='image-text-container image-container-upload' style={{
      border: imageStyleTab === 5 ? clickStyle : normalStyle}}>
      {!loading&&<><div className='image-items-text'>
        点击更换视频</div>
      <PlayCircleFilled style={{fontSize: '20px'}}/></>}
      {loading&&<><div className='image-items-text-loading'>Uploading...</div>
        <LoadingOutlined style={{fontSize: '16px', marginTop: '40px'}}/></>}
    </div>
  );
  const handleRightClick=()=>{
    scrollToRight();
  };
  const handleLeftClick=()=>{
    scrollToLeft();
  };
  const contentRef = useRef(null);

  const scrollToLeft=()=>{
    contentRef.current.scrollLeft -= contentRef.current.offsetWidth * 0.3;
  };

  const scrollToRight=()=>{
    contentRef.current.scrollLeft += contentRef.current.offsetWidth * 0.3;
  };
  const [fileList, setFileList] = useState([]);
  const [fileListVideo, setFileListVideo] = useState([]);
  const uploadButton = (
    <div className='uploadButton'>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('UploadImg')}
      </div>
    </div>
  );
  const uploadVideoButton = (
    <div className='uploadButton'>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('UploadVideo')}
      </div>
    </div>
  );

  const timeline=(a, b, c)=>{
    return (
      <>
        <Timeline>
          <Timeline.Item dot={<div className='license-time'>{a}</div>}>
            <div className="step-layout">
              <div className='step-title'>
                {t('SelectAlgorithm')}
              </div>
              <div className='step-select'>
                <Select allowClear className='step-select-item' onChange={onSelectChange}
                  options={selectAlgo.map((item) => (
                    {
                      value: item.id,
                      label: item.algorithmName,
                    }
                  ))}
                />
                <div className='descriptionForSelect'>{selectChangeId && <ExclamationCircleOutlined />}{' '}
                  {selectAlgo?.filter((item) => item.id === selectChangeId)[0]?.description}</div>
              </div>
            </div>

          </Timeline.Item>
          <Timeline.Item dot={<div className='license-time'>{b}</div>}>
            <div className='license-step'>
              {t('AnalysisObjects')} <span style={{fontSize: '12px', color: 'grey', marginLeft: '5px'}}> {t('ChooseSampleObject')}
              </span>
            </div>
            <Space>
              {demoImageOne}
              {demoImageTwo}
              {demoVideo}
              <Upload
                beforeUpload={beforeUploadImage}
                listType="picture-card"
                fileList={fileList}
                {...props}
                accept=".pdf,.jpg,.jpeg,.png"
              >
                {fileList.length >= 1 ? demoImageUpload : uploadButton}
              </Upload>
              <Upload
                beforeUpload={beforeUploadVideo}
                listType="picture-card"
                fileList={fileListVideo}
                {...propsVideo}
                accept=".mp4"
                disabled={loading}
              >
                {fileListVideo.length >= 1 ? demoImageUploadVideo : uploadVideoButton}
              </Upload>
            </Space>
          </Timeline.Item>
          <Timeline.Item dot={<div className='license-time'>{c}</div>}>
            <div className='license-step'>
              {t('CreateNewTask')}<span style={{fontSize: '12px', color: 'grey', marginLeft: '5px'}}> {t('CreateNewTaskTip')}
              </span>
            </div>
            <Button type='primary' onClick={()=>{
              if (!selectChangeId) {
                message.error('请选择算法对象');
                return;
              }
              setTableLoading(true);
              if (imageStyleTab === 1 || imageStyleTab === 2 || imageStyleTab === 3) {
                faceAndImageAi({
                  aitype: selectChangeId,
                  fileUrl: staticUrl[imageStyleTab - 1].url,
                  type: staticUrl[imageStyleTab - 1].type,
                }, t('flag')).then((res) => {
                  console.log(res, '上传图片和视频');
                  message.success(res.msg);
                  setUpdateList(updateList + 1);
                  setTimeout(() => {
                    setTableLoading(false);
                  }, 2000);
                  setViewDetail(res.data);
                }).catch((error) => {
                  setTableLoading(false);
                  message.error(error);
                });
              } else if (imageStyleTab === 4) {
                faceAndImageAi({
                  aitype: selectChangeId,
                  fileUrl: uploadImage,
                  type: 1,
                }, t('flag')).then((res) => {
                  message.success(res.msg);
                  setUpdateList(updateList + 1);
                  setTimeout(() => {
                    // message.success(res.msg);
                    setTableLoading(false);
                  }, 2000);
                  setViewDetail(res.data);
                }).catch((error) => {
                  setTableLoading(false);
                  message.error(error);
                });
              } else if (imageStyleTab === 5) {
                faceAndImageAi({
                  aitype: selectChangeId,
                  fileUrl: uploadVideo,
                  type: 2,
                }, t('flag')).then((res) => {
                  message.success(res.msg);
                  setUpdateList(updateList + 1);
                  setTimeout(() => {
                    // message.success(res.msg);
                    setTableLoading(false);
                  }, 2000);
                  setViewDetail(res.data);
                }).catch((error) => {
                  setTableLoading(false);
                  message.error(error);
                });
              }
            }}>{t('Create')}</Button>
          </Timeline.Item>
        </Timeline>
      </>
    );
  };
  // 获取视频关键帧作为封面
  // 视频------视频截图 ~~ 视频播放状态 ~~

  return (
    <div className='page-container'>
      <div className="width-half">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          gap={16}
        >
          <div
            className='basic-body'
          >
            <div className="basic-title">
              {t('AnalysisTaskCreation')}
            </div>
            {timeline(1, 2, 3)}
          </div>
          <div
            className='basic-body basic-title-table'
          >
            <div className="basic-title ">
              {t('TaskList')}<span style={{fontSize: '12px', color: 'grey', marginLeft: '5px'}}> {t('TaskListTips')}
                <Table rowKey={(record) => record.id} dataSource={dataSource} className='tableStyle' pagination={false}>
                  <Table.Column width={'80px'} title={t('status')} dataIndex='imageStatus' key='imageStatus' render={(record) =>{
                    // console.log(record, 'record的值');
                    if (record===1) {
                      return (<div style={{color: 'greenyellow'}}><CheckCircleFilled /></div>);
                    } else if (record===0) {
                      return (<div style={{color: 'grey'}}><CheckCircleFilled /></div>);
                    } else {
                      return (<div style={{color: 'red'}}><CheckCircleFilled /></div>);
                    }
                  }}/>
                  <Table.Column title={t('FileName')} dataIndex="fileName" key="fileName"
                  />
                  <Table.Column title={t('Algorithm')} dataIndex="algorithmsName" key="algorithmsName" />
                  <Table.Column width={'80px'} title={t('Type')} dataIndex="fileType" key="fileType" render={ (record) =>{
                    return (<Space><div>{record===1?'Video':'Image'}</div></Space>);
                  }}/>
                  <Table.Column width={'180px'} title={t('createTime')} dataIndex="createTime" key="createTime" />
                  <Table.Column title={t('action')} render={ (record) =>{
                    return (<Space><a style={{color:
                    '#177ddc'}} onClick={()=>{
                      setTableLoading(true);
                      setViewDetail();
                      getTaskDetail(record.id, t('flag')).then((res)=>{
                        // console.log(res, '获取的详情值');
                        // setUpdateList(updateList+1);
                        setViewDetail(res.data);
                        setSmallImageIndex(-1);
                        setSmallImageStyleTab(-1);
                      }).catch((error)=>{
                        message.error(error);
                      });
                    }}>view</a></Space>);
                  }}/>
                </Table>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='width-right'>
        <div className='analysis-top'>
          <span> {t('AnalysisResults')}</span>
          <Radio.Group onChange={onRadioChange} defaultValue={0} buttonStyle="solid">
            <Radio.Button value={0}>{t('Result')}</Radio.Button>
            <Radio.Button value={1}>{t('ResponseJSON')}</Radio.Button>
          </Radio.Group>
        </div>
        {viewDetail&&resultCheck===0&&<>
          {/* //有小图的情况-大图 */}
          {viewDetail.smallImages.length>0&&<div className='image-container'>
            {smallImageIndex !== null ? smallImageIndex!==-1?<div className='image-container-out-div'
              style={{backgroundImage: `url(${viewDetail?.smallImages[smallImageIndex]?.imageResultUrl})`}}>
            </div>:<div className='image-container-out-div'
              style={{backgroundImage: `url(${viewDetail?.recognition.imageResultDetailsUrl})`}}>
            </div> :
              <div></div>}
          </div>}
          {/* //有小图的情况-小图 */}
          {viewDetail.smallImages.length>0&&<div className='scroll-container'>
            <div className='scroll-click' onClick={handleLeftClick}><LeftOutlined style={{fontSize: '24px'}} /></div>
            <div className='image-tab' ref={contentRef}>
              <div className='image-tab-container ' >
                <div className='small-image-container' style={{
                  border: smallImageStyleTab===-1 ? clickStyle : normalStyle}}>
                  <Image className='image-item-tab' preview={false} key={-1} onClick={() => {
                    setSmallImageIndex(-1);
                    setSmallImageStyleTab(-1);
                  }} src={ viewDetail?.recognition.imageResultDetailsUrl} />
                </div>
                {viewDetail?.smallImages?.map((item, index) => (
                  <div className='small-image-container' style={{
                    border: smallImageStyleTab===index ? clickStyle : normalStyle}} key={index} onClick={() => {
                    setSmallImageStyleTab(index);
                    setSmallImageIndex(index);
                  }}>
                    <Image className='image-item-tab' preview={false} key={index} src={item.imageResultUrl} />
                  </div>
                ))
                }
              </div>
            </div>
            <div className='scroll-click scroll-click-right' onClick={handleRightClick}><RightOutlined style={{fontSize: '24px'}} /></div>
          </div>}
          {/* //只有大图的情况 */}
          {/* eslint-disable-next-line */}
          {!viewDetail.smallImages.length>0&&<div className='image-container'>
            {viewDetail.recognition.fileType===0?<div className='image-container-out-div'
              style={{backgroundImage: `url(${viewDetail?.recognition.imageResultDetailsUrl?
                viewDetail?.recognition.imageResultDetailsUrl :
                'http://172.16.10.240:9666/resource/material/Identification_example/error-image.png'})`}}></div>:
              // 视频文件case
            <div><PlayCircleFilled onClick={()=>{
              console.log('点击视频播放');
              setIsVideoOpen(true);
            }} style={{fontSize: '100px', color: viewDetail?.recognition.imageResultDetailsUrl?'#0041A5':'grey'}}/>
            <Modal width={900} footer={null} title={t('AnalysisResults')} visible={isVideoOpen}
              destroyOnClose onCancel={() => setIsVideoOpen(false)}>
              {viewDetail?.recognition.imageResultDetailsUrl?<div className='modal-video'><ReactPlayer
                url={viewDetail?.recognition.imageResultDetailsUrl}
                controls={true}
                playing={true}
                width='100%' height='100%'
              /></div>:<div className='no-data-video'>{t('noData')}</div>}
            </Modal></div>}
          </div>}
          {smallImageIndex === -1 && <div><div className='detailsTask'>{t('TaskDetails')}</div>
            <ul className='detailsTaskText'>
              <li>{t('FileName')}: {viewDetail?.recognition.fileName}</li>
              <li>{t('Algorithm')}: {viewDetail?.recognition.algorithmsName}</li>
              <li>{t('Type')}: {viewDetail?.recognition.fileType===0?'Image':'video'}</li>
              <li>{t('createTime')}: {viewDetail?.recognition.createTime}</li>
              {viewDetail?.recognition.personNumber&&<li>{t('personNumber')}: {viewDetail?.recognition.personNumber}</li>}
              <li>{t('description')}: {viewDetail?.recognition.description?viewDetail?.recognition.description:'No Data'}</li>
            </ul>
            {viewDetail?.recognition.textResult&&<div>{t('ResultDescription')}</div>}
            {viewDetail?.recognition.textResult&&<ul className='detailsTaskText'>
              <li>{viewDetail?.recognition.textResult}</li>
            </ul>}</div>}
          {smallImageIndex !== -1 && <><div className='detailsTask'>{t('TaskDetails')}</div>
            <ul className='detailsTaskText'>
              <li>{t('Gender')}: {viewDetail?.smallImages[smallImageIndex]?.gender===0?'女':
              viewDetail?.smallImages[smallImageIndex]?.gender===1?'男':t('noData')}</li>
              <li>{t('Age')}: {viewDetail?.smallImages[smallImageIndex]?.age?viewDetail?.smallImages[smallImageIndex]?.age:t('noData')}</li>
            </ul></>}
        </>}
        {viewDetail&&resultCheck===1&&<div>
          <div className='json-container'><pre>
            {viewDetail?.recognition?.responseJson?viewDetail?.recognition?.responseJson:
            <div style={{marginTop: '20px', marginLeft: '20px'}}>{t('noData')}</div>}</pre>
          </div>
        </div>}
        {!viewDetail && <div className='no-data'>
          {!viewDetail &&tableLoading===false&&<div className='no-data-item'><Empty description={'No result'} /></div>}
          <div className='no-data-item'><Spin className='spin' spinning={tableLoading} tip="Result generating..."></Spin></div>
          {viewDetail && <div className='no-data-item'><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" description={
            <span>
              There are many detection tasks, your task
              is waiting in line at number 12. Thanks for your patience.
            </span>
          } /></div>}
        </div>}
      </div>
    </div>
  );
};
BasicRecognition.propTypes = {};

export default BasicRecognition;
