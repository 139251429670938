/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import logo from '../../../assets/ACCLIVIS.png';
import smallLogo from '../../../assets/small-logo.png';
import {storage} from '../../../utils/storage';
import {useTranslation} from 'react-i18next';
import Icon, {PieChartOutlined} from '@ant-design/icons';
import Sider from 'antd/lib/layout/Sider';

const AccessIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9916 12.9825C16.3144 12.9038 16.6444 13.0833 16.7409
      13.3901C16.914 14.0471 17.001 14.7224 17 15.4002C17 15.7314
      16.721 16 16.3768 16H0.623146C0.27919 16 0 15.7313 0 15.4002C0
      11.7523 2.49433 8.65628 5.92601 7.60404C4.83813 6.83825 4.12396
      5.6091 4.12396 4.2123C4.12396 1.88591 6.08318 0 8.49999 0C10.9168
      0 12.876 1.88591 12.876 4.2123C12.876 6.53869 10.9168 8.4246 8.49999
      8.4246C8.49211 8.4246 8.48467 8.42355 8.47723 8.42249C8.46979 8.42144
      8.46235 8.42039 8.45448 8.42039C4.68497 8.44313 1.5885 11.2452 1.27342
       14.8012H15.7292C15.695 14.4229 15.6294 14.0497 15.534 13.6841C15.4673
       13.3701 15.6689 13.0611 15.9916 12.9825ZM11.5641 4.21398C11.5641 2.58804
       10.19 1.26537 8.50086 1.26537C6.81172 1.26537 5.43765 2.58804 5.43765
       4.21398C5.43765 5.83993 6.81172 7.16259 8.50086 7.16259C10.19 7.16259
       11.5641 5.83993 11.5641 4.21398Z"
      fill="currentColor"
    />
    <path
      d="M10.0901 10.6646C9.84069 10.4086 9.43639 10.4086 9.18702
    10.6646C8.93766 10.9207 8.93766 11.3359 9.18702 11.592L11.3454
    13.8076C11.4649 13.9308 11.6272 14 11.7965 14C11.9658 14 12.1282
    13.9308 12.2476 13.8076L16.813 9.11938C17.0623 8.86331 17.0623
    8.44813 16.813 8.19206C16.5636 7.93598 16.1593 7.93598 15.9099
    8.19206L11.7957 12.417L10.0901 10.6646V10.6646Z"
      fill="currentColor"
    />
  </svg>
);
const RealTimeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="7.35" stroke="currentColor" strokeWidth="1.3" />
    <path
      d="M4.63586 18C5.95463 18.6407 7.43534 19 8.99999 19C10.5646 19 12.0454 18.6407 13.3641 18"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <circle cx="9" cy="9" r="2.35" stroke="currentColor" strokeWidth="1.3" />
  </svg>
);
const ResourceIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.825 19.1484C8.93329 19.2109 9.06671 19.2109
    9.175 19.1484L16.4022 14.9758C16.5105 14.9132 16.5772 14.7977
     16.5772 14.6726V6.32735C16.5772 6.20231 16.5105 6.08676
     16.4022 6.02424L9.175 1.85159C9.06671 1.78907 8.93329 1.78907
     8.825 1.85159L1.59776 6.02424C1.48947 6.08676 1.42276 6.20231
     1.42276 6.32735V14.6726C1.42276 14.7977 1.48947 14.9132 1.59776
     14.9758L8.825 19.1484L8.5 19.7113L8.825 19.1484Z"
      stroke="currentColor"
      strokeWidth="1.3"
    />
    <path
      d="M8.99997 18.9442V10.4998L1.61108 6.27755"
      stroke="currentColor"
      strokeWidth="1.3"
    />
    <path
      d="M3.30005 7.65002V10.5"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path d="M9 10.5L16.3889 6.27778" stroke="currentColor" strokeWidth="1.3" />
  </svg>
);
const FaceAnalysisIcon = () => (
  <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg"
    width="18"
    fill="rgba(255, 255, 255, 0.65)"
    height="18"
    // style={{backgroundColor: 'white'}}
    viewBox="0 0 200 200" >
    <path d="M101.5,104.2L24.4,164c-1,0.8-2.5,0.6-3.3-0.4s-0.6-2.5,0.4-3.3c0,0,0,0,0,0l77.4-60V31.8c0-1.3,1.1-2.4,2.4-2.4
s2.4,1.1,2.4,2.4v68l74.5,60.5c1,0.8,1.2,2.3,0.3,3.4c-0.8,1-2.3,1.2-3.4,0.3L101.5,104.2z"/>
    <path d="M108.2,30.7L180.8,154c2.7,4.5,1.2,10.4-3.4,13c-1.5,0.9-3.1,1.3-4.8,1.3H27.4c-5.3,0-9.5-4.3-9.5-9.5
c0-1.7,0.5-3.4,1.3-4.8L91.8,30.7c2.7-4.5,8.5-6,13-3.4C106.2,28.2,107.4,29.3,108.2,30.7L108.2,30.7z M100,35.6L27.4,158.8h145.2
L100,35.6z"/>
  </svg>
);

const CustomMenus = (collapsed) => {
  console.log(collapsed, '1123');
  const menuTree = storage.getData('userInfo')?.menuTree;
  const {t} = useTranslation();
  const locationParams = useParams();
  const [currentMenu, setCurrentMenu] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(
      locationParams.moduleId || locationParams.key,
  );
  const [openKeys, setOpenKeys] = useState([locationParams.key]);
  const onOpen = (value) => {
    setOpenKeys([value[value.length - 1]]);
  };
  const onClick = (value) => {
    setSelectedKeys(value.key);
  };
  const ICONS = {
    211: (
      <span className="icon-font" style={{paddingRight: 9}}>
        &#xe607;
      </span>
    ),
    212: (
      <span className="icon-font" style={{paddingRight: 9}}>
        &#xe632;
      </span>
    ),
    242: (
      <span className="icon-font" style={{paddingRight: 11}}>
        &#xe604;
      </span>
    ),
    214: (
      <span className="icon-font" style={{paddingRight: 11}}>
        &#xe60c;
      </span>
    ),
    215: (
      <span className="icon-font" style={{paddingRight: 9, fontSize: 18}}>
        &#xe62e;
      </span>
    ),
    216: (
      <span className="icon-font" style={{paddingRight: 9, fontSize: 18}}>
        &#xe62f;
      </span>
    ),
    217: (
      <span className="icon-font" style={{paddingRight: 9}}>
        &#xe629;
      </span>
    ),
    218: (
      <span className="icon-font" style={{paddingRight: 9, fontSize: 18}}>
        &#xe631;
      </span>
    ),
    219: (
      <Icon component={AccessIcon} style={{paddingRight: 11}} alt="icon" />
    ),
    248: (
      <Icon
        component={RealTimeIcon}
        alt="icon"
        style={{paddingRight: 9, fontSize: 18}}
      />
    ),
    249: (
      <Icon component={ResourceIcon} style={{paddingRight: 11}} alt="icon" />
    ),
    254: (
      <Icon component={FaceAnalysisIcon} style={{paddingRight: 8, color: 'white'}} alt="icon" />
    ),
  };

  const menuLink = (pID, sID) => {
    let url = '/' + pID;
    if (sID) {
      url = url + '/' + sID;
    }
    return url;
  };

  useEffect(() => {
    if (menuTree) {
      setCurrentMenu(menuTree.children);
    }
  }, []);
  return (
    <>
      <Menu
        defaultSelectedKeys={['1']}
        // defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        // inlineCollapsed={collapsed}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onClick={onClick}
        onOpenChange={onOpen}
        multiple="false"
        style={{
          overflow: 'auto',
          borderTop: '60px solid  #00348b',
          borderBottom: '60px solid  #00348b',
          height: '100vh',
          maxWidth: '200px',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 200}}

      >
        {currentMenu.length > 0 ?
            currentMenu.map((menu) => {
              if (menu.children && menu.children.length > 0) {
                return (
                  <Menu.SubMenu
                    icon={ICONS[menu.id]}
                    key={menu.id}
                    title={t('flag') === '1' ? menu.text : menu.cnName}
                  >
                    {menu.children.map((menuItem) => (
                      <Menu.Item key={menuItem.id}>
                        <Link
                          to={`/console/${menuItem.menuType}${menuLink(
                              menu.id,
                              menuItem.id,
                          )}`}
                        >
                          {t('flag') === '1' ?
                            menuItem.text :
                            menuItem.cnName}
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={menu.id} icon={ICONS[menu.id]}>
                    <Link
                      to={`/console/${menu.menuType}${menuLink(menu.id)}`}
                    >
                      {t('flag') === '1' ? menu.text : menu.cnName}
                    </Link>
                  </Menu.Item>
                );
              }
            }) :
            null}
      </Menu>

    </>
  );
};

export default CustomMenus;
