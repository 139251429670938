import React, {useEffect, useState} from 'react';
import {Cascader, message, Form} from 'antd';
import {getEvelListData} from '../../../../api';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const AreaForm = ({item}) => {
  const [buildingLevelOptions, setBuildingLevelOptions] = useState([]);
  const {t} = useTranslation();
  const getLevelList = async (level='', setFn, finishFn)=>{
    getEvelListData({
      pageNum: 1,
      pageSize: 100,
      levelName: level,
    }, t('flag')).then((res)=>{
      setFn(res);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };

  const processLevelsData = ( async (data)=>{
    const levelsData = data;
    const levelsDataNew = [];
    if (levelsData.data) {
      const levelsDataRaw = levelsData.data.items;
      levelsDataRaw.map((level, index)=>{
        const buildingName = level.buildingId;
        const building = levelsDataNew.filter((building) => building.value === buildingName);
        // IF BUILDING ALREADY ADDED
        if (building.length>0) {
          const newLevel = {
            value: level.id,
            label: level.levelName,
            key: index,
          };
          const buildingIndex = levelsDataNew.findIndex((building) => building.value == buildingName);
          if (buildingIndex!==-1) {
            levelsDataNew[buildingIndex].children.push(newLevel);
          }
        } else {
          // IF NEW BUILDING NOT ADDED YET
          const newBuilding = {
            value: buildingName,
            label: level.buildingName,
            key: index+10,
            children: [
              {
                value: level.id,
                label: level.levelName,
                key: index,
              },
            ],
          };
          levelsDataNew.push(newBuilding);
        }
      });
      setBuildingLevelOptions(levelsDataNew);
    }
  });
  useEffect(async () => {
    getLevelList('', processLevelsData, ()=>{});
  }, []);
  return (
    <div>
      <Form.Item
        hidden={item.hidden}
        name={item.name}
        label={t(item.label)}
        key={item.name+item.type}
        rules={[{
          required: item.required,
          message: item['message'+t('flag')],
        }]}
      >
        <Cascader
          options={buildingLevelOptions}
          expandTrigger="hover"
          style={{width: '100%'}}
        />
      </Form.Item>
    </div>
  );
};

AreaForm.propTypes = {
  item: PropTypes.object.isRequired,
};
export default AreaForm;
