import './App.less';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import {ConfigProvider, Radio} from 'antd';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './locales/i18n';
import Login from './pages/login';
import CustomLayout from './components/layout/index';
import {storage} from './utils/storage';
import Home from './pages/home';
import ErrorPage from './pages/errorPage';
// import CustomForms from './pages/systemManagement/customForms';
import Level from './pages/layoutView/level';
import {getAlarmTypeNameListData} from './api';
import {connect} from 'react-redux';
import Building from './pages/layoutView/building';
import Analytics from './pages/analytics';
import Area from './pages/layoutView/area';
import Roles from './pages/accessControl/roles';
import Users from './pages/accessControl/users';
import VisitHistory from './pages/report/visitHistory';
import VisitorCount from './pages/report/visitorCount';
import CameraManagement from './pages/layoutView/cameraManagement';
import ContactTracing from './pages/analytics/contactTracing';
import AlarmSettings from './pages/systemManagement/AlarmSettings';
import Alerts from './pages/alerts';
import ResourceManagement from './pages/resourceManagement';
import RealTimeMonitoring from './pages/realTimeMonitoring';
import License from './pages/systemManagement/license';
import FaceInformation from './pages/faceInformation';
import StaffContact from './pages/systemManagement/staffContact';
import FaceRecognition from './pages/Recognition/FaceRecognition';
import BasicRecognition from './pages/Recognition/BasicRecognition';
import CustomForms from './pages/systemManagement/customForms';
// import SystemManagement from './pages/systemManagement/systemManagement/systemPage/systemManagement';

const App = () => {
  const {t} = useTranslation();
  const alarmTypes = storage.getData('alarmType')||[];
  // 获取目前缓存的语言i18nextLng
  const lang = storage.getData('i18nextLng')||'zh';
  const userInfo = storage.getData('userInfo');
  const [locale, setLocale] = useState(lang==='zh'?zhCN:enUS);
  const {i18n} = useTranslation();
  useEffect(()=>{
    i18n.changeLanguage(lang);
  }, []);

  return (

    <BrowserRouter basename={window.__POWERED_BY_QIANKUN__ ? '/react16' : '/'}>
      <div className="change-locale">
        <Radio.Group value={locale} onChange={(e) => {
          setLocale(e.target.value);
          if (e.target.value.locale!=='en') {
            i18n.changeLanguage( 'zh');
          } else {
            i18n.changeLanguage( 'en');
          }
          moment.locale(e.target.value.locale);
          if (userInfo&&!alarmTypes) {
            getAlarmTypeNameListData(t('flag')).then((res)=>{
              storage.saveData('local', 'alarmType', res.data);
            }).catch((error)=>{});
          }
        }}>
          <Radio.Button key="en" value={enUS}>
            EN
          </Radio.Button>
          <Radio.Button key="cn" value={zhCN}>
            中
          </Radio.Button>
        </Radio.Group>
      </div>
      <ConfigProvider locale={locale}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/console" element={<CustomLayout/>}>
            <Route path="home/:key" element={<Home/>} />
            <Route path="faceInformation/:key" element={<FaceInformation/>} />
            <Route path="analytics/:key/:moduleId" element={<Analytics/>} />
            <Route path="alerts/:key" element={<Alerts/>} />
            <Route path="visitHistory/:key/:moduleId" element={<VisitHistory/>} />
            <Route path="visitorCount/:key/:moduleId" element={<VisitorCount/>} />
            <Route path="systemManagement/:key/:moduleId" element={<CustomForms/>} />
            {/* <Route path="systemManagement/:key/:moduleId" element={<SystemManagement/>} /> */}


            <Route path="alarmSettings/:key/:moduleId" element={<AlarmSettings/>} />
            <Route path="level/:key/:moduleId" element={<Level/>} />
            <Route path="licenseOffline/:key/:moduleId" element={<License type="offLine"/>} />
            <Route path="licenseOnline/:key/:moduleId" element={<License type="onLine"/>} />
            <Route path="building/:key/:moduleId" element={<Building/>} />
            <Route path="area/:key/:moduleId" element={<Area/>} />
            <Route path="camera/:key/:moduleId" element={<CameraManagement/>} />
            <Route path="users/:key/:moduleId" element={<Users/>} />
            <Route path="roles/:key/:moduleId" element={<Roles/>} />
            <Route path="contactTracing/:key/:moduleId" element={<ContactTracing/>} />
            <Route path="real-timeMonitoring/:key" element={<RealTimeMonitoring/>} />
            <Route path="resourceManagement/:key" element={<ResourceManagement/>} />
            <Route path="staffContact/:key/:moduleId" element={<StaffContact />} />
            <Route path="alerts/:key" element={<Alerts/>} />
            <Route path="faceRecognition/:key/:moduleId" element={<FaceRecognition />} />
            <Route path="recognition/:key/:moduleId" element={<BasicRecognition />} />

            <Route path="*" element={<ErrorPage/>} />
          </Route>
          {/* <Route path="/console/home/*" element={<Home/>} />*/}

        </Routes>
        {/* {userInfo?<CustomLayout />:<Login />}*/}

      </ConfigProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    logged: state.toggleLogin.logged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

App.propTypes = {
  logged: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);


