const Config1=[
  {
    label: 'faceSimilarityThreshold',
    name: 'Face Similarity Threshold',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.01,
    require: true,
  },
  {
    label: 'faceRecognitionMode',
    name: 'Face Recognition Mode',
    type: 'radio',
    value: [
      {
        key: 'completePattern',
        value: '1',
      },
      {
        key: 'partPattern',
        value: '2',
      },
    ],
    require: true,
  },
  {
    label: 'socialDistanceDuration',
    name: 'Social Distance Duration',
    type: 'duration',
    min: 5,
    max: 180,
    unit: 'second',
    step: 1,
    require: true,
  },
  {
    label: 'noMaskDuration',
    name: 'No Mask Duration',
    type: 'duration',
    min: 5,
    max: 180,
    unit: 'second',
    step: 1,
    require: true,
  },
  {
    label: 'loiteringDuration',
    name: 'Loitering Duration',
    type: 'duration',
    min: 5,
    max: 180,
    unit: 'second',
    step: 1,
    require: true,
  },
  {
    label: 'overStayers',
    name: 'Overstayers',
    type: 'timePicker',
    require: true,
  }, {
    label: 'fallingThreshold',
    name: 'Falling Threshold',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.01,
    require: true,
  },
  {
    label: 'maskThreshold',
    name: 'Mask Threshold',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.01,
    require: true,
  }, {
    label: 'smokingRecognitionThreshold',
    name: 'Smoking Recognition Threshold',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.01,
    require: true,
  },
];
const Config2=[
  {
    label: 'pictureStorePath',
    name: 'pictureStorePath',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'pictureShowPath',
    name: 'pictureShowPath',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'cameraStorePath',
    name: 'cameraStorePath',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'cameraShowPath',
    name: 'cameraShowPath',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'cameraTime',
    name: 'cameraTime',
    type: 'timePicker',
    require: true,
  },
  {
    label: 'location',
    name: 'originalLocation',
    type: 'primaryInputForAccess',
    require: true,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'SMS Monthly Max Number',
    name: 'sendSmsCount',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'telegramChatId',
    name: 'telegramChatId',
    type: 'primaryInputHadPlaceHolder',
    require: true,
  },
  {
    label: 'telegramToken',
    name: 'telegramToken',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'whatsappInstanceId',
    name: 'whatsappInstanceId',
    type: 'primaryInput',
    require: true,
  },
  {
    label: 'whatsappToken',
    name: 'whatsappToken',
    type: 'primaryInputHadDisabled',
    require: true,
    disable: true,
  },
  {
    label: 'serverMonitoringReceiver',
    name: 'serverMonitoringReceiver',
    type: 'primaryInput',
    require: true,
  },


];

export const Config = {'241': Config1, '232': Config2};
export const configModuleId=['241', '232'];
