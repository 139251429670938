import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Space, Popconfirm, message, Modal, Tag} from 'antd';
import {addStaffContact, deleteStaffContact, updateStaffContact, getBuildingIdOption} from '../../../api';
import CustomTable from '../../../components/customTable';
import {STAFFLIST} from '../../../api/Urls';
import EditForms from '../../../components/forms/EditForms';

const StaffContact =() => {
  const [buildingIdOption, setBuildingIdOption] = useState([]);
  const {t} = useTranslation();
  const columnsForm=[{
    label: 'id',
    name: 'id',
    type: 'primaryInput',
    hidden: true,
  },
  {
    label: 'name',
    name: 'name',
    type: 'primaryInput',
    required: true,
    message1: 'Please input name!',
    message2: '请输入员工姓名!',
  },
  {
    label: 'mobile',
    name: 'contactPhone',
    type: 'primaryInput',
    required: true,
    message1: 'Please input mobile!',
    message2: '请输入员工手机号!',
  },
  {
    label: 'email',
    name: 'email',

    type: 'primaryInput',
    required: true,
    message1: 'Please input email!',
    message2: '请输入员工邮箱号!',
  },
  {
    label: 'Building',
    name: 'buildingId',
    mode: 'multiple',
    type: 'selectSpecial',
    required: true,
    message1: 'Please input building!',
    message2: '请选择楼宇!',
    options: buildingIdOption,
  },
  ];

  const [updateList, setUpdateList] = useState(0);
  const [onSaving, setOnSaving] = useState(false);
  const [initData, setInitData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('add');
  const handleDelete = (id) => {
    deleteStaffContact(id, t('flag')).then((res) => {
      message.success(res.msg);
      setIsModalVisible(false);
    }).catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => setUpdateList(updateList + 1));
  };
  const onCancel=()=> {
    setIsModalVisible(false);
    setModalTitle('add');
    setInitData(null);
  };

  const onFinish = (values) => {
    console.log(values, '提交员工信息的数据');
    // values.buildingId=values.buildingId.toString;
    setOnSaving(true);
    const RequestPromise = modalTitle === 'add' ?
      addStaffContact({...values, buildingId: values.buildingId.toString()}, t('flag')) :
      updateStaffContact({...values, buildingId: values.buildingId.toString()}, t('flag'));

    RequestPromise.then((res)=> {
      message.success(res.msg);
      setOnSaving(false);
      setIsModalVisible(false);
      setModalTitle('add');
      setInitData(null);
      setUpdateList(updateList + 1);
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setOnSaving(false);
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      search: true,
      onExport: true,
    },
    {
      title: t('mobile'),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      // ...getColumnSearchProps('contactPhone'),
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      // ...getColumnSearchProps('contactPhone'),
    },
    {
      title: t('Building'),
      dataIndex: 'buildingId',
      key: 'buildingId',
      width: 300,
      // onExport: true,
      render: (buildingId) => buildingId?.split(',').map((item) => (
        <Tag key={'buildingId' + item}>
          {buildingTypeFucData(item)}
        </Tag>
      )),
      exportRender: (buildingId) => buildingId.buildingId ?
      buildingId.buildingId.split(',').map((item) => buildingTypeFucData(item)).join(',') : '',
    },
    {
      title: t('action'),
      key: 'action',
      width: 180,
      // eslint-disable-next-line react/display-name
      render: (record)=>(
        <Space>
          <Button type="link" onClick={(e) => {
            setInitData({...record, buildingId: record.buildingId?.split(',').map((item)=>parseInt(item))});
            setIsModalVisible(true);
            setModalTitle('edit');
          }}>{t('edit')}</Button>

          <Popconfirm
            title={t('flag')==='1'?'Are you sure to delete this staff?':'是否删除此工作人员？'}
            onConfirm={()=>handleDelete(record.id)}
            okText={t('yes')}
            cancelText={t('no')}
            color="#102864"
          >
            <Button type="link">{t('delete')}</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const buildingTypeFucData = (typeCode) => {
    const alarm=buildingIdOption.find((item)=>parseInt(item.value)===parseInt(typeCode));
    if (alarm) {
      return alarm.label;
    } else {
      return '';
    }
  };
  useEffect(() => {
    getBuildingIdOption(t('flag'))
        .then((res) => {
          setBuildingIdOption(res.data.map((item) => ({
            label: item.buildingName,
            value: item.id,
          })));
        })
        .catch((error) => {
          message.error({
            content: error.toString(),
            key: 'netError',
            duration: 2,
          });
        });
  }, []);
  return (
    <div>
      <CustomTable
        addFuc={() => {
          setIsModalVisible(true);
          setModalTitle('add');
        }}
        columns={columns}
        InitApi={STAFFLIST}
        updateList={updateList}/>
      <Modal
        title={t(modalTitle)}
        destroyAll
        destroyOnClose
        visible={isModalVisible}
        footer={null}
        width={700}
        onCancel={onCancel}>
        <EditForms
          columnsForm={columnsForm}
          loading={onSaving}
          initData={initData}
          onCancel={onCancel}
          isEdit={modalTitle === 'edit'}
          onFinish={onFinish}
        />
      </Modal>
    </div>
  );
};

export default StaffContact;
