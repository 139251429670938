import React, {useState, useRef, useEffect} from 'react';
import camera from '../../../assets/mapIcon/camera-online.png';
import cameraMsg from '../../../assets/mapIcon/camera-online -msg.png';
import online from '../../../assets/mapIcon/online.png';
import {Map, Marker, NavigationControl, InfoWindow} from 'react-bmapgl';
import mapStyle from '../../../assets/mapIcon/custom_map_config .json';

import {Row, Col, Badge, Radio, Card, Select, Space, message} from 'antd';
import {PlusOutlined, MinusOutlined, AimOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {storage} from '../../../utils/storage';
import {ETTodayValue, STTodayValue} from '../../../components/baseTime';
import {alarmColorFuc, alarmTypeFuc} from '../../../components/baseTypeSelect';
import {getHomeMapAlertListData, getMapAreaListData} from '../../../api';

const BMapGL= window.BMapGL;

const Maps = () => {
  const o =storage.getData( 'userInfo').originalLocation?.split(',')||[116.35, 39.928216];
  const center = new BMapGL.Point(o[0], o[1]);
  const mapRef=useRef();

  const [info, setInfo]= useState(null);
  const [zoom, setZoom]= useState(11);
  const [areaList, setAreaList] = useState([]);
  const [mapAlertList, setMapAlertList]= useState([]);
  const {t} = useTranslation();
  const locationData=(coordinate)=>{
    if (coordinate) {
      return new BMapGL.Point(coordinate.split(',')[0], coordinate.split(',')[1]);
    }
    return new BMapGL.Point(116.35, 39.928216);
  };
  const infoBox=()=>(<InfoWindow
    title=''
    position={locationData(info.coordinate)}
    height={150}
    onClose={()=>setInfo(null)}
  >
    <div>
      <div className="info-box-title">
        <Space >
          <span><img src={online} alt="c1"/> {info.cameraName}</span>
          <Badge count={info.indexMapMessageResponse.length}/>
        </Space>
      </div>
      <div className="info-box">
        {info.indexMapMessageResponse.map((item, index)=>(
          <Row className="info-des" key={item.alarmType+item.cameraName+index}>
            <Col span={20} >
              <Badge dot color={alarmColorFuc(item.alarmType)}/>
              <span className="padding-l-6">{alarmTypeFuc(item.alarmType)}</span>
            </Col>
            <Col span={4} className="text-right">{item.alarmCount}</Col>
          </Row>
        ))}
      </div>
      <div className="text-right ">
        <a href={`/console/alerts/215/?cameraId=${info.id}&startTime=${STTodayValue}&endTime=${ETTodayValue}`}
          className="info-box-footer">...</a>
      </div>
    </div>
  </InfoWindow>);
  const getMapAlert=(value)=> {
    getHomeMapAlertListData({
      ...value,
      dateType: 1,
    }).then((res) => {
      setMapAlertList(res.data);
    }).catch((error) => {
      message.error({
        content: error ? error.toString() : t('apiError'),
        key: 'netError',
        duration: 2,
      });
    });
  };
  const onAreaChange=(value)=>{
    if (value) {
      const coordinate= value.split('/')[1];
      const id=value.split('/')[0];
      getMapAlert({areaId: id});
      mapRef.current.map.setCenter(new BMapGL.Point(coordinate.split(',')[0], coordinate.split(',')[1]));
    } else {
      mapRef.current.map.setCenter(center);
    }
  };
  const getAreaList = ()=>{
    getMapAreaListData(t('flag')).then((res)=>{
      setAreaList(res.data);
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    });
  };
  useEffect(()=>{
    getMapAlert();
    getAreaList();
  }, []);
  return (
    <Card>
      <Row className="margin-b-16">
        <Col span={8}>
          {t('area')} <Select
            className="margin-l-8"
            style={{width: 200}}
            onChange={onAreaChange}
            placeholder={t('placeholder')}>
            <Select.Option value={0}>{t('all')}</Select.Option>
            {areaList.map((item)=>(
              <Select.Option
                key={item.id+'area'}
                value={item.id+'/'+item.coordinate}
              >{item.areaName}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={16} className="text-right">
          <Radio.Group>
            <Radio.Button
              value="0"
              disabled={zoom===21}
              onClick={()=> {
                setZoom(zoom + 1);
              }}>
              <PlusOutlined />
            </Radio.Button>
            <Radio.Button
              value="1"
              disabled={zoom===1}
              onClick={()=> {
                setZoom(zoom - 1);
              }}>
              <MinusOutlined />
            </Radio.Button>
            <Radio.Button
              value="2"
              onClick={()=> {
                mapRef.current.map.setCenter(center);
              }}>
              <AimOutlined />
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Map
        ref={mapRef}
        setCenter={center}
        zoom={zoom}
        mapStyleV2={{styleJson: mapStyle}}
        style={{height: 674}}
      >
        {mapAlertList.map((item)=> (<>
          {item.indexMapMessageResponse.length>0?(
            <Marker
              key={item.cameraName}
              icon={new BMapGL.Icon(cameraMsg, new BMapGL.Size(36, 36))}
              position={locationData(item.coordinate)}
              onClick={() => {
                setInfo(item);
              }}/>
          ):(<Marker
            key={item.cameraName}
            icon={new BMapGL.Icon(camera, new BMapGL.Size(36, 36))}
            position={locationData(item.coordinate)}
          />)
          }</>))}
        <NavigationControl />
        {info&&infoBox(info)}
      </Map>
    </Card>
  );
};

Maps.propTypes = {
};

export default Maps;
