import React, {} from 'react';
import PropTypes from 'prop-types';
import {Spin} from 'antd';
import {DualAxes} from '@ant-design/plots';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

const DualAxesCharts = ({Data, Loading, Height}) => {
  const {t} = useTranslation();
  const config = {
    data: [Data, Data],
    xField: 'date',
    theme: 'dark',
    yField: t('flag')==='1'?['Memory Usage', 'Free Memory']:['已用内存', '空余内存'],
    xAxis: {
      nice: true,
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${moment(v).format('HH:mm')}`,
      },
    },
    yAxis: [{
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${parseFloat(v).toFixed(1)} GB`,
      },
    }, {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${parseFloat(v).toFixed(1)} GB`,
      },
    }],
    loading: Loading,
    loadingTemplate: (<div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,.9)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Spin spinning={true}/>
    </div>),
    geometryOptions: [
      {
        geometry: 'line',
        smooth: true,
        color: '#59CD90',
        yAxis: {
          label: {
            // 数值格式化为千分位
            formatter: (v) => `${parseFloat(v).toFixed(1)}GB`,
          },
        },
      },
      {
        geometry: 'line',
        smooth: true,
        color: '#E8EB54',
        yAxis: {
          label: {
            // 数值格式化为千分位
            formatter: (v) => `${parseFloat(v).toFixed(1)}GB`,
          },
        },
      },
    ],
  };
  return (<DualAxes {...config} style={{height: Height}}/>);
};

DualAxesCharts.propTypes = {
  Data: PropTypes.arrayOf(PropTypes.object).isRequired,
  Height: PropTypes.number.isRequired,
  Loading: PropTypes.bool.isRequired,
};

export default DualAxesCharts;
