import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Row, Col, Badge, Radio, Space} from 'antd';
import {Map, Marker, Polyline, InfoWindow} from 'react-bmapgl';
import {PlusOutlined, MinusOutlined, AimOutlined} from '@ant-design/icons';

import camera from '../../../../assets/mapIcon/camera-online.png';
import cameraMsg from '../../../../assets/mapIcon/camera-online -msg.png';
import online from '../../../../assets/mapIcon/online.png';

import mapStyle from '../../../../assets/mapIcon/custom_map_config .json';
import {storage} from '../../../../utils/storage';
import {alarmColorFuc, alarmTypeFuc} from '../../../../components/baseTypeSelect';
import {ETTodayValue, STTodayValue} from '../../../../components/baseTime';
// import {useTranslation} from 'react-i18next';
const BMapGL= window.BMapGL;

const Maps = ({traceList, alarmList}) => {
  const [info, setInfo]= useState(null);
  const [zoom, setZoom]= useState(11);
  const [pathData, setPathData]= useState([]);
  const mapRef=useRef();
  const o =storage.getData( 'userInfo').originalLocation?.split(',')||[116, 192];
  const center = new BMapGL.Point(o[0], o[1]);

  const infoBox=()=>(<InfoWindow
    title=''
    position={info.coordinate}
    height={150}
    onClose={()=>setInfo(null)}
  >
    <div>
      <div className="info-box-title">
        <Space>
          <span><img src={online} alt="c1"/> {info.cameraName}</span>
          <Badge count={info.indexMapMessageResponse.length}/>
        </Space>
      </div>
      <div className="info-box">
        {info.indexMapMessageResponse.map((item, index)=>(
          <Row className="info-des" key={item.alarmType+item.cameraName+index}>
            <Col span={20} >
              <Badge dot color={alarmColorFuc(item.alarmType)}/>
              <span className="padding-l-6">{alarmTypeFuc(item.alarmType)}</span>
            </Col>
            <Col span={4} className="text-right">{item.alarmCount}</Col>
          </Row>
        ))}
      </div>
      <div className="text-right ">
        <a href={`/console/alerts/215/?cameraId=${info.id}&startTime=${STTodayValue}&endTime=${ETTodayValue}`}
          className="info-box-footer">...</a>
      </div>
    </div>
  </InfoWindow>);
  const pathFuc=(list)=>{
    const pathData=[];
    if (list.length) {
      list.forEach(({coordinate})=>(
        pathData.push(new BMapGL.Point(coordinate.split(',')[0], coordinate.split(',')[1]))
      ));
    }
    return pathData;
  };
  useEffect(()=>{
    setPathData(pathFuc(traceList));
    console.log(alarmList);
  }, [traceList]);

  return (
    <div>

      <div className="text-right">
        <Radio.Group>
          <Radio.Button
            value="0"
            disabled={zoom===21}
            onClick={()=> {
              setZoom(zoom + 1);
            }}>
            <PlusOutlined />
          </Radio.Button>
          <Radio.Button
            value="1"
            disabled={zoom===1}
            onClick={()=> {
              setZoom(zoom - 1);
            }}>
            <MinusOutlined />
          </Radio.Button>
          <Radio.Button
            value="2"
            onClick={()=> {
              mapRef.current.map.setCenter(center);
            }}>
            <AimOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>
      <Map
        ref={mapRef}
        setCenter={center}
        zoom={zoom}
        mapStyleV2={{styleJson: mapStyle}}
        style={{height: 540}}
      >
        {alarmList&&alarmList.map((item)=>(
          <Marker
            key={item.cameraId+item.alarmCount}
            icon={new BMapGL.Icon(item.indexMapMessageResponse?cameraMsg:camera,
                new BMapGL.Size(36, 36))}
            position={{lng: item.coordinate.split(',')[0], lat: item.coordinate.split(',')[1]}}
            onClick={()=> {
              setInfo(item.indexMapMessageResponse?{...item,
                coordinate: new BMapGL.Point(item.coordinate.split(',')[0], item.coordinate.split(',')[1]),
              }:null);
            }}/>
        ))}
        {/*
        <Marker
          icon={new BMapGL.Icon(camera, new BMapGL.Size(36, 36))}
          position={{lng: 116.33, lat: 40.01}} onClick={()=> {
            setInfo(new BMapGL.Point(116.33, 40.01));
          }}/>*/}

        {info&&infoBox(info)}
        {pathData.length>0&&(<Polyline
          path={pathData}
          strokeColor="#D75353"
          strokeWeight={4}
        />)}
      </Map>
    </div>
  );
};

Maps.propTypes = {
  traceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  alarmList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Maps;
