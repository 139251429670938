import React, {} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Table} from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const DetailsDrawer = ({detailList, columnsDrawer, currentImg}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div>
      <Row>
        <Col span={24} className="video-box">
          <Slider {...settings}>
            <div key={`detail_image`}>
              <img className="CarouselImg" src={currentImg}/>
            </div>
          </Slider>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            className="marginT16"
            dataSource={detailList}
            columns={columnsDrawer}
            pagination={{
              pageSize: 8,
              simple: true,
              size: 'small',
              hideOnSinglePage: true,
            }}
          />
        </Col>

      </Row>
    </div>
  );
};

DetailsDrawer.propTypes = {
  columnsDrawer: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentImg: PropTypes.string.isRequired,
  detailList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DetailsDrawer;
