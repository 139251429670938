import * as Urls from '../../../api/Urls';

export const getBaseConfig = (pageID) => {
  switch (parseInt(pageID)) {
    // Face Recognition
    case 213:
      return {
        detailsType: 'infos', // infos / bigImage/ /
        listType: 'list', // list / table
        searchApi: Urls.GETFACERECOGNITIONLIST,
        detailsApi: Urls.GETFACERECOGNITIONDETAIL,
        outDateNotice: 12,
        pageSize: 18,
        interest: true,
        imageKey: 'faceImgUrl',
        timeKey: 'imgTime',
        title: 'analysisListTitle',
      };
    // dwell time
    case 244:
      return {
        detailsType: 'drawer', // infos / bigImage/drawer /
        listType: 'table', // list / table
        searchApi: Urls.GETDWELLTIMELIST,
        outDateNotice: 10,
        detailsApi: '',
        pageSize: 18,
        interest: false,
      };
    // Persons of Interest
    case 220:
      return {
        detailsType: 'infos', // infos / bigImage/ /
        listType: 'list', // list / table
        searchApi: Urls.GETINTERESTLIST,
        detailsApi: Urls.GETINTERESTDETAIL,
        outDateNotice: 30,
        pageSize: 18,
        interest: false,
        imageKey: 'faceImageUrl',
        timeKey: 'imgTime',
        title: 'analysisListTitle',
      };
    // Mask
    case 224:
      return {
        detailsType: 'infos', // infos / bigImage/ /
        listType: 'list', // list / table
        searchApi: Urls.GETFACERECOGNITIONLIST,
        detailsApi: Urls.GETFACERECOGNITIONDETAIL,
        outDateNotice: 20,
        pageSize: 18,
        interest: false,
        imageKey: 'faceImgUrl',
        timeKey: 'imgTime',
        title: 'analysisListTitle',
        maskHad: 'true',
      };
    // falling
    case 221:
      return {
        detailsType: 'videoImages',
        detailsKey: 'detailUrl',
        videoKey: 'videoUrl',
        listType: 'list',
        searchApi: Urls.GETFALLINGLIST,
        outDateNotice: 7,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'imageUrl',
        timeKey: 'imageTime',
        title: 'analysisTitle',
      };
    //  smoke
    case 222:
      return {
        detailsType: 'bigImage',
        detailsKey: 'bigImgUrl',
        listType: 'list',
        searchApi: Urls.GETSMOKELIST,
        outDateNotice: 6,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'createTime',
        title: 'analysisTitle',
      };
    //  Social Distancing
    case 223:
      return {
        detailsType: 'bigImage',
        detailsKey: 'bigImgUrl',
        listType: 'list',
        searchApi: Urls.GETSOCIALLIST,
        outDateNotice: 3,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'createTime',
        title: 'analysisTitle',
      };
    //  Loitering
    case 226:
      return {
        detailsType: 'bigImage',
        // imageGroup: true,
        detailsKey: 'bigImgUrl',
        listType: 'list',
        searchApi: Urls.GETLOITERLIST,
        outDateNotice: 5,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'alarmTime',
        title: 'analysisTitle',
      };
    //  Trespassing
    case 227:
      return {
        detailsType: 'bigImage',
        detailsKey: 'bigImgUrl',
        listType: 'list',
        searchApi: Urls.GETTRESPASSINGLIST,
        outDateNotice: 50,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'alarmTime',
        title: 'analysisTitle',
      };
      // 入侵检测
    case 257:
      return {
        detailsType: 'bigImage',
        detailsKey: 'recordImage',
        listType: 'list',
        searchApi: Urls.GETINTRUTIONLIST,
        outDateNotice: 9,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'alarmTime',
        title: 'analysisTitle',
      };
    //  Over-stayers
    case 229:
      return {
        detailsType: 'bigImage',
        detailsKey: 'bigImgUrl',
        listType: 'list',
        searchApi: Urls.GETOVERSTAYERSLIST,
        outDateNotice: 40,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'alarmTime',
        title: 'analysisTitle',
      };
      // Grouping
    case 246:
      return {
        detailsType: 'bigImage',
        detailsKey: 'imageUrl',
        listType: 'list',
        searchApi: Urls.GETGROUPINGLIST,
        outDateNotice: 4,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'imageUrl',
        timeKey: 'imageTime',
        title: 'analysisTitle',
      };
    //  people count
    case 245:
      return {
        detailsType: 'bigImage',
        detailsKey: 'bigImgUrl',
        listType: 'list',
        searchApi: Urls.GETPEOPLECOUNTLIST,
        // outDateNotice: '10',
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'recordImage',
        timeKey: 'imageTime',
        title: 'analysisTitle',
      };
    default:
      return {
        detailsType: 'bigImage',
        videoKey: 'videoUrl',
        showVideo: true,
        listType: 'list',
        searchApi: Urls.GETSMOKELIST,
        detailsApi: '',
        pageSize: 18,
        interest: false,
        imageKey: 'imageUrl',
        timeKey: 'createTime',
        title: 'analysisTitle',
      };
  }
};
