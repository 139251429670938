import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Player from 'video-react/lib/components/Player';
import ErrorImg from '../../../assets/imgError.png';
import {Image, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons';

const DetailsSimpleModal = ({type, details, videoUrl, bigImageUrl, bigImageUrlImageGroup}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const {t}=useTranslation();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div>
      {type === 'videoImages' ? (
        <div className="padding16">
          {videoUrl ? (<div className='video'>
            <Player fluid={false} width="100%" height={400}>
              <source src={videoUrl}/>
            </Player>
          </div>) :
          (<Slider {...settings}>
            {details && details.map((item) => {
              return (
                <div key={'img' + item.id} className="text-center">
                  <Image
                    alt="img"
                    preview={false}
                    fallback={ErrorImg}
                    src={item.showImageUrl}
                    className="details-modal-image"/>
                </div>
              );
            })}
          </Slider>)}
        </div>) : (
        <div className="padding16 text-center">
          {!bigImageUrlImageGroup&&<Image
            src={bigImageUrl}
            preview={false}
            className="details-modal-image"
            fallback={ErrorImg}
          />}
          {bigImageUrlImageGroup&& <div className='tab-image-container'>
            {/* {bigImageUrl?.split(',').map((item, index) =>(
              <Image
                key={index}
                src={item}
                preview={true}
                className="details-modal-image"
                fallback={ErrorImg}
              />
            ))} */}
            <Image
              // key={index}
              src={bigImageUrl?.split(',')[currentImageIndex]}
              preview={false}
              className="details-modal-image"
              fallback={ErrorImg}
            />
            <div className='tab-button'>
              <Button ghost className='tab-right' disabled={!bigImageUrl}
                onClick={() => {
                  setCurrentImageIndex((currentImageIndex - 1 + bigImageUrl?.split(',').length) % bigImageUrl?.split(',').length);
                }}>
                <CaretLeftOutlined/>{t('Previous Image')}</Button>
              <Button ghost className='tab-left' disabled={!bigImageUrl}
                onClick={() => setCurrentImageIndex((currentImageIndex + 1) % bigImageUrl?.split(',').length)}>
                {t('Next Image')}<CaretRightOutlined/></Button>
            </div>
          </div>}
        </div>)}

    </div>
  );
};

DetailsSimpleModal.propTypes = {
  details: PropTypes.arrayOf(PropTypes.object),
  videoUrl: PropTypes.string,
  bigImageUrl: PropTypes.string,
  bigImageUrlImageGroup: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default DetailsSimpleModal;
