import React from 'react';
import {Line} from '@ant-design/charts';
import {Spin} from 'antd';
import PropTypes from 'prop-types';

const LineCharts = ({Data, Loading, Height}) => {
  const config = {
    data: Data,
    xField: 'date',
    theme: 'dark',
    yField: 'value',
    seriesField: 'category',
    loading: Loading,
    smooth: true,
    loadingTemplate: (<div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,.9)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Spin spinning={true}/>
    </div>),
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
            return ''.concat(s, ',');
          });
        },
      },

    },
    color: [
      '#59CD90',
      '#5887F6',
      '#EA526F',
      '#FFBABA',
      '#28C4BD',
      '#099CFC',
      '#CE66CA',
      '#5AD4FE',
      '#D75353',
      '#3F6BD3',
      '#FFB05D',
      '#FF5EC2',
      '#D75353',
    ],
    /* lineStyle: function lineStyle(_ref) {
      const alarmType = _ref.category;
      console.log(_ref);
      switch (alarmType) {
        case 'Waiting Number':
          return {stroke: '#59CD90'};
        case 'Waiting Time':
          return {stroke: '#5887F6'};
        case 'Falling':
          return {stroke: '#EA526F'};
        case 'Social Distancing':
          return {stroke: '#FFBABA'};
        case 'NOT Wearing Mask':
          return {fill: '#28C4BD'};
        case 'Person of Interest':
          return {fill: '#099CFC'};
        case 'Running':
          return {fill: '#CE66CA'};
        case 'Loitering':
          return {fill: '#5AD4FE'};
        case 'Vigorous Activity >= 2 person':
          return {fill: '#D75353'};
        case 'Overstayers':
          return {fill: '#3F6BD3'};
        case 'New Item Detected':
          return {fill: '#FFB05D'};
        case 'NOT Wearing Mask Properly':
          return {fill: '#FF5EC2'};
        default:
          return {fill: '#D75353'};
      }
    },*/
  };
  return <Line{...config} style={{height: Height}}/>;
};

LineCharts.propTypes = {
  Data: PropTypes.arrayOf(PropTypes.object).isRequired,
  Height: PropTypes.string.isRequired,
  Loading: PropTypes.bool.isRequired,
};

export default LineCharts;
