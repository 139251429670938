/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {httpLoading} from '@/store/actions';
import {storage} from '../../../utils/storage';
import {Button, Form, Input, InputNumber, message, Radio, Row, Spin, TimePicker, Col, Card, Tabs, Space, Popconfirm} from 'antd';
import {Config, configModuleId} from './config';
import moment from 'moment';
import {getAlarmSettingData, updateAlarmSetting, getEmailData, updateEmail, getInitMsgData,
  getAlgoData,
  deleteSingleAlgo,
  updateSingleAlgo,
  addNewSingleAlogo,
} from '../../../api';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';

const PAGETYPE = 'NLB';


const CustomForms = () => {
  const {moduleId} = useParams();
  const [listIndex, setIndex]=useState();
  const {t} = useTranslation();
  const [algoList, setAlgoList]=useState([]);
  const [loading, setLoading] = useState(false);
  const [msgCounts, setMsgCounts] = useState(null);
  const [configForm] = Form.useForm();
  const [algoForm] = Form.useForm();
  const [topForm]= Form.useForm();
  const AccessRole =storage.getData( 'userInfo')?.isSystem===1?'isSystemUser':'noSystemUser';
  const SystemType =storage.getData( 'userInfo')?.buildingType===1?'building':'map';
  // 添加表单项的数量
  const [formItemsClick, setFormItemsClick] = useState(false);
  // 设置头部的点击事件
  // const [topFormClick, setTopFormClick] = useState(false);
  const TabPane = Tabs.TabPane;
  const [tab, setTab]=useState('1');
  const msgTitle = {
    'systemStatus': t('SMS Gateway Status'),
    'sedMsmCount': t('SMS Available (current month)'),
    'sendSystemCount': t('System Status SMS Sent (current month)'),
    'sendAlarmCount': t('Alert SMS Sent (current month)'),
  };
  const timer = moduleId === configModuleId[0] || moduleId === configModuleId[1] ?
    Config[moduleId].filter((item) => item.type === 'timePicker') : [];

  const onDeleteSingleAlgo = (id)=>{
    setLoading(true);
    console.log(id, algoList);
    deleteSingleAlgo(algoList.othersTypeAlgorithm[id].id, t('flag')).then((res)=>{
      message.success(res.msg);
      setIndex('');
      getAlgoList();
    });
    setLoading(false);
  };

  const onEditSingleAlgo = (data, name)=>{
    // console.log(data);
    updateSingleAlgo({
      ...data,
    }, t('flag')).then((res)=>{
      console.log(res);
      message.success(res.msg);
      setIndex('');
      getAlgoList();
    }).catch((error) => {
      message.error(error);
    });
  };

  const updateConfig = (values) => {
    setLoading(true);
    if (timer.length > 0) {
      timer.forEach((item) => {
        values[item.name] = moment(values[item.name][0]).format('HH:mm:ss') + ',' +
          moment(values[item.name][1]).format('HH:mm:ss');
      });
    }
    if (moduleId === configModuleId[0]) {
      updateAlarmSetting(values, t('flag')).then((res) => {
        message.success(res.msg);
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => {
        setLoading(false);
      });
    } else if (moduleId === configModuleId[1]) {
      updateEmail(values, t('flag')).then((res) => {
        message.success(res.msg);
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  };
  // 新增动态算法子选项
  const addNewAlgo = (params)=>{
    console.log('触发了');
    addNewSingleAlogo({...params, paramType: 1}).then((res)=>{
      console.log(res);
      message.success(res.msg);
      setIndex('');
      setFormItemsClick(false);
      getAlgoList();
    }).catch((error) => {
      console.log(error, 'error');
      // message.error(error);
      // setFormItemsClick(false);
    });
  };
  const getConfig = () => {
    setLoading(true);
    if (moduleId === configModuleId[0]) {
      getAlarmSettingData(t('flag')).then((res) => {
        const values = res.data;
        timer.forEach((item) => {
          if (values[item.name]) {
            values[item.name] = [moment(values[item.name].split(',')[0], 'HH:mm:ss'),
              moment(values[item.name].split(',')[1], 'HH:mm:ss')] || null;
          }
        });
        configForm.setFieldsValue(values);
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => {
        setLoading(false);
      });
    } else if (moduleId === configModuleId[1]) {
      getEmailData(t('flag')).then((res) => {
        const values = res.data;

        timer.forEach((item) => {
          console.log(item.name, values[item.name]);
          if (values[item.name]) {
            values[item.name] = [moment(values[item.name].split(',')[0], 'HH:mm:ss'),
              moment(values[item.name].split(',')[1], 'HH:mm:ss')] || null;
          }
        });
        configForm.setFieldsValue(values);
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  };
  // 顶部表格编辑
  const onTopFormSave = (values) => {
    console.log(values, 'onTopFormSave values的值');
    onEditSingleAlgo({
      algorithmUrl: values.FaceAiRight,
      id: values.Id,
      description: values.description,
      algorithmName: values.FaceAiLeft,
      paramType: 2, // 代表顶部表格编辑
    });
  };
    // 普通表格编辑
  const onFinish = (values) => {
    console.log(values, 'values的值');
    // 没有子选项的情况
    if (!values.users.length) {
      onEditSingleAlgo({
        algorithmUrl: values.list[listIndex].algorithmUrl,
        id: values.list[listIndex].id,
        algorithmName: values.list[listIndex].algorithmName,
        description: values.list[listIndex].description,
        paramType: 1, // 代表普通表格编辑
      });
    } else {
      // 有子选项的情况
      if (values?.list?.map((item)=>item.algorithmName).includes(values?.users[0].algorithmName)) {
        message.error(t('DuplicateAlgorithmName'));
      } else {
        addNewAlgo(values.users[0]);
      }
    }
  };

  const getInitMsg = () => {
    getInitMsgData(t('flag')).then((res) => {
      setMsgCounts(res.data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    });
  };
  // 设置数据回显---算法表单
  const getAlgoList = ()=>{
    getAlgoData(t('flag')).then((res)=>{
      setAlgoList(res.data);
      const algo =res.data.othersTypeAlgorithm;
      algoForm.setFieldsValue({users: [], list: algo});
      // 设置顶部回显
      topForm.setFieldsValue({'FaceAiLeft': res.data.compareTypeAlgorithm[0].algorithmName});
      topForm.setFieldsValue({'FaceAiRight': res.data.compareTypeAlgorithm[0].algorithmUrl});
      topForm.setFieldsValue({'description': res.data.compareTypeAlgorithm[0].description});
      topForm.setFieldsValue({'Id': res.data.compareTypeAlgorithm[0].id});
    }).catch((error) => {
      if (error) {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }
    });
  };
  useEffect(() => {
    getConfig();
    getAlgoList();
    if (moduleId === '232' && PAGETYPE === 'NLB') {
      getInitMsg();
    }
  }, [moduleId]);

  return (
    <div className="settings ">
      <Spin spinning={loading}>
        <div className="bg-details padding16 bg-theme">
          {(moduleId === '232' && PAGETYPE === 'NLB') && (<Row gutter={[16, 16]}>
            {msgCounts && (Object.keys(msgCounts).map((key) => (
              <Col key={key} flex={1}>
                <Card bordered={false}>
                  {msgTitle[key]} : {key !== 'systemStatus' ? msgCounts[key] :
                    (msgCounts[key] === 0 ? t('abnormal') : t('running'))}
                </Card>
              </Col>
            )))}
          </Row>)}
          {moduleId === '232' &&(
            <Tabs tabPosition={'top'} onChange={(e)=>setTab(e)}>
              <TabPane tab="Camera" key="1"></TabPane>
              {AccessRole==='isSystemUser'?<TabPane tab="Algorithm" key="2"></TabPane>:<div></div>}
            </Tabs>)}
          <Form
            className="setting-form mt-30"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 12,
            }}
            form={configForm}
            onFinish={updateConfig}
          >
            {moduleId === configModuleId[0] || moduleId === configModuleId[1]&&
            tab==='1' ? Config[moduleId].map((item) => {
              switch (item.type) {
                case 'duration':
                  return (<Form.Item
                    name={item.name}
                    label={t(item.label)}
                    key={item.name}
                    rules={[{
                      message: `${t('pleaseInput')}${t(item.name)}!`,
                      required: item.require,
                    }]}>
                    <InputNumber min={item.min} max={item.max} addonAfter={t(item.unit)} style={{width: '50%'}} />
                  </Form.Item>);
                case 'number':
                  return (<Form.Item
                    name={item.name}
                    key={item.name}
                    label={t(item.label)}
                    rules={[{
                      required: item.require,
                      message: `${t('pleaseInput')}${t(item.name)}!`,
                    }]}>
                    <InputNumber min={item.min} step={item.step} max={item.max} style={{width: '50%'}} />
                  </Form.Item>);
                case 'primaryInput':
                  return (<Form.Item
                    name={item.name}
                    disabled={item.name}
                    key={item.name}
                    label={t(item.label)}
                    rules={[{
                      required: item.require,
                      message: `${t('pleaseInput')}${t(item.name)}!`,
                    }]}>
                    <Input style={{width: '50%'}} maxLength={100} autoComplete="off" />
                  </Form.Item>);
                case 'primaryInputHadPlaceHolder':
                  return (<Form.Item
                    name={item.name}
                    key={item.name}
                    label={t(item.label)}
                    rules={[{
                      required: item.require,
                      message: `${t('pleaseInput')}${t(item.name)}!`,
                    }]}>
                    <Input style={{width: '50%'}} maxLength={100} autoComplete="off" placeholder='@ExampleChatGroupID'/>
                  </Form.Item>);
                case 'primaryInputHadDisabled':
                  return (<Form.Item
                    name={item.name}
                    key={item.name}
                    label={t(item.label)}
                    rules={[{
                      required: item.require,
                      message: `${t('pleaseInput')}${t(item.name)}!`,
                    }]}>
                    <Input disabled={true} style={{width: '50%'}} maxLength={100} autoComplete="off" />
                  </Form.Item>);
                case 'primaryInputForAccess':
                  return (<Form.Item
                    name={item.name}
                    key={item.name}
                    hidden={SystemType==='building'}
                    label={t(item.label)}
                    rules={[{
                      required: SystemType==='building'?false:true,
                      message: `${t('pleaseInput')}${t(item.name)}!`,
                    }]}>
                    <Input style={{width: '50%'}} maxLength={100} autoComplete="off" />
                  </Form.Item>);
                case 'radio':
                  return (<Form.Item
                    name={item.name}
                    label={t(item.label)}
                    key={item.name}
                    rules={[{
                      required: item.require,
                      message: `${t('pleaseChose')}${t(item.name)}!`,
                    }]}>
                    <Radio.Group>
                      {item.value.map((subItem) => (
                        <Radio value={subItem.value} key={subItem.key}>{t(subItem.key)}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>);
                case 'timePicker':
                  return (<Form.Item
                    name={item.name}
                    label={t(item.label)}
                    key={item.name}
                    rules={[{
                      required: item.require,
                      message: `${t('pleaseSelect')}!`,
                    }]}>
                    <TimePicker.RangePicker />
                  </Form.Item>);

                default:
                  return null;
              }
            }) : null}

            {moduleId === configModuleId[0]&&tab!=='2' ||
             moduleId === configModuleId[1]&&tab!=='2' ? <Form.Item wrapperCol={{span: 24}}>
               <div className="text-center">
                 <Button className="transparent-btn margin-r-16" htmlType="reset">{t('clearBut')}</Button>
                 <Button className="full-btn" htmlType="submit">{t('saveBut')}</Button>
               </div>
             </Form.Item> : <></>}

          </Form>
          {tab==='2'&&moduleId === '232' &&(<div className="" style={{marginBottom: '250px'}}>
            {/* Configuration of Face Comparison Algorithm */}
            {/* 算法可编辑表单 */}
            <Form
              layout='vertical'
              style={{
                width: 1400,
                margin: '0 auto',
              }}
              autoComplete="off"
              form={topForm}
              onFinish={onTopFormSave}
            >
              <div style={{fontSize: '16px', marginBottom: '24px', marginTop: '0px', marginLeft: '-10px'}}>{t('aiTitle1')}</div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <Form.Item
                  label={t('AlgorithmName')}
                  name='FaceAiLeft'
                  rules={[
                    {
                      required: true,
                      message: 'Please input',
                    },
                  ]}
                >
                  <Input
                    style={{width: 400, marginRight: 16}}
                    disabled={listIndex!=='testKey'}
                    placeholder="Please Input Algorithm Name" />
                </Form.Item>
                <Form.Item
                  label={t('AlgorithmUrl')}
                  name="FaceAiRight"
                  rules={[
                    {
                      required: true,
                      message: 'Please input',
                    },
                    {
                      pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
                      message: t('urlTrue'),
                    },
                  ]}
                >
                  <Input style={{width: 400, marginRight: 16}}
                    disabled={listIndex!=='testKey'}
                    placeholder="Please Input Algorithm Url" />
                </Form.Item>
                {/* 算法描述 */}
                <Form.Item
                  label={t('description')}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please input',
                    },
                  ]}
                >
                  <Input style={{width: 400}}
                    disabled={listIndex!=='testKey'}
                    placeholder="Please Input Algorithm Description" />
                </Form.Item>

                <Form.Item
                  hidden={true}
                  label="Id"
                  name="Id"
                >
                  <Input />
                </Form.Item>
                <div className="btn-space">
                  {listIndex!=='testKey'? (<div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px',
                  }}><Button style={{marginLeft: '10px'}} disabled={formItemsClick} onClick={() => setIndex('testKey')}
                    >
                    Edit</Button></div>) :
                    (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                        }}>
                        <Button style={{marginLeft: '10px'}} type="default"
                          onClick={() => {
                            getAlgoList();
                            setIndex('');
                          }} >
                          Cancel
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form.Item>
                      </div>

                    )}
                </div>
              </div>
            </Form>
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              layout='vertical'
              style={{
                maxWidth: 1400,
                margin: '0 auto',

              }}
              autoComplete="off"
              form={algoForm}
            >
              <div style={{fontSize: '16px', marginBottom: '24px', marginTop: '10px', marginLeft: '-10px'}}>{t('aiTitle2')}</div>
              <Form.List name="list">
                {(fields) => (
                  <>
                    {fields.map(({key, name, ...restField}) => {
                      return (
                        <div key={key}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 10,
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              marginBottom: 2,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              label={t('AlgorithmName')}
                              name={[name, 'algorithmName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input',
                                },
                              ]}
                            >
                              <Input
                                style={{width: 400, marginRight: 16}}
                                disabled={listIndex !== key}
                                placeholder="Please Input Algorithm Name" />
                            </Form.Item>
                            <Form.Item
                              label={t('AlgorithmUrl')}

                              {...restField}
                              name={[name, 'algorithmUrl']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input',
                                },
                                {
                                  pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
                                  message: t('urlTrue'),
                                },
                              ]}
                            >
                              <Input style={{width: 400, marginRight: 16}}
                                disabled={listIndex !== key}
                                placeholder="Please Input Algorithm Url" />
                            </Form.Item>
                            <Form.Item
                              label={t('description')}
                              {...restField}
                              name={[name, 'description']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input',
                                },
                              ]}
                            >
                              <Input style={{width: 400}}
                                disabled={listIndex !== key}
                                placeholder="Please Input Algorithm Description" />
                            </Form.Item>

                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </div>
                          <div className="btn-space">
                            {listIndex !== key ? (<Button disabled={formItemsClick} onClick={() => setIndex(key)}
                            >
                              Edit</Button>) :
                              (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 10,
                                  }}>
                                  <Popconfirm
                                    title={t('cancelConfirm')}
                                    onConfirm={() => {
                                      setIndex('');
                                      getAlgoList();
                                    }}
                                  >
                                    <Button type="default"
                                      onClick={() => {
                                      }} >
                                      Cancel
                                    </Button>
                                  </Popconfirm>
                                  <Popconfirm
                                    title={t('deleteConfirm')}
                                    onConfirm={()=>{
                                      onDeleteSingleAlgo(name);
                                    }}
                                  >
                                    <Button danger type="primary">
                                      Delete
                                    </Button>
                                  </Popconfirm>
                                  <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                      Submit
                                    </Button>
                                  </Form.Item>
                                </div>

                              )}
                          </div>
                        </div>

                      );
                    },
                    )}
                  </>
                )}
              </Form.List>
              <Form.List name="users">
                {(fields, {add, remove}) => (
                  <>
                    {fields.map(({key, name, index, ...restField}) => {
                      console.log(fields, 'fields', key, 'key', index, 'index', name, 'name', restField, 'restField');
                      // 控制算法不能选中
                      // if (fields.length>0) {
                      //   setFormItemsClick(true);
                      // }
                      return (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'algorithmName']}
                            label={t('addAlgorithmName')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input',

                              },
                            ]}
                          >
                            <Input placeholder="Please Input New Algorithm Name"
                              style={{width: 400, marginRight: 10}}

                              // disabled={listIndex!==index}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={t('addAlgorithmUrl')}

                            name={[name, 'algorithmUrl']}
                            rules={[
                              {
                                required: true,
                                message: 'Please input',

                              },
                              {
                                pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
                                message: t('urlTrue'),
                              },
                            ]}
                          >
                            <Input placeholder="Please Input New Algorithm Url"
                              style={{width: 400, marginRight: 10}}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={t('addDescription')}

                            name={[name, 'description']}
                            rules={[
                              {
                                required: true,
                                message: 'Please input',

                              },
                            ]}
                          >
                            <Input placeholder="Please Input New Algorithm Description"
                              style={{width: 400}}
                            />
                          </Form.Item>
                          <Form.Item label=' '>
                            <Button danger onClick={() => {
                              remove(name);
                              setFormItemsClick(false);
                            }}>
                              {t('delete')}
                            </Button>
                          </Form.Item>
                          <Form.Item label=' '>
                            <Button type="primary" htmlType="submit" >
                              {t('save')}
                            </Button>
                          </Form.Item>
                        </Space>
                      );
                    },
                    )}
                    <Form.Item style={{marginBottom: '30px'}}>
                      <Button type="dashed" disabled={formItemsClick} onClick={() => {
                        setIndex('');
                        add();
                        setFormItemsClick(true);
                      }} style={{width: 1300}} block icon={<PlusOutlined />}>
                        {t('Add field')}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>

          </div>)}

        </div>

      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    httpLoading: state.toggleHttpLoading.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHttpLoading: (f) => dispatch(httpLoading(f)),
  };
};

CustomForms.propTypes = {
  httpLoading: PropTypes.bool.isRequired,
  setHttpLoading: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomForms);
