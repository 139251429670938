/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react';
import {Alert} from 'antd';
import Marquee from 'react-fast-marquee';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SoundOutlined, RightOutlined, HomeOutlined} from '@ant-design/icons';
import {Col, Layout, Row, Space, Badge, Modal, Divider, Button, Tooltip} from 'antd';
import smallLogo from '../../assets/small-logo.png';
import {login} from '../../store/actions';
import './style.css';
import {useTranslation} from 'react-i18next';
import {storage} from '../../utils/storage';
import CustomMenus from './sider/CustomMenus';
import marqueeNotice from '../marqueeNotice';
// import CustomRouters from '../routers';
import {Outlet, Link, useNavigate, useParams} from 'react-router-dom';
import profilePhoto from '../../assets/header/profilePhoto.png';
import alerts from '../../assets/header/alerts.png';
import {getAlarmTypeNameListData, getAlarmsMsgCountsData, getNotice} from '../../api';

const {Header, Content, Sider} = Layout;

const CustomLayout = ({setLogged, logged}) => {
  const locationParams = useParams();
  const timer = useRef();
  const ng = useNavigate();
  const {t} = useTranslation();
  const [notice, setNotice] = useState();
  const [offset, setOffset] = useState(0);
  // const handleAnimationEnd = () => {
  //   setOffset(0);
  // };
  const [collapsed, setCollapsed] = useState(false);
  const [modalVisible, setModalVisible] = useState('');
  const [sID, setSID]=useState(locationParams.moduleId||null);
  const [pID, setPID] = useState(locationParams.key);
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [unreadMsg, setUnreadMsg]= useState(0);
  const [unprocessedMsg, setUnprocessedMsg]= useState(false);
  const headerTitle = ()=>{
    const menuTree =storage.getData( 'userInfo')?.menuTree;
    const pItem=menuTree.children.find((item)=>item.id===pID);
    if (sID) {
      const menu=pItem.children.find((item)=>item.id===sID);
      return t('flag')==='1'?menu.text:menu.cnName;
    }
    return pItem?(t('flag')==='1'?pItem.text:pItem.cnName):'';
  };


  const getAlarmsMsgCounts=()=>{
    getAlarmsMsgCountsData(t('flag')).then((res)=>{
      setUnreadMsg(res.data.alarmCount>0?(res.data.alarmCount<=99?res.data.alarmCount:99):0);
      setUnprocessedMsg(res.data.isExistUnProcessAlarm>0&&res.data.alarmCount===0);
    }).catch();
  };
  const handleLogoutButton = () => {
    setLogged(false);
    // storage.clearData('session', 'userInfo');
    storage.clearData('local', 'alarmType');
    storage.clearData('local', 'userInfo');
    window.location.href= window.location.origin+'/login';
  };
  const licenseExpiration = (
    <>
      {/* <Divider style={{marginTop: 0}}></Divider> */}
      <div className='license-expiration'>
        {t('expirationInfo')}
        <div style={{margin: '24px 0'}}>
          {
            notice
          }
        </div>
        <div style={{textAlign: 'right'}}>
          <Button type='primary' onClick={() => {
            ng('/console/licenseOffline/217/253');
            setModalVisible(false);
            storage.clearData('local', 'modalTip');
          }}>
            {t('viewDetail')}
          </Button>
          <Button style={{marginLeft: '10px'}} onClick={() => {
            setModalVisible(false);
            storage.clearData('local', 'modalTip');
          }}>
            {t('i got it')}
          </Button>
        </div>
      </div>
    </>
  );
  useEffect(() => {
    // getAlarmsMsgCounts();
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      // getAlarmsMsgCounts();
    }, 30000);
    getNotice(t('flag')).then((res) => {
      // console.log(res, 'getNotice信息11111111111111111');
      setNotice(res.data);
      setModalVisible(localStorage.getItem('modalTip') && (res.data));
    });
  }, []);
  useEffect(()=>{
    getAlarmsMsgCounts();
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      getAlarmsMsgCounts();
    }, 30000);
  }, []);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setOffset((offset) => offset + 1);
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);
  useEffect(()=>{
    setSID(locationParams.moduleId||null);
    setPID(locationParams.key);
  }, [locationParams]);
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider style={{
        zIndex: '999',
      }} breakpoint="xl" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        {!collapsed&&<div className="logo" style={{
          zIndex: '1999',
          position: 'fixed',
          left: 40,
          top: 0,
        }}>
          <img src={smallLogo} alt="logo" />
        </div>}
        {collapsed&&<div className="logo" style={{
          zIndex: '1999',
          position: 'fixed',
          left: 14,
          top: 20,
          width: '40px',
          fontWeight: '600',
          fontSize: '20px',
        }}>
          AIGEL
        </div>}
        <CustomMenus collapsed={collapsed} />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
        >
          <Row className="theme-header">
            <Col span={6} />
            <Col span={18}>
              <Row>
                <Col flex="auto" className="text-right padding-r-16" >
                  <Space size="large">
                    {notice&&<div className='marquee-container'>
                      <SoundOutlined style={{fontSize: '20px', marginLeft: '10px'}}/>
                      <Tooltip placement="bottomLeft" title={notice}><div className='marquee-container-item'>
                        <Marquee className="marquee" gradient={false} pauseOnHover>
                          {notice}
                        </Marquee>
                      </div></Tooltip>
                      <div className='arrow-container'><RightOutlined onClick={() => {
                        ng('/console/licenseOffline/217/253');
                      }} className="RightOutlined" style={{fontSize: '20px', marginRight: '4px'}}/></div>
                    </div>}
                    <span className="text-white ">
                      <img src={profilePhoto} alt="profilePhoto" />
                      {userInfo ? userInfo.userName : 'Admin'}
                    </span>
                    <div className="wave-box">
                      {(unreadMsg > 0 && unreadMsg < 10) && (<div className="wave-content">
                        <div className="wave wave1 " />
                      </div>)}
                      {(unreadMsg > 10) && (<div className="wave-content-lg">
                        <div className="wave wave1 " />
                      </div>)}
                      <Badge
                        count={unreadMsg > 0 ? (<b className="dots">+{unreadMsg}</b>) : 0}
                        dot={unprocessedMsg}
                        offset={unreadMsg > 0 ? [4, -2] : ''}
                        overflowCount={99999}
                      >
                        <Link to="/console/alerts/215">
                          <img src={alerts} alt="alters" />
                        </Link>
                      </Badge>
                    </div>

                  </Space>
                </Col>
                <Col flex="200px" className="text-right padding-r-16">
                  <Space size="large">
                    <a className="icon-font text-white " onClick={()=>{
                      window.location.href= window.location.origin+'/home';
                    }}><HomeOutlined /></a>
                    <a className="icon-font text-white " onClick={handleLogoutButton}>&#xe62c;</a>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>   <h1 className="page-title">{headerTitle()}</h1></Col>
          </Row>
        </Header>
        <Content style={{margin: '0 16px'}}>
          <Outlet />
          <Modal
            title={t('Notice')}
            visible={modalVisible}
            onCancel={() => {
              setModalVisible(false);
              storage.clearData('local', 'modalTip');
            }}
            className='role-add-modal'
            width={694}
            footer={null}
            maskClosable={false}
            destroyOnClose>
            {licenseExpiration}
          </Modal>
        </Content>
      </Layout>
    </Layout >
  );
};

const mapStateToProps = (state) => {
  return {
    logged: state.toggleLogin.logged,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setLogged: (f) => dispatch(login(f)),
  };
};


CustomLayout.propTypes = {
  logged: PropTypes.bool.isRequired,
  setLogged: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomLayout);

