import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Space, Popconfirm, Modal, Form, Input, Button, Select, message, Spin, Tag} from 'antd';
import {getRolesListData, deleteUser, saveUser, updateUser, resetPwd, getBuildingIdOption} from '../../../api';
import {httpLoading} from '@/store/actions';
import Base64 from 'base-64';
import './style.css';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../components/customTable';
import {GETUSERLIST} from '../../../api/Urls';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 18},
};

const Users = ({setHttpLoading, httpLoading}) => {
  const {t} = useTranslation();
  const [updateList, setUpdateList] = useState(0);
  const [buildingIdOption, setBuildingIdOption] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [form] = Form.useForm();

  const [roleData, setRoleData] = useState([]);
  const passwordForm = (<Form
    {...layout}
    name='settingForm'
    className='setting-form'
    onFinish={(values) => handleResetPassword(values)}
    form={form}>
    <Form.Item
      name='userId'
      label={t('userId')}
      hidden={modalType === 'add'}
      shouldUpdate
    >
      <Input autoComplete='off' style={{width: 257}} maxLength={12} disabled />
    </Form.Item>
    <Form.Item
      name='password'
      label={t('password')}
      rules={[
        {
          required: true,
          message: t('flag')==='1'?'Please input new password!':'请输入新密码！',
        },
      ]}
      shouldUpdate
    >
      <Input.Password autoComplete='off' style={{width: 257}} />
    </Form.Item>
    <Form.Item wrapperCol={{offset: 6}}>
      <Button className='full-btn marginR16' htmlType='submit'>
        {t('submit')}
      </Button>
    </Form.Item>
  </Form>);
  const editForm = ( <Form
    {...layout}
    name='settingForm'
    className='setting-form'
    onFinish={(values) => {
      // handleUser(modalType, values);
      handleUser(modalType, form.getFieldsValue());
    }}
    form={form}>
    <Form.Item
      name='userId'
      label={t('userId')}
      hidden
      shouldUpdate
    >
      <Input autoComplete='off' style={{width: 257}} maxLength={12} disabled />
    </Form.Item>
    <Form.Item
      name='username'
      label={t('userName')}
      rules={[
        {
          required: modalType === 'add',
          message: t('flag')==='1'?'Please input user name!':'请输入用户名！',
        },
      ]}
      hidden={modalType === 'edit'}
      shouldUpdate
    >
      <Input autoComplete='off' style={{width: 257}} maxLength={30} />
    </Form.Item>
    <Form.Item
      name='name'
      label={t('nickName')}
      rules={[
        {
          required: true,
          message: t('flag')==='1'?'Please input nick name!':'请输入昵称！',
        },
      ]}
      shouldUpdate
    >
      <Input autoComplete='off' style={{width: 257}} maxLength={30} />
    </Form.Item>
    <Form.Item
      name='password'
      label={t('password')}
      hidden={modalType==='edit'}
      rules={[
        {
          required: true,
          message: t('flag')==='1'?'Please input password!':'请输入密码！',
        },
      ]}
      shouldUpdate
    >
      <Input.Password autoComplete='off' style={{width: 257}} />
    </Form.Item>
    <Form.Item name='roleId' label={t('role')} shouldUpdate
      rules={[{
        required: true,
        message: t('flag')==='1'?'Please select roles!':'请选择角色！',
      }]}>
      <Select style={{width: 257}} options={roleData}/>
    </Form.Item>
    <Form.Item name='buildingId' label={t('Building')} shouldUpdate
      rules={[{
        required: true,
        message: t('flag')==='1'?'Please select building!':'请选择楼宇！',
      }]}>
      <Select style={{width: 257}} mode="multiple" options={buildingIdOption}/>
    </Form.Item>
    <Form.Item wrapperCol={{offset: 6}}>
      <Button
        className='full-btn marginR16'
        onClick=
          {()=> handleUser(modalType, form.getFieldsValue())}

      >
        {t('submit')}
      </Button>
    </Form.Item>
  </Form>);

  const getRoles = () => {
    // setHttpLoading(true);
    getRolesListData({pageSize: 999, pageNum: 1}, t('flag'))
        .then((res) => {
          setRoleData(res.data.items.map((item)=>({
            label: item.roleName,
            value: item.roleId,
          })));
          // setHttpLoading(false);
        })
        .catch((error) => {
          message.error({
            content: error.toString(),
            key: 'netError',
            duration: 2,
          });
        });
    // .finally(() => setHttpLoading(false));
  };
  const handleUser = (type, values) => {
    setHttpLoading(true);
    console.log(type, values);
    const data = {...values};
    if (type==='add') {
      delete data.confirm;
      delete data.userId;
      data.password = Base64.encode(`${values.password}_hcyNLB@`);
      saveUser(data, t('flag'))
          .then((res) => {
            setUpdateList(updateList+1);
            // setHttpLoading(false);
            message.success(res.msg);
            setModalVisible(false);
            form.resetFields();
          })
          .catch((error) => {
            message.error({
              content: error.toString(),
              key: 'netError',
              duration: 2,
            });
          })
          .finally(() => setHttpLoading(false));
    } else {
      delete data.confirm;
      delete data.password;
      updateUser(data, t('flag'))
          .then((res) => {
            setUpdateList(updateList+1);
            // setHttpLoading(false);
            message.success(res.msg);
            form.resetFields();
            setModalVisible(false);
          })
          .catch((error) => {
            message.error({
              content: error.toString(),
              key: 'netError',
              duration: 2,
            });
            setModalVisible(false);
          })
          .finally(() => setHttpLoading(false));
    }
  };
  const handleDelete = (id)=>{
    setHttpLoading(true);
    deleteUser(id, t('flag')).then((res)=>{
      message.success(res.msg);
      setModalVisible(false);
      setUpdateList(updateList+1);
    }) .catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
      setHttpLoading(false);
    })
        .finally(() => setHttpLoading(false));
  };
  const handleResetPassword = (values)=>{
    setHttpLoading(true);
    const data = {...values};
    data.password = Base64.encode(`${values.password}_hcyNLB@`);
    resetPwd(data, t('flag'))
        .then((res) => {
          setUpdateList(updateList+1);
          message.success(res.msg);
          // setHttpLoading(false);
          setModalVisible(false);
        })
        .catch((error) => {
          message.error({
            content: error.toString(),
            key: 'netError',
            duration: 2,
          });
          setHttpLoading(false);
        })
        .finally(() => setHttpLoading(false));
  };
  const columns=[
    {
      title: t('id'),
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: t('userName'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('nickName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('role'),
      dataIndex: 'roleName',
      key: 'roleName',
    },
    {
      title: t('Building'),
      dataIndex: 'buildingId',
      key: 'buildingId',
      width: 300,
      // onExport: true,
      render: (buildingId) => buildingId?.split(',').map((item) => (
        <Tag key={'buildingId' + item}>
          {buildingTypeFucData(item)}
        </Tag>
      )),
      exportRender: (buildingId) => buildingId.buildingId ?
      buildingId.buildingId.split(',').map((item) => buildingTypeFucData(item)).join(',') : '',
    },
    {
      title: t('action'),
      key: 'action',
      /*eslint-disable*/
      render: (record) => (
        <Space size='middle'>
          <a
            onClick={() => {
              console.log(record.buildingId?.split(','), 'record.buildingId.split(',')');
              const num=record.buildingId?.split(',').map((item)=>parseInt(item))
              form.setFieldsValue({...record, buildingId:num});
              setModalType('edit');
              setModalVisible(true);
            }}>
            {t('edit')}
          </a>
          <a
            onClick={() => {
              form.setFieldsValue({...record, buildingId:record.buildingId.split(',').map((item)=>item)});
              setModalType('reset');
              setModalVisible(true);
            }}>
            {t('resetPassword')}
          </a>
          <Popconfirm
            title={t('flag')==='1'?"Are you sure to delete this user?":"是否删除此用户？"}
            onConfirm={()=>handleDelete(record.userId)}
            okText={t('yes')}
            cancelText={t('no')}
            color="#102864"
          >
            <a>{t('delete')}</a>
          </Popconfirm>
        </Space>
      ),
      /*eslint-disable*/
    },
  ];
  const buildingTypeFucData = (typeCode) => {
    const alarm=buildingIdOption.find((item)=>parseInt(item.value)===parseInt(typeCode));
    if (alarm) {
      return alarm.label;
    } else {
      return '';
    }
  };
  useEffect(() => {

    getBuildingIdOption(t('flag'))
    .then((res) => {
      setBuildingIdOption(res.data.map((item)=>({
        label: item.buildingName,
        value: item.id,
      })));
    })
    .catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
    getRoles();
  }, []);
  return (
    <div>
      <Spin spinning={httpLoading}>
        <CustomTable
          InitApi={GETUSERLIST}
          updateList={updateList}
          columns={columns}
          addFuc={()=>{
            setModalVisible(true);
            form.resetFields();
            setModalType('add');
          }}
          hiddenExport={true}
        />

      </Spin>
      <Modal
        title={t(modalType)}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        width={482}
        footer={null}
        className={`user-${modalType}-modal`}
        destroyOnClose>
        <div>
          {modalType==='reset'?passwordForm:editForm}
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    httpLoading: state.toggleHttpLoading.loading,
    userInfo: state.getUserInfo.info,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setHttpLoading: (f)=>dispatch(httpLoading(f)),
  };
};

Users.propTypes = {
  httpLoading: PropTypes.bool.isRequired,
  setHttpLoading: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Users);
