import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {getMonitorDetailsData, getMonitorDetailsListData} from '../../../api';
import {useTranslation} from 'react-i18next';
import {Button, Col, message, Row, Spin, Statistic, List, Tag} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
// import {Player} from 'video-react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import online from '../../../assets/monitoring/online_blue.png';
import {alarmTypeFuc} from '../../../components/baseTypeSelect';
import {
  // ETTodayValue,
  // STTodayValue,
  timeFormat} from '../../../components/baseTime';
import {Link, useNavigate} from 'react-router-dom';
import imgError from '../../../assets/imgError.png';

const MonitoringDetails = ({CID, goBack}) => {
  const {t} = useTranslation();
  const ng = useNavigate();
  const [details, setDetails] = useState({});
  const [alertList, setAlertList] =useState({});
  const [loading, setLoading] = useState(true);
  const [clock, setClock] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const timer = useRef();
  const getMonitorDetails = () => {
    setLoading(true);
    getMonitorDetailsData(CID, t('flag')).then((res) => {
      setDetails(res.data);
    }).catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => {
      setLoading(false);
    });
  };
  const getMonitorDetailsList = ()=>{
    const pageSize=parseInt((document.body.clientHeight - 385)/116);
    getMonitorDetailsListData(CID, pageSize>5?pageSize:5, t('flag')).then((res)=>{
      setAlertList(res.data);
    }).catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  useEffect(() => {
    clearInterval(timer);
    getMonitorDetails();
    getMonitorDetailsList();
    setInterval(() => {
      setClock(moment().format('YYYY-MM-DD HH:mm:ss'));
    }, 1000);
  }, []);
  return (
    <div className="">
      <Button type="text" className="back" onClick={() => {
        goBack(null);
        clearInterval(timer);
      }}><LeftOutlined/> {t('back')}</Button>
      <Row gutter={[16, 24]}>
        <Col span={16}>
          <Spin spinning={loading}>
            <div className="monitoring-bg">
              <div className="monitor-detail-title">
                <img src={online} alt="online"/> {clock}
              </div>
              <div className="monitor-detail-info">
                <div className="details-info-item">
                  <Statistic valueStyle={{fontSize: 16}} title={t('cameraName')}
                    value={details.cameraName}/>
                </div>
                <div className="details-info-item border-left">
                  <Statistic
                    title={t('status')}
                    valueStyle={{fontSize: 16}}
                    value={details.cameraStatus===1?t('online'):t('offline')}/>
                </div>
                <div className="details-info-item border-left">
                  <Statistic valueStyle={{fontSize: 16}} title={t('buildingName')} value={details.buildingName}/>
                </div>
                <div className="details-info-item border-left">
                  <Statistic valueStyle={{fontSize: 16}} title={t('levelName')} value={details.levelName}/>
                </div>
                <div className="details-info-item border-left">
                  <Statistic valueStyle={{fontSize: 16}} title={t('areaName')} value={details.areaName}/>
                </div>
              </div>
              <div className="monitor-detail-video">
                {/* <Player fluid={false} width={'100%'} height={580} playing>
                  <source src={details.rtspUrl} type="rtmp/flv"/>
                </Player> */}
                <ReactPlayer width={'100%'} height={593} url={details.videoStreamAddress} playing />
              </div>
            </div>
          </Spin>
        </Col>
        <Col span={8} className="alarm-list">
          <div className="monitoring-bg">
            <Row className="monitor-detail-title">
              <Col flex="auto">{t('alertTitleToday')}</Col>
              <Col flex="120px" className="text-right">
                <Link
                  to={`/console/alerts/215/?cameraId=${CID}&startTime=${moment(moment().format('YYYY-MM-DD 00:00:00')).valueOf()}
                  &endTime=${moment().valueOf()}`}
                >
                  {t('more')} <RightOutlined />
                </Link>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="monitor-list-info">
                  <div className="info-card-title">{t('overall')}</div>
                  <div className="info-card-num">
                    {alertList.overAll}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className="monitor-list-info">
                  <div className="info-card-title">{t('openTicket')}</div>
                  <div className="info-card-num">
                    {alertList.openTicket}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className="monitor-list-info">
                  <div className="info-card-title">{t('closeTicket')}</div>
                  <div className="info-card-num">
                    {alertList.closeTicket}
                  </div>
                </div>
              </Col>
            </Row>
            <List
              dataSource={alertList.alarmList}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  onClick={()=>{
                    ng('/console/alerts/215/?id='+item.id);
                  }}
                  extra={
                    <img
                      width={178}
                      height={100}
                      alt="logo"
                      fallback={imgError}
                      src={item.recordImage}
                    />
                  }
                >
                  <div>
                    <div>
                      <span className="alarm-list-title">{t('types')}</span>
                      <span className="alarm-list-info">{alarmTypeFuc(item.alarmType)}</span>
                    </div>
                    <div>
                      <span className="alarm-list-title">{t('time')}</span>
                      <span className="alarm-list-info">{timeFormat(item.alarmTime)}</span>
                    </div>
                    <div>
                      <span className="alarm-list-title">{t('status')}</span>
                      <span className="alarm-list-info">
                        {item.alarmStatus===0?( <Tag color="error" >{t('openTicket')}</Tag>):(
                        <Tag color="success" >{t('closeTicket')}</Tag>)}
                      </span>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>

    </div>
  );
};

MonitoringDetails.propTypes = {
  CID: PropTypes.number.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default MonitoringDetails;
