import React, {useState, useEffect} from 'react';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import {Spin, Upload, Form, message, Space} from 'antd';
import moment from 'moment';
// import ImgCrop from 'antd-img-crop';
import {PlusOutlined} from '@ant-design/icons';
import {imageUpload, faceCompare} from '../../../src/api';
const FaceRecognition = () => {
  // 表单相关
  const [form] = Form.useForm();
  const {t}=useTranslation();
  const [imageStyleTab, setImageStyleTab]=useState(1);
  // 小图切换
  const [smallImageTabA, setSmallImageTabA]=useState(0);
  const [smallImageTabB, setSmallImageTabB]=useState(0);
  const [tableLoading, setTableLoading]=useState(false);
  const [fileCompareFile, setFileCompareFile]=useState({
    image1: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic1A.png',
    image2: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic1B.png',
  });
  const [fileUrlA, setFileUrlA]=useState();
  const [fileUrlB, setFileUrlB]=useState();
  const [similarity, setSimilarity]=useState();
  const [similarityDescription, setSimilarityDescription]=useState();
  const clickStyle={border: '5px solid #0166FF', borderRadius: '6px'};
  const normalStyle={border: '1px solid rgba(255, 255, 255, 0.65)', borderRadius: '6px'};
  const clickStyleSmallImage='2px solid rgb(23, 125, 220)';
  const normalStyleSmallImage='1px solid #434343';
  const staticUrlA=[
    {name: 'demo1', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic1A.png'},
    {name: 'demo2', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic2A.png'},
    {name: 'demo3', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic3A.png'},
    {name: 'demo4', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic4A.png'},
    {name: 'demo5', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic5A.png'},
    {name: 'demo6', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic6A.png'},
    {name: 'demo7', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example//demo-pic7A.png'},
  ];
  const staticUrlB=[
    {name: 'demo1', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic1B.png'},
    {name: 'demo2', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic2B.png'},
    {name: 'demo3', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic3B.png'},
    {name: 'demo4', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic4B.png'},
    {name: 'demo5', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic5B.png'},
    {name: 'demo6', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic6B.png'},
    {name: 'demo7', url: 'http://172.16.10.240:9666/resource/face_register/material/face_example/demo-pic7B.png'},
  ];
  const beforeUploadImage = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5; // 限制为5MB以下
    if (!isLt5M) {
      message.error(t('isLt5M'));
      return;
    }
    console.log(file.name, 'fileNAme');
    // 重命名文件
    const newFileName = `${moment().valueOf()}-${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '')}.jpg`;
    const newFile = new File([file], newFileName, {type: file.type});
    return Promise.resolve(newFile);
    // return isLt5M;
  };
  const demoImageOne = (
    staticUrlA.map((item, index)=>{
      return <div key={index} onClick={() => {
        setSmallImageTabA(index);
        setFileCompareFile({...fileCompareFile, image1: item.url});
      }} className='image-text-container' style={{
        border: smallImageTabA === index ? clickStyleSmallImage : normalStyleSmallImage}}>
        <div className='image-items-text-button' style={{backgroundColor: smallImageTabA === index?'#0166FF':'#141414'}}>
        Sample{' 0'}{index+1}[A]</div>
        <div className='image-container-out-div'
          style={{backgroundImage: `url(${item.url})`}}>
        </div>
      </div>;
    })
  );
  const demoImageTwo = (
    staticUrlB.map((item, index)=>{
      return <div key={index} onClick={() => {
        setSmallImageTabB(index);
        setFileCompareFile({...fileCompareFile, image2: item.url});
      }} className='image-text-container' style={{
        border: smallImageTabB === index ? clickStyleSmallImage : normalStyleSmallImage}}>
        <div className='image-items-text-button' style={{backgroundColor: smallImageTabB === index ? '#0166FF' : '#141414'}}>
          Sample{' 0'}{index + 1}[B]</div>
        <div className='image-container-out-div'
          style={{backgroundImage: `url(${item.url})`}}>
        </div>
      </div>;
    })
  );
  const uploadButton = (
    <div className='uploadButton'>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('UploadImg')}{imageStyleTab===1?'A':'B'}
      </div>
    </div>
  );
  const uploadImageA = (
    <div onClick={() => {
      setImageStyleTab(1);
      setSmallImageTabA(8);
      if (fileUrlA) {
        setFileCompareFile({...fileCompareFile, image1: fileUrlA});
      }
    }} className='image-text-container image-container-upload' style={{
      border: smallImageTabA === 8 ? clickStyleSmallImage : normalStyleSmallImage}}>
      <div className='image-items-text'>
        点击更换图片</div>
      <div className='image-items-text-button' style={{backgroundColor: smallImageTabA === 8?'#0166FF':'#141414'}}>Sample{' 0'}{8}[A]</div>
      <div className='image-container-out-div'
        style={{backgroundImage: `url(${fileUrlA})`}}>
      </div>
    </div>
  );
  const uploadImageB = (
    <div onClick={() => {
      setImageStyleTab(2);
      setSmallImageTabB(8);
      if (fileUrlB) {
        setFileCompareFile({...fileCompareFile, image2: fileUrlB});
      }
    }} className='image-text-container image-container-upload' style={{
      border: smallImageTabB === 8 ? clickStyleSmallImage : normalStyleSmallImage}}>
      <div className='image-items-text'>
        点击更换图片</div>
      <div className='image-items-text-button' style={{backgroundColor: smallImageTabB === 8?'#0166FF':'#141414'}}>Sample{' 0'}{8}[B]</div>
      <div className='image-container-out-div'
        style={{backgroundImage: `url(${fileUrlB})`}}>
      </div>
    </div>
  );
  // 文件列表
  const [fileListA, setFileListA] = useState([
  ]);
  const [fileListB, setFileListB] = useState([
  ]);
  const propsA = {
    showUploadList: false,
    multiple: false,
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      imageUpload(formData, t('flag')).then((res)=>{
        console.log(res, 'res上传图片返回地址');
        setFileUrlA(res.data);
        message.success(res.msg);
        setSmallImageTabA(8);
        setFileCompareFile({...fileCompareFile, image1: res.data});
      }).catch((error)=>{
        message.error(error);
      });
    },
    onChange(info) {
      setFileListA(info.fileList);
    },
  };
  const propsB = {
    showUploadList: false,
    multiple: false,
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      imageUpload(formData, t('flag')).then((res)=>{
        setFileUrlB(res.data);
        message.success(res.msg);
        setSmallImageTabB(8);
        setFileCompareFile({...fileCompareFile, image2: res.data});
      }).catch((error)=>{
        message.error(error);
      });
    },
    onChange(info) {
      setFileListB(info.fileList);
    },
  };
  const handleChangeA = ({fileList: newFileList}) => {
    setFileListB(newFileList);
  };
  const handleChangeB = ({fileList: newFileList}) => {
    setFileListB(newFileList);
  };
  useEffect(() => {
    console.log('对比文件变更');
    if (fileCompareFile.image1 && fileCompareFile.image2) {
      setSimilarity();
      setSimilarityDescription();
      setTableLoading(true);
      faceCompare(fileCompareFile, t('flag')).then((res) => {
        console.log(res, '人脸对比');
        setTimeout(()=>{
          setTableLoading(false);
          // 获取对比数据
          setSimilarity(res.data.similarity);
          setSimilarityDescription(res.data.describe);
        }, 2000);
      }).catch((error)=>{
        setTableLoading(false);
        setSimilarity();
        setSimilarityDescription();
        message.error(error);
      },
      );
    }
  }, [fileCompareFile]);
  return (
    <div className='page-container'>
      <div className='width-half width-half-compare'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          gap={16}
        >
          <div
            className='basic-body basic-body-compare-top'
          >
            <div className="basic-title">
              {t('AnalysisTaskCreation')}<span style={{fontSize: '12px', color: 'grey', marginLeft: '5px'}}> {t('ChooseImageCompare')}
              </span>
            </div>
            <div className='image-container-a'>
              <div onClick={() => {
                setImageStyleTab(1);
              }} className='image-container-solo-image' style={imageStyleTab === 1 ? clickStyle : normalStyle} >
                <div className='image-container-out-div'
                  style={{backgroundImage: `url(${smallImageTabA===8?fileUrlA:staticUrlA[smallImageTabA].url})`}}>
                </div>
              </div>
              <div onClick={() => {
                setImageStyleTab(2);
              }} className='image-container-solo-image' style={imageStyleTab === 2 ? clickStyle : normalStyle}>
                <div className='image-container-out-div'
                  style={{backgroundImage: `url(${smallImageTabB===8?fileUrlB:staticUrlB[smallImageTabB].url})`}}>
                </div>
              </div>
            </div>
          </div>
          <Form
            form={form}
            name="basic"
            layout="horizontal"
          >
            <div
              className='basic-body basic-body-compare-bottom'
            >
              <div className="basic-title">
                {t('Algorithm')}<span style={{fontSize: '12px', color: 'grey', marginLeft: '5px'}}> {t('Faces Comparison')}
                </span>
              </div>
              <div className="basic-title">
                {t('Analysis object')}{imageStyleTab !== 1 ? 'B' : 'A'}
                <span style={{fontSize: '12px', color: 'grey', marginLeft: '5px'}}> {t('ChooseRule')}
                </span>
              </div>

              <Form.Item name="imageFileA" rules={[{required: true, message: 'Please Upload!'}]} hidden={imageStyleTab===2}>
                <Space>
                  {demoImageOne}
                  <Upload
                    onChange={handleChangeA}
                    beforeUpload={beforeUploadImage}
                    listType="picture-card"
                    fileList={fileListA}
                    {...propsA}
                    accept=".pdf,.jpg,.jpeg,.png"
                  >
                    {fileListA.length >= 1 ? uploadImageA : uploadButton}
                  </Upload>
                </Space>
                {/* </ImgCrop> */}
              </Form.Item>
              <Form.Item name="imageFileB" rules={[{required: true, message: 'Please Upload!'}]} hidden={imageStyleTab===1}>
                <Space>
                  {demoImageTwo}
                  <Upload
                    onChange={handleChangeB}
                    beforeUpload={beforeUploadImage}
                    listType="picture-card"
                    fileList={fileListB}
                    {...propsB}
                    accept=".pdf,.jpg,.jpeg,.png"
                  >
                    {fileListB.length >= 1 ? uploadImageB : uploadButton}
                  </Upload>
                </Space>
              </Form.Item>

            </div>
          </Form>
        </div>
      </div>
      <div className='width-right width-right-compare'>
        <div className='analysis-top'>
          <span> {t('AnalysisResults')}</span>
        </div>
        <Spin className='spin' spinning={tableLoading} tip="Result generating...">
          <div className='spin-item'>
            {similarity?<div>
              <div style={{marginTop: '10px', width: '100%'}} className="double-container"><div className='image-text-result'>
                <div className='image-items-text-right' style={{backgroundColor: '#141414'}}>
                  Sample[A]</div>
                <div className='image-container-out-div'
                  style={{backgroundImage: `url(${fileCompareFile.image1})`}}>
                </div>
              </div><div className='image-text-result'>
                <div className='image-items-text-right' style={{backgroundColor: '#141414'}}>
                  Sample[B]</div>
                <div className='image-container-out-div'
                  style={{backgroundImage: `url(${fileCompareFile.image2})`}}>
                </div>
              </div></div>
              <ul className='detailsTaskText'>
                <li >
                  {t('Face Similarly')}{' :  '}{similarity}
                </li>
                <li >
                  {t('description')}{' :  '}{similarityDescription}
                </li>
              </ul></div>:<div className='detailsTaskText-no-data'>
                {tableLoading?'':<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </div>}
          </div>
        </Spin>
      </div>
    </div>
  );
};
FaceRecognition.propTypes = {};

export default FaceRecognition;
