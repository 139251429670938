import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, message, Row, Table} from 'antd';
import Add from '../../assets/Add.png';
import {DownloadOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {getTableListData} from '../../api';
import {exportExcel} from '../excal/excel';
import {getColumnSearchProps} from './ColumnSearch';


const CustomTable = ({
  addFuc,
  columns,
  buttonGroup,
  InitApi,
  updateList,
  expandedRowRender, // 4.29 xcy
  onExpand, // 4.29 xcy
  expandedRowKeys, // 4.29 xcy
  params,
  hiddenExport,
  rowSelection,
  unBindButton,
  scroll,
  rowId,
}) => {
  const {t} = useTranslation();
  const [loading, setLoading]=useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [filter, setFilter]= useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 10, current: 1, total: 0, size: 'small',
  });

  const getTableList=(data)=>{
    setLoading(true);
    getTableListData(InitApi, {
      ...params,
      ...data,
    }, t('flag')).then((res)=>{
      setTableData(res.data.items);
      setPagination({
        size: 'small',
        pageSize: res.data.pageSize,
        current: res.data.pageNum,
        total: res.data.totalPage*res.data.pageSize,
      });
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>setLoading(false));
  };
  const toTrim=(obj)=> {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key]) {
          if (typeof (obj[key][0]) === 'string') {
            obj[key] = obj[key][0].trim();
          } else {
            obj[key] = obj[key][0];
          }
        }
      });
      return obj;
    }
    return {};
  };
  const onTableChange=(pages, filters, sorts)=>{
    const filterData=toTrim(filters);
    const data={
      ...filters,
      pageNum: pages.current,
      pageSize: pages.pageSize,
    };
    setFilter(filterData);
    getTableList(data);
  };

  const onExportExcel=()=>{
    const data={
      ...filter,
      pageSize: 100000,
      pageNum: 1,
    };
    setExportLoading(true);
    const exportColumns=columns.filter((item)=>item.onExport);
    getTableListData(InitApi, data, t('flag')).then((res)=>{
      exportExcel('Report', exportColumns, res.data.items);
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=> setExportLoading(false));
  };
  const newColumns = (column) => {
    return column.map((item)=>{
      let c = {
        ...item,
      };
      if (item.search) {
        c = {...c, ...getColumnSearchProps()};
      }
      return c;
    });
  };

  useEffect(()=>{
    getTableList({
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
    });
  }, []);

  useEffect(()=>{
    if (updateList) {
      getTableList({
        ...filter,
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  }, [updateList]);

  return (
    <div className="contact-bg">
      <Row className="margin-b-16">
        <Col span={12}>
          {addFuc&&(<Button
            className="marginB16 "
            type="text"
            onClick={()=>addFuc()}
          >
            <img src={Add} height={17}/>
            <span className="add">{t('add')}</span>
          </Button>)}
          {buttonGroup&& buttonGroup()}
          {unBindButton&&rowSelection?.selectedRowKeys?.length>0&&(<Button
            className="marginB16 "
            type="normal"
            onClick={()=>unBindButton()}
          >
            {t('Unbinding buildings')}
          </Button>)}
        </Col>
        <Col span={12} className="text-right">
          {!hiddenExport&&(<Button
            onClick={onExportExcel}
            loading={exportLoading}
            icon={<DownloadOutlined/>}
            type="normal"
          >
            {t('export')}
          </Button>)}
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        loading={loading}
        dataSource={tableData}
        pagination={pagination}
        onChange={onTableChange}
        columns={newColumns(columns)}
        rowKey={rowId||'id'}
        scroll={{x: 1500}}
        expandable={{expandedRowRender, onExpand, expandedRowKeys}}/>
    </div>
  );
};

CustomTable.propTypes = {
  addFuc: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandedRowRender: PropTypes.func,
  onExpand: PropTypes.func,
  buttonGroup: PropTypes.func,
  InitApi: PropTypes.string.isRequired,
  updateList: PropTypes.number.isRequired,
  expandedRowKeys: PropTypes.array,
  ButtonGroupType: PropTypes.number,
  params: PropTypes.object,
  hiddenExport: PropTypes.bool,
  scroll: PropTypes.bool,
  rowSelection: PropTypes.object,
  unBindButton: PropTypes.func,
  rowId: PropTypes.string,
};

export default CustomTable;
