import React, {useState} from 'react';
import {VISITERLIST} from '../../../api/Urls';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../components/customTable';
import {Button, Image, message, Space, Table} from 'antd';
import imgError from '../../../assets/imgError.png';
import {getDetailData} from '../../../api';
import {useSearchParams} from 'react-router-dom';
import {timeFormat} from '../../../components/baseTime';

const VisitHistory = () => {
  const {t} = useTranslation();
  const [locationParams]= useSearchParams();
  const source = locationParams.get('source');
  const [updateList, setUpdateList] = useState(0);
  const [expandedKey, setExpandedKey]=useState([]);
  const [subData, setSubData] = useState([]);
  const [subLoading, setSubLoading]= useState(true);
  const [type, setType]=useState(source?parseInt(source):null);
  const [params, setParams] = useState({
    type: source||'',
  });

  const onSourceChange=(type)=>{
    setType(type);
    setParams({pageNum: 1, type: type||''});
    setUpdateList(updateList+1);
  };
  const ButtonGroup = ()=>(
    <Space className="marginB16">
      <Button
        type={!type?'chosen':'normal'}
        onClick={()=>{
          onSourceChange('');
        }}>{t('all')}</Button>
      <Button
        type={type===1?'chosen':'normal'}
        onClick={()=>{
          onSourceChange(1);
        }}
      >{t('returnVisitor')}</Button>
      <Button
        type={type===2?'chosen':'normal'}
        onClick={()=>{
          onSourceChange(2);
        }}
      >{t('newVisitor')}</Button>
    </Space>);

  /*eslint-disable*/
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      onExport: true,
    },
    {
      key:"faceImageShowUrl",
      dataIndex:"faceImageShowUrl",
      title:t("faceImage"),
      onExport: true,
      render: (faceImageShowUrl) => (<Image src={faceImageShowUrl} width={100} fallback={imgError}/>)
    },
    {
      key:"memberName",
      dataIndex:"memberName",
      title:t('name'),
      onExport: true,
      search:true,
    },
    {
      key:"icNumber",
      dataIndex:"icNumber",
      title:t("ic"),
      onExport: true,
      search:true,
    },
    {
      key:"gender",
      dataIndex:"gender",
      title:t('gender'),
      onExport: true,
      exportRender: (gender) => {
        if (gender.gender===2) {
          return t('male');
        } else if (gender.gender===1) {
          return t('female');
        }
        return '';
      },
      render:(gender) => (<span>{gender === 2 ? t('male') : gender === 1 ?t('female'):('')}</span>),
      filters:[{text: t('male'), value: 2}, {text: t('female'), value: 1}]
    },
    {
      key:"firstVisitTime",
      dataIndex:"firstVisitTime" ,
      title:t('entryTime'),
      onExport: true,
      render:(firstVisitTime)=> timeFormat(firstVisitTime),
      exportRender:(firstVisitTime)=> timeFormat(firstVisitTime.firstVisitTime),
    },
    {
      key:"lastVisitTime" ,
      dataIndex:"lastVisitTime",
      title:t('leaveTime'),
      onExport: true,
      render:(lastVisitTime)=> timeFormat(lastVisitTime),
      exportRender:(lastVisitTime)=> timeFormat(lastVisitTime.lastVisitTime),
    },
    {
      key:"dwellTime" ,
      dataIndex:"dwellTime" ,
      title:t('dwellTime'),
      onExport: true,
    }
  ];
  const columnsChild = [
    {
      key:"index",
      title:t('id'),
      width:80,
      render:(record)=>(<span>{record.index+1}</span>)
    },
    {
      key:"faceShowUrl",
      dataIndex:"faceShowUrl",
      title:t('faceImage'),
      render:(faceImageShowUrl) => (
        <Image alt="faceImage" src={faceImageShowUrl} className="img80" fallback={imgError}/>)
    },
    {
      key:"entryTime" ,
      dataIndex:"entryTime" ,
      title:t('entryTime'),
      render:(entryTime)=> timeFormat(entryTime),
      exportRender:(entryTime)=> timeFormat(entryTime.entryTime),
    },
    {
      key:"exitTime",
      dataIndex:"exitTime" ,
      title:t('leaveTime'),
      render:(exitTime)=> timeFormat(exitTime),
      exportRender:(exitTime)=> timeFormat(exitTime.exitTime),
    },
    {
      key:"dwellTime",
      dataIndex:"dwellTime",
      title:t('dwellTime'),
    }
  ];
  
  /*eslint-disable*/
  const expandedRowRender = () => {
    return <Table columns={columnsChild} rowKey={(record) => record.index} dataSource={subData} loading={subLoading} pagination={false}/>;
  };
  const onExpand= (expanded, record)=>{
    if (!expanded) {
      setExpandedKey([]);
      return false;
    }
    setExpandedKey([record.id]);
    setSubData([]);
    setSubLoading(true);
    getDetailData(record.id, t('flag')).then( (res)=>{
      // message.success(res.msg);
      const _subData = res.data.map((item, index)=>{
        return {index: index, ...item};
      });
      setSubData(_subData);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      setSubLoading(false);
    });
  }

  return (
      <div>
        <CustomTable
            columns={columns}
            expandedRowRender={expandedRowRender}
            expandedRowKeys={expandedKey}
            onExpand={onExpand}
            updateList={updateList}
            InitApi={VISITERLIST}
            buttonGroup={ButtonGroup}
            params={params}/>
      </div>
  );
};

export default VisitHistory;
