import React, {} from 'react';
import PropTypes from 'prop-types';
import {Col, Image, List, Popconfirm, Row, message} from 'antd';
import ErrorImg from '../../../assets/imgError.png';
import iconSuspect from '../../../assets/icon-suspect2x.png';
import {addPersonOfInterestData} from '../../../api';
import {useTranslation} from 'react-i18next';
import {timeFormat} from '../../../components/baseTime';

const ResultList = ({searchList, pagination, viewDetails, imageKey, interest, timeKey}) => {
  const {t} = useTranslation();

  const addPersonOfInterest=(id)=>{
    addPersonOfInterestData(id, t('flag')).then((res)=>{
      message.success(res.msg);
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    });
  };
  console.log(`Browser window size: ${window.innerWidth} x ${window.innerHeight}`);
  console.log(`Screen size: ${window.screen.width} x ${window.screen.height}`);
  return (
    <div className="details-image-box">
      <List
        dataSource={searchList}
        pagination={pagination}
        renderItem={(item, index) => (
          <List.Item
            className="details-image-item"
            key={'list'+index}
          >
            <div className="special-box">
              <div className="special-box-child-5"></div>
              <div className="special-box-child-6"></div>
              <div className="special-box-child-7"></div>
              <div className="special-box-child-8"></div>
            </div>
            <div className="details-image-title">{item.cameraName??''}</div>
            <div
              className="details-image"
              onClick={()=>{
                viewDetails(item);
              }}
            >
              <Image src={item[imageKey]?.split(',')[0]} preview={false} className="details-images" fallback={ErrorImg}/>
              {item.faceScore&& <div className="details-image-tips">
                {t('similarity')}:{parseInt(item.faceScore*100)+'%'}
              </div>}
            </div>
            <Row className="details-info">
              <Col flex="auto" className='time-container'>
                {timeFormat(item[timeKey])}
              </Col>
              <Col flex="30px">
                {interest&&( <Popconfirm
                  placement="bottom"
                  title={t('addInterest')}
                  onConfirm={()=>{
                    addPersonOfInterest(item.faceRecordId);
                  }}
                  okText={t('yes')}
                  cancelText={t('no')}>
                  <img src={iconSuspect} className="icon-suspect"/>
                </Popconfirm>)}
              </Col>
            </Row>
            <div className="special-box-bottom">
              <div className="special-box-child-1"></div>
              <div className="special-box-child-2"></div>
              <div className="special-box-child-3"></div>
              <div className="special-box-child-4"></div>
            </div>
          </List.Item>
        )}
        grid={{
          gutter: 8,
          // column: 6,
          xs: 1, // <576px
          sm: 2, // ≥576px
          md: 3, // ≥768px
          lg: 3, // ≥992px
          xl: 3, // ≥1200px
          xxl: 6, // ≥1600px
        }}
      />

    </div>
  );
};

ResultList.propTypes = {
  searchList: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  viewDetails: PropTypes.func.isRequired,
  imageKey: PropTypes.string.isRequired,
  timeKey: PropTypes.string.isRequired,
  interest: PropTypes.bool,
};

export default ResultList;
