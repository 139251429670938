import React, { } from 'react';
import PropTypes from 'prop-types';
import {Table, Image, Button} from 'antd';
import ErrorImg from '../../../assets/imgError.png';
import {useTranslation} from 'react-i18next';

const ResultTable = ({searchList, pagination, waitingInfo, viewDetails}) => {
  const {t} = useTranslation();

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('ic'),
      dataIndex: 'icNumber',
      key: 'icNumber',
    },
    /*eslint-disable*/
    {
      title: t('image'),
      key: 'image',
      render: (record)=>(
        <Image
          fallback={ErrorImg}
          src={record.imageUrls?record.imageUrls[0]:''}
          className="img80"
        />
      ),
    },
    /*eslint-disable*/
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('dwellTime'),
      dataIndex: 'waitingTime',
      key: 'waitingTime',
    },
    {
      title: t('start'),
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: t('end'),
      dataIndex: 'endTime',
      key: 'endTime',
    },
    /*eslint-disable*/
    {
      title: t('action'),
      key: 'action',
      render: (record)=>(
        <Button type='link' onClick={()=> viewDetails(record.detail)}>{t('details')}</Button>
      ),
    },
    /*eslint-disable*/
  ];
  return (
    <div>
      <div className="waiting-time-info-box">
        <div className="waiting-time-info-item">{t('maxTime')} : {waitingInfo.maxWaitingTime}</div>
        <div className="waiting-time-info-item">{t('minTime')} : {waitingInfo.minWaitingTime}</div>
        <div className="waiting-time-info-item">{t('averageTime')} : {waitingInfo.avgWaitingTime}</div>
        {/* <div className="waiting-time-info-item">Max dwell number : {waitingInfo.maxWaitingNum}</div> */}
      </div>
      <div className="padding16">
        <Table
          pagination={pagination}
          columns={columns}
          dataSource={searchList}
        />
      </div>
    </div>
  );
};

ResultTable.propTypes = {
  searchList: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  waitingInfo: PropTypes.object.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default ResultTable;
