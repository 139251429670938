import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {Button, DatePicker, Space} from 'antd';
// import moment from 'moment';
import './style.css';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../components/customTable';
import {VISITORCOUNTLIST} from '../../../api/Urls';
// import {timeFormat} from '../../../components/baseTime';

const VisitorCount = () => {
  const {t} = useTranslation();

  const [updateList, setUpdateList] = useState(0);

  const [type, setType] = useState(1);
  const [initDate, setInitDate] = useState();
  const [params, setParams] = useState({
    type: type,
  });

  const onSourceChange = (type) => {
    setType(type);
    setParams({pageNum: 1, type});
    setUpdateList(updateList + 1);
    setInitDate();
  };
  // 日期开启变更函数
  // const onOpenChange = (open) => {
  //   setType(4);
  // };
  const onDateChange = (value) => {
    setType(4);
    if (value?.length>0) {
      const tempStartTime = value[0]?.valueOf();
      const tempEndTime = value[1]?.valueOf();
      setParams({
        pageNum: 1,
        type: 4,
        startTime: tempStartTime,
        endTime: tempEndTime,
      });
      setUpdateList(updateList + 1);
      setInitDate(value);
      console.log(initDate, 'initDate');
    }
  };
  const ButtonGroup = () => (
    <Space className="marginB16">
      <Button
        type={type === 1 ? 'chosen' : 'normal'}
        onClick={() => {
          onSourceChange(1);
        }}>{t('day')}</Button>
      <Button
        type={type === 2 ? 'chosen' : 'normal'}
        onClick={() => {
          onSourceChange(2);
        }}
      >{t('week')}</Button>
      <Button
        type={type === 3 ? 'chosen' : 'normal'}
        onClick={() => {
          onSourceChange(3);
        }}
      >{t('month')}</Button>
      <DatePicker.RangePicker
        format="YYYY-MM-DD"
        onChange={onDateChange}
        // onOpenChange={onOpenChange}
        defaultValue={initDate||null}
      />
    </Space>);

  /*eslint-disable*/
  const columns = [
    {
      title: t('date'),
      dataIndex: type===1||type===4?'date':'statisticsDate',
      key: 'id',
      width: 200,
      onExport: true,
      // render: (date) => timeFormat(date),
      // exportRender: (date) => timeFormat(date.date),
    },
    {
      key: 'total',
      dataIndex: 'total',
      title: t("visitorCount"),
      onExport: true,
    },
    {
      key: "male",
      dataIndex: "male",
      title: t('male'),
      onExport: true,
    },
    {
      key: "female",
      dataIndex: "female",
      title: t("female"),
      onExport: true,
    },
    {
      key: "age0to20",
      dataIndex: "age0to20",
      title: t('age') + ": 0-20",
      onExport: true,
    },
    {
      key: "age21to40",
      dataIndex: "age21to40",
      title: t('age') + ": 21-40",
      onExport: true,
    },
    {
      key: "age41to60",
      dataIndex: "age41to60",
      title: t('age') + ": 41-60",
      onExport: true,
    },
    {
      key: "ageGt60",
      dataIndex: "ageGt60",
      title: t('age') + ": >60",
      onExport: true,
    },
    
    {
      key: "average",
      dataIndex: "dwellTime",
      title: t('averageTime'),
      onExport: true,
    }
  ];
  
  return (
    <div>
      {type===1&&<CustomTable
        columns={columns}
        updateList={updateList}
        InitApi={VISITORCOUNTLIST}
        buttonGroup={ButtonGroup}
        params={params}/>}
      {type===2&&<CustomTable
        columns={columns}
        updateList={updateList}
        InitApi={VISITORCOUNTLIST}
        buttonGroup={ButtonGroup}
        params={params}/>}
        {type===3&&<CustomTable
        columns={columns}
        updateList={updateList}
        InitApi={VISITORCOUNTLIST}
        buttonGroup={ButtonGroup}
        params={params}/>}
        {type===4&&<CustomTable
        columns={columns}
        updateList={updateList}
        InitApi={VISITORCOUNTLIST}
        buttonGroup={ButtonGroup}
        params={params}/>}
    </div>
  );
};


VisitorCount.propTypes = {};

export default VisitorCount;
