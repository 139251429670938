import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import store from './store';
import {storage} from './utils/storage';
import {getAlarmTypeNameListData, userLogin} from './api';
/* eslint-disable */

function render(props) {
  console.log('render props', props);
  const { container } = props;
  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>,
    container ? container.querySelector('#root') : document.querySelector('#root'));
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log('[react16] react app bootstraped');
}

export async function mount(props) {
  // console.log(props);
  // if (!props) {
  const { currentUser } = props.globalState;
  try {
    const res = await userLogin({
      username: 'dev',
      password: 'MTIzNDU2YWJjLl9oY3lOTEJA',
      captchaKey: '1111',
      captcha: '1111',
    });
    if (res.code === 200) {
      storage.saveData('local', 'userInfo', {
        ...res.data,
        nickName: currentUser.nickname,
        userName: currentUser.username,
      });
      storage.saveData('local', 'modalTip', true);
    }
    const list = await getAlarmTypeNameListData();
    if (res.code === 200) {
      storage.saveData('local', 'alarmType', list.data);
    }
  } catch (error) {
    console.error(error);
  }


  render(props);
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
}