import React, {useState, useEffect} from 'react';
import {getSearchData, getSearchDetailData, getOutDateMessage} from '../../api';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Spin, Row, Col, Modal, message, Drawer, Alert} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';
import './style.css';
import BaseSearchFrom from './components/BaseSearchFrom';
import ResultList from './components/ResultList';
import DetailsModal from './components/DetailsModal';
import ResultTable from './components/ResultTable';
import DetailsDrawer from './components/DetailsDrawer';
import DetailsSimpleModal from './components/DetailsSimpleModal';
import {getBaseConfig} from './baseConfig';
import {getSearchConfig} from './seacrhConfig';
const Analytics = () => {
  const {t} = useTranslation();
  const locationParams = useParams();
  const [config, setConfig]=useState(getSearchConfig(locationParams.moduleId));
  const [baseConfig, setBaseConfig]=useState(getBaseConfig(locationParams.moduleId));
  const [loading, setLoading]= useState(false);
  const [notice, setNotice]= useState();
  const [searchList, setSearchList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 18,
    size: 'small',
    total: 0,
    showSizeChanger: false,
    hideOnSinglePage: true,
  });
  const [showDetail, setShowDetail] = useState(false);
  const [details, setDetails] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  // current show img
  const [currentImage, setCurrentImage] = useState('');
  const [waitingInfo, setWaitingInfo] = useState({
    avgWaitingTime: '',
    maxWaitingNum: '',
    maxWaitingTime: '',
    minWaitingTime: '',
  });
  const [videoUrl, setVideoUrl]= useState(null);
  const columnsDrawer=[
    {
      title: t('entryTime'),
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: t('leaveTime'),
      dataIndex: 'endTime',
      key: 'endTime',
    },
    // {
    //   title: t('action'),
    //   dataIndex: 'imageUrl',
    //   key: 'imageUrl',
    //   // eslint-disable-next-line react/display-name
    //   render: (imageUrl)=><Button type="link" onClick={()=> {
    //     setCurrentImage(imageUrl);
    //   }}>{t('details')}</Button>,
    // },
  ];

  const onSearch=(values, page)=>{
    console.log(values, 'onSearch values的值!!!!!!!!!!!');
    const base=getBaseConfig(locationParams.moduleId);
    setLoading(true);
    values.pageNum=page||1;
    values.pageSize=base.pageSize;
    const formData = new FormData();
    Object.keys(values).forEach((key)=>{
      console.log(key, values[key], 'values[key]');
      formData.append(key, values[key]);
    });
    console.log(formData, base.searchApi, 'formData的提交值和提交的接口');
    getSearchData( base.searchApi, formData, t('flag')).then((res)=>{
      if (base.listType==='table') {
        setWaitingInfo({
          avgWaitingTime: res.data.avgWaitingTime,
          maxWaitingNum: res.data.maxWaitingNum,
          maxWaitingTime: res.data.maxWaitingTime,
          minWaitingTime: res.data.minWaitingTime,
        });
      }
      setSearchList(res.data.items||[]);
      setPagination({
        ...pagination,
        current: res.data.pageNum,
        total: parseInt(res.data.totalPage )* parseInt(res.data.pageSize),
        onChange: (page)=>onSearch(values, page),
      });
    }).catch((error)=>{
      message.error({
        content: error?error.toString():t('apiError'),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>setLoading(false));
  };
  const viewDetails= (data) => {
    setShowDetail(true);
    if (baseConfig.detailsType==='infos') {
      setDetailsLoading(true);
      getSearchDetailData(baseConfig.detailsApi, data, t('flag')).then((res)=>{
        setDetails({
          ...res.data,
          faceShowUrl: data.faceShowUrl,
        });
      }).catch((error)=>{
        message.error({
          content: error.toString(),
          key: 'netError',
          duration: 2,
        });
      }).finally(()=>setDetailsLoading(false));
    } else if (baseConfig.detailsType==='drawer') {
      setDetailList(data||[]);
      setCurrentImage(data[0].imageUrl||'');
    } else if (baseConfig.detailsType==='videoImages') {
      setDetails(data[baseConfig.detailsKey]);
      setVideoUrl(data[baseConfig.videoKey]||null);
    } else if (baseConfig.detailsType==='bigImage') {
      setCurrentImage(data[baseConfig.detailsKey]);
    }
  };

  useEffect(()=>{
    if (getSearchConfig(locationParams.moduleId)!==config) {
      setConfig(getSearchConfig(locationParams.moduleId));
      setBaseConfig(getBaseConfig(locationParams.moduleId));
    }
  }, [locationParams]);

  useEffect(()=>{
    const init={};
    Object.keys(config).forEach((key)=>{
      if (key==='radio') {
        config.radio.forEach((item)=>{
          init[item.name]=item.defaultResValue;
        });
      } else if (key==='period') {
        init.startTime=config.period.defaultResValue[0];
        init.endTime=config.period.defaultResValue[1];
        init.areaId=config.period.defaultResValue[1];
      } else if (key!=='infos') {
        init[key]=config[key].defaultResValue;
      }
    });
    setPagination({
      ...pagination,
      total: 0,
    });
    setSearchList([]);
    onSearch(init);
    // 获取过期信息
    if (baseConfig.outDateNotice) {
      getOutDateMessage(baseConfig.outDateNotice, t('flag')).then((res)=>{
        setNotice(res.data);
      }).catch((error)=>{
        message.error({
          content: error.toString(),
          key: 'netError',
          duration: 2,
        });
      }).finally();
    }
  }, [config]);

  return (
    <Spin spinning={false}>
      {notice&&<Alert showIcon={false} message={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ExclamationCircleFilled style={{marginRight: '8px', color: '#faad14'}}/>{''}{notice}</div>} banner />}
      <Row gutter={[16, 16]}>
        <Col lg={12} xl={8} xxl={6}>
          <BaseSearchFrom onSearch={onSearch} config={config}/>
        </Col>
        <Col lg={12} xl={16} xxl={18}>
          {baseConfig.listType==='list'&&(<div className="details-box paddingT8">
            <div className="details-title">{t(baseConfig.title)}</div>
            <Spin spinning={loading}>
              <ResultList
                searchList={searchList}
                pagination={pagination}
                viewDetails={viewDetails}
                imageKey={baseConfig.imageKey}
                interest={baseConfig.interest}
                timeKey={baseConfig.timeKey}
              />
            </Spin>
          </div>)}
          {baseConfig.listType==='table'&&(<ResultTable
            pagination={pagination}
            searchList={searchList}
            waitingInfo={waitingInfo}
            viewDetails={viewDetails} />)}

          {(baseConfig&&(baseConfig.detailsType==='infos'))&&(
            <Modal
              width={800}
              title={t('details')}
              visible={showDetail}
              onCancel={() => setShowDetail(false)}
              footer={null}
              destroyOnClose>
              <Spin spinning={detailsLoading}>
                <DetailsModal
                  detail={details}
                  maskHad={baseConfig.maskHad}
                />
              </Spin>
            </Modal>)}

          {(baseConfig&&(baseConfig.detailsType==='videoImages'||baseConfig.detailsType==='bigImage'))&&(
            <Modal
              title={t('details')}
              visible={showDetail}
              onCancel={() => setShowDetail(false)}
              width={800}
              footer={null}
              className="face-recognition-modal"
              destroyOnClose>
              <Spin spinning={detailsLoading}>
                {baseConfig.detailsType==='videoImages'?(<DetailsSimpleModal
                  details={details}
                  videoUrl={videoUrl}
                  type='videoImages'
                />):(<DetailsSimpleModal
                  type='bigImage'
                  bigImageUrl={currentImage}
                  bigImageUrlImageGroup={currentImage.split(',').length>1?true:false}
                />)}
              </Spin>
            </Modal>)}
          {(baseConfig&&baseConfig.detailsType==='drawer')&&(
            <Drawer
              width={800}
              title={t('details')}
              placement="right"
              visible={showDetail}
              onClose={() => setShowDetail(false)}
            >
              <Spin spinning={detailsLoading}>
                <DetailsDrawer
                  detailList={detailList}
                  columnsDrawer={columnsDrawer}
                  currentImg={currentImage}
                />
              </Spin>
            </Drawer>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

Analytics.propTypes = {};

export default Analytics;
