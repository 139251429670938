const _localStorage = window.localStorage;
const _sessionStorage = window.sessionStorage;

const saveData = (type='session', name, data) => {
  const d = (typeof data === 'object') ? JSON.stringify(data) : data;
  if (type === 'local') {
    _localStorage.setItem(name, d);
  } else {
    _sessionStorage.setItem(name, d);
  }
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getData = (name) => {
  let session = _sessionStorage.getItem(name);
  if (session && typeof session === 'string' && isJsonString(session)) {
    try {
      session = JSON.parse(session);
    } catch (error) {
      console.log(error);
    }
  } else {
    session = {};
  }
  if (Object.keys(session).length > 0) {
    return session;
  }
  let local = _localStorage.getItem(name);
  if (local && typeof local === 'string' && isJsonString(local)) {
    try {
      local = JSON.parse(local);
    } catch (error) {
      console.log(error);
    }
  } else {
    local = {};
  }
  if (Object.keys(local).length > 0) {
    return local;
  }
  return null;
};

const clearData = (type='clearAll', dataKey) => {
  if (type === 'clearAll') {
    _localStorage.clear();
    _sessionStorage.clear();
  } else {
    _localStorage.removeItem(dataKey);
    _sessionStorage.removeItem(dataKey);
  }
};


export const storage = {
  saveData,
  getData,
  clearData,
};


