
import React from 'react';
import LoginPage from './login';
import Loading from './loading';
const Login=()=> {
  // 是否是微前端环境
  const isMicro = window.__POWERED_BY_QIANKUN__;

  return isMicro?<Loading />:<LoginPage/>;
};
export default Login;
