import React, {useState} from 'react';
import {Button, message, Modal, Space, Tag} from 'antd';
import {addAlarmType, updateAlarmType} from '../../../api';
import {ALARMTYPELIST} from '../../../api/Urls';
import {columnsForm} from './config';
import {useTranslation} from 'react-i18next';
import EditForms from '../../../components/forms/EditForms';
import CustomTables from '../../../components/customTable';
import {alarmTypeFuc} from '../../../components/baseTypeSelect';
import {storage} from '../../../utils/storage';

const AlarmSettings =() => {
  const {t} = useTranslation();
  const [updateList, setUpdateList] = useState(0);
  const [onSaving, setOnSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initData, setInitData] = useState(null);
  const [modalTitle, setModalTitle] = useState('add');
  const alarmTypes = storage.getData('alarmType')||[];

  const onFinish = (values) => {
    setOnSaving(true);
    // delete value.alarmTypeName;

    values.receiverId = values.receiverId.join();
    values.sendType = values.sendType.join();
    const data={
      receiverId: values.receiverId,
      sendType: values.sendType,
      id: values.id,
    };
    const RequestPromise = modalTitle === 'add' ?
      addAlarmType(values, t('flag')) :
      updateAlarmType(data, t('flag'));

    RequestPromise.then((res)=>{
      message.success(res.msg);
      setOnSaving(false);
      setIsModalVisible(false);
      setModalTitle('add');
      setUpdateList(updateList + 1);
      setInitData({});
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(()=>{
      setOnSaving(false);
    });
  };
  const onEdit = (data) => {
    console.log(data);
    // if (data.alarmType)data.alarmTypeName=alarmTypeFuc(data.alarmType);
    setIsModalVisible(true);
    setModalTitle('edit');
    setInitData({
      ...data,
      alarmType: alarmTypeFuc(data.alarmType),
      sendType: data.sendType?.split(','),
      receiverId: data.receiverId?.split(','),
    });
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setModalTitle('add');
    setInitData(null);
  };
  /*eslint-disable*/
  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('id'),
      width: 80,
    },
    {
      title: t('alarmType'),
      dataIndex: 'alarmType',
      key: 'alarmType',
      onExport: true,
      render: (alarmType) => alarmTypeFuc(alarmType),
      exportRender: (item) => alarmTypeFuc(item.alarmType),
      filters: alarmTypes.map(({alarmTypeName,cnAlarmTypeName,alarmType})=>{return {
        text: t('flag')==='1'?alarmTypeName:cnAlarmTypeName,
        value:alarmType,
      }}),
      filterMultiple: false,
    },
    {
      title: t('sendType'),
      dataIndex: 'sendType',
      key: 'sendType',
      onExport: true,
      render: (receiverId) => {
        if (receiverId) {
          return (<Space wrap size="small">
            {receiverId.split(',').map((item) => (<Tag key={'receiverId' + item}>
              {t(item)}
            </Tag>))}
          </Space>);
        }
        return '';
      },
      exportRender: (sendType) => {
        if (sendType.sendType) {
          const arr = [];
          sendType.sendType.split(',').forEach((item) => {
            arr.push(t(item));
          });
          return arr.join(',');
        }
        return '';
      },
      filters: [
        {text: t('Portal Alert'), value: 'Portal Alert'},
        {text: t('sms'), value: 'sms'},
        {text: t('whatsapp'), value: 'whatsapp'},
        {text: t('telegram'), value: 'telegram'},
        {text: t('email'), value: 'email'},
      ],
      filterMultiple: false,
    },
    {
      title: t('receiver'),
      dataIndex: 'receiverName',
      key: 'receiverName',
      render: (receiver) => {
        if (receiver) {
          return (<Space wrap size="small">
            {receiver.split(',').map((item) => (<Tag key={'receiverId' + item}>
              {item}
            </Tag>))}
          </Space>);
        }
        return '';
      },
    },
    {
      title: t('action'),
      key: 'id',
      width: 180,
      render: (record) => (
        <Space>
          <Button type="link" size="small" onClick={() => {
            onEdit(record);
          }}>
            {t('edit')}
          </Button>
        </Space>),
    },
  ];
  /*eslint-disable*/
  return (
    <div>
      <CustomTables
          columns={columns}
          InitApi={ALARMTYPELIST}
          updateList={updateList}/>
      <Modal
          title={t(modalTitle)}
          destroyAll
          visible={isModalVisible}
          footer={null}
          width={700}
          onCancel={onCancel}>
        <EditForms
            columnsForm={columnsForm}
            loading={onSaving}
            initData={initData}
            onCancel={onCancel}
            onFinish={onFinish}
        />
      </Modal>
    </div>
  );
};

export default AlarmSettings;
