import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, message, Radio, Row} from 'antd';
import {AimOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import mapStyle from '../../../../assets/mapIcon/custom_map_config .json';
import position from '../../../../assets/mapIcon/position.png';
import {useTranslation} from 'react-i18next';
import {InfoWindow, Map, Marker, Label} from 'react-bmapgl';
import {updateCameraLocation} from '../../../../api';
import {storage} from '../../../../utils/storage';

const BMapGL = window.BMapGL;

const PositionMap = ({map, locationData, onFinish}) => {
  const {t} = useTranslation();
  const o =storage.getData( 'userInfo').originalLocation?.split(',')||[116, 192];
  const center = new BMapGL.Point(o[0], o[1]);
  const ls = new BMapGL.LocalSearch(center);

  const mapRef = useRef();
  const markerRef= useRef();

  const [searchForm] = Form.useForm();
  const [locationForm] = Form.useForm();
  const [positionForm] = Form.useForm();

  const [zoom, setZoom] = useState(13);

  const [settingType, setSettingType] = useState(1);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [address, setAddress] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [resetLocation, setResetLocation] = useState(true);
  const [loading, setLoading] = useState(false);

  const onSearch = (values) => {
    ls.search(values.cameraAddress);// 检索输入内容
    ls.setSearchCompleteCallback((res) => { // 检索成功回调
      if (res._pois.length > 0) {
        const data = res._pois[0];
        setLng(data.point.lng);
        setLat(data.point.lat);
        setAddress(data.address + ' ' + data.title);
        locationForm.setFieldsValue({
          cameraAddress: data.address + ' ' + data.title,
          coordinate: data.point.lng+','+data.point.lat,
        });
        positionForm.setFieldsValue({
          lat: data.point.lat,
          lng: data.point.lng,
        });
        map = mapRef.current.map;
        map.setCenter(new BMapGL.Point(data.point.lng, data.point.lat));
      }
    });
  };
  const onSetLocation=(values)=>{
    if (resetLocation) {
      setLoading(true);
      updateCameraLocation(values, t('flag')).then((res)=>{
        message.success(res.msg);
      }).catch((error)=>{
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(()=>{
        onFinish();
        setLoading(false);
      });
      setResetLocation(false);
    } else {
      setResetLocation(true);
    }
  };
  const onPositionChange = (values) => {
    console.log(values, '获取的坐标的值');
    // ls.searchNearby('', new BMapGL.Point(parseFloat(values.lng), parseFloat(values.lat)));// 检索输入内容
    ls.searchNearby('周边', new BMapGL.Point(parseFloat(values.lng), parseFloat(values.lat)), 1000);
    ls.setSearchCompleteCallback((res) => { // 检索成功回调
      console.log(res, '经纬度检索成功的回调函数');
      if (res._pois.length > 0) {
        const data = res._pois[0];
        setAddress(data.address+' '+data.title);
        locationForm.setFieldsValue({
          cameraAddress: data.address + ' ' + data.title,
          coordinate: data.point.lng+','+data.point.lat,
        });
        searchForm.setFieldsValue({
          cameraAddress: data.address + ' ' + data.title,
        });
      } else {
        setAddress(t('flag')==='1'?'There is no address information for this coordinate':'此坐标暂无地址信息');
        locationForm.setFieldsValue({
          cameraAddress: t('flag')==='1'?'There is no address information for this coordinate':'此坐标暂无地址信息',
          coordinate: values.lng+','+values.lat,
        });
      }
      setLat(values.lat);
      setLng(values.lng);
      map = mapRef.current.map;
      map.setCenter(new BMapGL.Point(values.lng, values.lat));
    });
  };

  const infoBox = () => (<InfoWindow
    width={400}
    title={t('details')}
    position={{lng: lng, lat: lat}}
    height={200}
    onClose={() => setShowDetails(false)}
  >
    <div>
      <div className="info-box-title">
        {t('details')}
      </div>
      <div className="padding16">
        <Row >
          <Col span={5} className="map-info-title">{t('address')}</Col>
          <Col span={19} className="map-info-des">{address}</Col>
        </Row>
        <Row>
          <Col span={5} className="map-info-title">{t('latitude')}</Col>
          <Col span={19} className="map-info-des">{lat}</Col>
        </Row>
        <Row>
          <Col span={5} className="map-info-title">{t('longtitude')}</Col>
          <Col span={19} className="map-info-des">{lng}</Col>
        </Row>
        {!resetLocation&&(<div className="text-right padding-r-16">
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setResetLocation(true);
              setShowDetails(false);
            }}>
            {t('reset')}
          </Button>
        </div>)}
      </div>
    </div>
  </InfoWindow>);

  useEffect(()=>{
    const lng=locationData.coordinate?locationData.coordinate[0]:null;
    const lat=locationData.coordinate?locationData.coordinate[1]:null;
    const address=locationData.cameraAddress?locationData.cameraAddress:null;

    setLng(lng);
    setLat(lat);
    setAddress(address);
    setResetLocation(!lat);
    if (locationData.coordinate) {
      mapRef.current.map.setCenter(new BMapGL.Point(lng, lat));
    }

    locationForm.setFieldsValue({
      id: locationData.id,
      cameraAddress: address,
      coordinate: lng?(lng+','+lat):null,
    });
    searchForm.setFieldsValue({
      cameraAddress: address,
    });
    positionForm.setFieldsValue({
      lat: lat,
      lng: lng,
    });
  }, []);

  return (
    <div>
      <Radio.Group
        onChange={(e) => setSettingType(e.target.value)}
        defaultValue={settingType}
        buttonStyle="solid">
        <Radio.Button value={1}>
          {t('manual')}
        </Radio.Button>
        <Radio.Button value={2}>
          {t('coordinate')}
        </Radio.Button>
      </Radio.Group>

      <Row className="margin-t-16">
        <Col span={20}>

          <Form onFinish={onSearch} form={searchForm} layout="inline">
            <Form.Item name="cameraAddress" hidden={settingType!==1}>
              <Input allowClear style={{width: 400}}/>
            </Form.Item>
            <Form.Item hidden={settingType!==1}>
              <Button type="primary" ghost htmlType="submit" disabled={!resetLocation}>{t('search')}</Button>
            </Form.Item>
          </Form>
          <Form form={positionForm} onFinish={onPositionChange} layout="inline">
            <Form.Item name="lat" label={t('latitude')} hidden={settingType!==2}>
              <Input allowClear/>
            </Form.Item>
            <Form.Item name="lng" label={t('longtitude')} hidden={settingType!==2}>
              <Input allowClear/>
            </Form.Item>
            <Form.Item hidden={settingType!==2}>
              <Button type="primary" ghost htmlType="submit" disabled={!resetLocation}>{t('search')}</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={4} >
          <Form onFinish={onSetLocation} form={locationForm} layout="inline" className="margin0 text-right">
            <Form.Item name="id" hidden>
              <Input allowClear/>
            </Form.Item>
            <Form.Item name="cameraAddress" hidden>
              <Input allowClear/>
            </Form.Item>
            <Form.Item name="coordinate" hidden>
              <Input allowClear/>
            </Form.Item>
            <Form.Item className="margin0">
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                style={{width: 140}}
                disabled={!lat}
              >{resetLocation ? t('setLocation') : t('resetLocation')}</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div className="text-right margin-t-16">
        <Radio.Group size="small">
          <Radio.Button
            value="0"
            disabled={zoom === 21}
            onClick={() => {
              setZoom(zoom + 1);
            }}>
            <PlusOutlined/>
          </Radio.Button>
          <Radio.Button
            value="1"
            disabled={zoom === 1}
            onClick={() => {
              setZoom(zoom - 1);
            }}>
            <MinusOutlined/>
          </Radio.Button>
          <Radio.Button
            value="2"
            onClick={() => {
              map = mapRef.current.map;
              map.setCenter(center);
            }}>
            <AimOutlined/>
          </Radio.Button>
        </Radio.Group>
      </div>
      <Map
        ref={mapRef}
        setCenter={center}
        zoom={zoom}
        mapStyleV2={{styleJson: mapStyle}}
        style={{height: 674}}
      >
        {lng && (<Marker
          ref={markerRef}
          icon={new BMapGL.Icon(position, new BMapGL.Size(40, 56))}
          position={{lng: lng, lat: lat}}
          enableDragging={settingType===1&&resetLocation}
          onDragend={()=>{
            const P=markerRef.current.marker.latLng;
            // console.log(markerRef.current);
            setLng(P.lng);
            setLat(P.lat);
            positionForm.setFieldsValue({
              lat: P.lat,
              lng: P.lng,
            });
            ls.search( new BMapGL.Point(P.lng, P.lat));// 检索输入内容
            ls.setSearchCompleteCallback((res) => { // 检索成功回调
              if (res&&res?._pois.length > 0) {
                const data = res._pois[0];
                setAddress(data.address+' '+data.title);
                locationForm.setFieldsValue({
                  cameraAddress: data.address + ' ' + data.title,
                  coordinate: P.lng+','+P.lat,
                });
                searchForm.setFieldsValue({
                  cameraAddress: data.address + ' ' + data.title,
                });
              } else {
                setAddress(t('flag')==='1'?'There is no address information for this coordinate':'此坐标暂无地址信息');
                locationForm.setFieldsValue({
                  cameraAddress: t('flag')==='1'?'There is no address information for this coordinate':'此坐标暂无地址信息',
                  coordinate: P.lng+','+P.lat,
                });

                locationForm.setFieldsValue({
                  coordinate: P.lng+','+P.lat,
                });
              }
            });
          }}
          onClick={() => {
            setShowDetails(true);
          }}/>)}
        {lng&&(<Label
          offset={{width: -80, height: 32}}
          position={new BMapGL.Point(lng, lat)}
          text={!resetLocation?t('positionInfoUsual'):t('positionInfoReset')}
        />)}
        {showDetails && infoBox()}
      </Map>
    </div>
  );
};

PositionMap.propTypes = {
  map: PropTypes.object,
  locationData: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default PositionMap;
