import {
  Button, DatePicker, Input, Space,
} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

export const getColumnSearchProps = (data, hideSorter) => ({
  // eslint-disable-next-line react/display-name
  filterDropdown: ({
    // eslint-disable-next-line react/prop-types
    selectedKeys, setSelectedKeys, confirm, clearFilters,
  }) => {
    const {t} = useTranslation();
    const handleSearch = (selectedKeys, confirm) => {
      confirm();
    };
    const handleReset = () => {
      clearFilters();
    };
    return (
      <div style={{padding: 8}}>
        <Input
          // placeholder={t('placeholderInput')+t(data)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={()=>handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            {t('search')}
          </Button>
          <Button onClick={()=>handleReset(clearFilters)} size="small" style={{width: 90}}>
            {t('reset')}
          </Button>
        </Space>
      </div>
    );
  },
  // eslint-disable-next-line react/display-name
  filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
  // sorter: !hideSorter,
  // onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

export const getColumnSearchTimeProps = (data, format, show) => ({
  // eslint-disable-next-line react/display-name
  filterDropdown: ({
    // eslint-disable-next-line react/prop-types,no-unused-vars
    selectedKeys, setSelectedKeys, confirm, clearFilters,
  }) => {
    const {t} = useTranslation();
    return (
      <div style={{padding: 8}}>
        <DatePicker
          showTime={!show ? {format: 'HH:mm'} : false}
          format={format}
          placeholder={t('placeholder')+data}
          onChange={(value) => setSelectedKeys(value ? [moment(value).format(show ? 'YYYYMMDD' : format)] : [])}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            {t('search')}
          </Button>
          <Button onClick={clearFilters} size="small" style={{width: 90}}>
            {t('reset')}
          </Button>
        </Space>
      </div>
    );
  },
  // eslint-disable-next-line react/display-name
  filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
  sorter: true,
  // onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

export const tableDataFilterSortFormat = (pagination, filters, sort) => {
  const F = {};

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      // eslint-disable-next-line prefer-destructuring
      F[key] = filters[key][0];
    }
  });
  if (sort.columnKey && sort.column) {
    F.sortField = sort.columnKey;
    F.sortWay = sort.order !== 'ascend';
  }
  F.pageSize = pagination.pageSize;
  F.page = pagination.current;
  return F;
};
