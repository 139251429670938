import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Timeline, Button, Table, Tag, message, Upload, Tabs} from 'antd';
import {licenseUpload, getConcat, licenseAfterUpload, licenseDownload} from '../../../api';
import './style.css';
// import {get} from '../../../utils/request';

import copyImg from '../../../assets/license-copy.png';
import SubImg from '../../../assets/license-Subtract.png';
import VecImg from '../../../assets/license-Vector.png';
import expsImg from '../../../assets/expires.png';
import expdImg from '../../../assets/expired.png';
import TimeImg from '../../../assets/license-time.png';
import {executeDownload} from '../../../api/fileDownload';
import {storage} from '../../../utils/storage';


const License = ({type}) => {
  const {t}=useTranslation();
  const TabPane = Tabs.TabPane;
  const homeType =storage.getData( 'userInfo')?.buildingType===2?'map':'building';
  // const [pagination, setPagination]=useState({
  //   pageSize: 10,
  //   current: 1,
  // });
  const addressRef=useRef(null);
  const [licenseData, setLicenseDate]=useState([]);
  const [serverName, setServerName]=useState('');
  const [officialAlgorithm, setOfficialAlgorithm]=useState('');
  const [officialCamera, setOfficialCamera]=useState('');
  const [trialAlgorithm, setTrialAlgorithm]=useState('');
  const [trialCamera, setTrialCamera]=useState('');
  const [trialOrderTime, setTrialOrderTime]=useState('');
  const [contact, setContact]=useState({});
  const [topDetail, setTopDetail]=useState({});
  const [tab, setTab]=useState('1');
  const [tableLoading, setTableLoading]=useState(false);
  const getAlgoDetail = ()=>{
    licenseAfterUpload(t('flag')).then((res)=>{
      setTopDetail(res.data);
      console.log('请求列表数据成功', res.data);
      setTableLoading(false);
      // 接口合并
      setLicenseDate(res.data?.algorithms);
      setServerName(res.data?.serverName);
      setOfficialAlgorithm(res.data?.officialAlgorithm);
      setOfficialCamera(res.data?.officialCamera);
      setTrialAlgorithm(res.data?.trialAlgorithm);
      setTrialCamera(res.data?.trialCamera);
      setTrialOrderTime(res.data?.trialOrderTime);
    }).catch((error)=>{
      // message.error('Something go wrong');
      setTableLoading(false);
    });
  };

  useEffect(() => {
    getAlgoDetail();
    getConcat().then((res)=>{
      setContact(res.data);
    });

    return (()=>{
    });
  }, [t('flag')]);
  // time line
  const timeline=(a, b)=>{
    return (
      <>
        <Timeline>
          <Timeline.Item dot={<div className='license-time'>{a}</div>}>
            <div className='license-step'>
              {t('step3')}
            </div>
            <div className='license-step1'>
              {t('step3-1')}
            </div>
            <div className='license-step1'>
              {t('step3-2')}
            </div>
          </Timeline.Item>
          <Timeline.Item dot={<div className='license-time'>{b}</div>}>
            <div className='license-step'>
              {t('step4')}
            </div>
            <Upload {...props}>
              <Button className='license-button'
                // disabled={a !==1?true:false}
              >{t('step4-upload')}</Button>
            </Upload>
            <div className='license-step1'>
              {t('step4-1')}
            </div>
            <div className='license-step1'>
              {t('step4-2')}
            </div>
          </Timeline.Item>
        </Timeline>
      </>
    );
  };
  // contact
  const contacts=()=>{
    return (
      <>
        <div className='license-line'></div>
        <div className='license-contact'>
          <div className='license-time1'>?</div><span>{t('contact')}</span>
        </div>
        <div className='license-contact1'>
          <div className='license-contact'>
            <img src={SubImg} alt="" /><span className='license-step1 license-contact2'>{contact.email}</span>
          </div>
          <div className='license-contact'>
            <img src={VecImg} alt="" /><span className='license-step1 license-contact2'>{contact.phone}</span>
          </div>
        </div>
      </>
    );
  };

  const algoColumn = [
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render(record) {
        console.log(record);
        return <div><img src={record !==1?expsImg:expdImg}></img></div>;
      },
    },
    {
      title: t('Algorithm Name'),
      dataIndex: 'algorithmName',
      key: 'algorithmName',

      render(record, item) {
        return <div>{record}
          {item.type===2&&<Tag style={{marginLeft: '8px'}} color={item.status===1?'magenta':'orange'}>{t('Trials')}</Tag>}
          {item.type===1&&item.expired&&<Tag style={{marginLeft: '8px'}} color={item.status===1?'magenta':'orange'}>{item.expired}</Tag>}
          {item.type===1&&!item.expired&&<div></div>}
        </div>;
      },
    },
    {
      title: t('StartTime'),
      dataIndex: 'startTime',
      key: 'startTime',
      render(x) {
        return x|| '-';
      },
    },
    {
      title: t('EndTime'),
      dataIndex: 'endTime',
      key: 'endTime',
      render(x) {
        return x|| '-';
      },
    },

  ];

  const cameraColumn = [
    {
      title: 'Camera No.',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('StartTime'),
      dataIndex: 'startTime',
      key: 'startTime',
      render(x) {
        return x|| '-';
      },

    },
    {
      title: t('EndTime'),
      dataIndex: 'endTime',
      key: 'endTime',
      render(x) {
        return x|| '-';
      },
    },
  ];

  const props = {
    showUploadList: false,
    multiple: false,
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      console.log(formData.get('file'));
      licenseUpload(formData, t('flag')).then((res)=>{
        message.success(res.msg);
        setTimeout(()=>{
          setTableLoading(true);
          getAlgoDetail();
        }, 1000);
      }).catch((error)=>{
        message.error(error);
      });
    },
    onChange(info) {
      if (info.file.status === 'done') {
        // message.success('License activated successfully');
        return;
      } else if (info.file.status === 'error') {
        message.error('Activation failed. The file you uploaded is incorrect');
      }
    },
  };
  return (
    <div>
      {type==='offLine'?(<div><div className="license-container">
        <div className="license-left">
          <div className="license-title">{t('steps')}</div>
          <div>
            <Timeline>
              <Timeline.Item dot={<div className='license-time'>1</div>}>
                <div className='license-step'>
                  {t('step1')}
                </div>
                <Button className='license-button' onClick={()=>{
                  licenseDownload(t('flag')).then((res)=>{
                    console.log('this is download place', res);
                    // decodeURI(res.headers['content-disposition']);
                    // console.log(res.headers['content-disposition']);
                    // console.log(res, '12313');
                    executeDownload(res, 'license.lic');
                    message.success('File download successfully');
                  }).catch((error)=>{
                    console.log(error);
                  });
                  // binaryFileDownLoad('172.16.10.239:7758/api/license/download', { });
                  // handleExport();
                }}>{t('down')}</Button>
              </Timeline.Item>
              <Timeline.Item dot={<div className='license-time'>2</div>}>
                <div className='license-step'>
                  {t('step2')}
                </div>
                <div className='license-step1'>
                  {t('step2-1')}
                </div>
                <div className='license-step1'>
                  {t('step2-2')}
                </div>
                <a href='http://license.hcytech.com.cn/' target="_blank" rel="noopener noreferrer" ref={addressRef}
                >{'http://license.hcytech.com.cn/'}
                </a>
                <div className='license-step2' onClick={()=>{
                  navigator.clipboard.writeText(addressRef.current.href);
                  message.success('Link copied successfully', 2);
                }}>
                  <img src={copyImg} alt="" /><span className='license-span'>{t('step2-3')}</span>
                </div>
              </Timeline.Item>
              {timeline(3, 4)}
            </Timeline>
            {contacts()}
          </div>
        </div>
        <div className="license-right">
          <div className='license-title'>{t('License details')}</div>
          <div className='license-information'>
            <div className='license-count'>{t('Server Name')}<span >{topDetail.serverName||'-'}</span></div>
            <div className='license-count'>{t('Server IP')}<span>{topDetail.serverIp||'-'}</span></div>
            {homeType==='map'&&<div className='license-count'>{t('Platform Type')}<span>{'Map'}</span></div>}
            {homeType==='building'&&<div className='license-count'>{t('Platform Type')}<span>{'Building'}</span></div>}
          </div>
          <div className='license-information'>
            <div className='license-count'>{t('Cameras')}{'[To start]'}<span>{topDetail.toStartOfficialCameras?
            topDetail.toStartOfficialCameras+'[official]': '0'+'[official]'
            }{'   '}{(topDetail.toStartTrialCameras?topDetail.toStartTrialCameras:0)+'[trial]'
            }</span></div>
            <div className='license-count'>{t('Cameras')}{'[In use]'}<span>{topDetail.inUseOfficialCameras?
            topDetail.inUseOfficialCameras+'[official]':'0'+'[official]'}
            {'   '}{(topDetail.inUseTrialCameras?topDetail.inUseTrialCameras:0)+'[trial]'
            }</span></div>
            <div className='license-count'>{t('Algorithms')}{'[To start]'}<span>
              {(topDetail.toStartOfficialAlgorithms?topDetail.toStartOfficialAlgorithms:0)+'[official]'}
              {' '}{(topDetail.toStartTrialAlgorithms?topDetail.toStartTrialAlgorithms:0)+'[trial]'}</span></div>
            <div className='license-count'>{t('Algorithms')}{'[In use]'}<span>
              {(topDetail.inUseOfficialAlgorithms?topDetail.inUseOfficialAlgorithms:0)+'[official]'}
              {' '}{(topDetail.inUseTrialAlgorithms?topDetail.inUseTrialAlgorithms:0)+'[trial]'}</span></div>

          </div>
          <Tabs tabPosition={'top'} onChange={(e)=>{
            setTab(e);
          }}>
            <TabPane tab={t('Algorithm Details')} key="1"></TabPane>
            <TabPane tab={t('Camera Details')} key="2"></TabPane>
          </Tabs>
          {/* 算法到期提醒 */}
          {topDetail?.trialExpire&&<div className='license-title1'><img src={TimeImg} style={{marginRight: '5px'}} alt="" />
            {trialOrderTime}{t('Notice')} : {topDetail?.trialExpire}</div>}
          <Table
            loading={tableLoading}
            style={{marginTop: 20}}
            columns={tab==='1'?algoColumn:cameraColumn}
            dataSource={tab==='1'?topDetail.soonToExpire:topDetail.camerasList}
          />
        </div>
      </div>
      </div>):(<div>
        <div className="license-container">
          <div className="license-left">
            <div className="license-title">{t('steps')}</div>
            <div>
              <Timeline>
                {timeline(1, 2)}
              </Timeline>
              {contacts()}
            </div>
          </div>
          <div className="license-right">
            <div style={{display: 'flex'}}><div className='license-title'>{t('License details')}</div>
            </div>
            <div className='license-information'>
              <div className='license-count'>{t('Server Name')}<span>{serverName}</span></div>
              <div className='license-count'>{t('Cameras')}
                <span>{trialCamera} {t('trial')},{officialCamera} {t('official')}</span>
              </div>
              <div className='license-count'>{t('Algorithms')}
                <span>{trialAlgorithm} {t('trial')},{officialAlgorithm} {t('official')}</span></div>
            </div>

            <Table dataSource={licenseData} rowKey={(record)=>record.id}>
              <Table.Column title={t('status')} dataIndex='status' key='status' render={ (record) =>{
                return (<div><img src={expsImg}></img></div>);
              }}/>
              <Table.Column title={'Algorithm Name'} dataIndex="algorithmName" key="algorithmName"
                render={ (record, item) =>{
                  return (<div>{record}{ item.trial==true&&
                  <Tag style={{marginLeft: '8px'}} color="orange">{t('Trial')}</Tag>}
                  </div>);
                }}
              />
              <Table.Column title={t('start time')} dataIndex="startTime" key="startTime"/>
              <Table.Column title={t('end time')} dataIndex="endTime" key="endTime"/>
            </Table>
          </div>
        </div>
      </div>)}
    </div>
  );
};

License.propTypes = {
  type: PropTypes.string.isRequired,
};

export default License;
