import React, {useEffect, useState} from 'react';
import {storage} from '../../utils/storage';
import {Cascader, message, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {getAreaSettingList} from '../../api';

let alarmTypes = storage.getData('alarmType')||[];

export const alarmTypeFuc = (typeCode) => {
  const flag = storage.getData('i18nextLng')==='zh'?'2':'1';
  const alarm=alarmTypes.find((item)=>item.alarmType===parseInt(typeCode));
  if (alarm) {
    return flag==='1'?alarm.alarmTypeName:alarm.cnAlarmTypeName;
  } else if (alarmTypes.length===0) {
    alarmTypes = storage.getData('alarmType');
    alarmTypeFuc(typeCode);
  }
  return '';
};

export const alarmColorFuc = (typeCode) => {
  const alarm=alarmTypes.find((item)=>item.alarmType===parseInt(typeCode));
  if (alarm) {
    return alarm.alarmRgb;
  } else if (alarmTypes.length===0) {
    alarmTypes = storage.getData('alarmType');
    alarmTypeFuc(typeCode);
  }
  return '#D75353';
};

export const AlarmTypeSelectOption=(props)=>{
  const {t} = useTranslation();
  return (
    <Select {...props} style={{width: 180}}>
      <Select.Option value=''>{t('all')}</Select.Option>
      {alarmTypes&&alarmTypes.map((item)=>(
        <Select.Option
          key={item.alarmTypeName}
          value={item.alarmType}
        >{t('flag')==='1'?item.alarmTypeName:item.cnAlarmTypeName}</Select.Option>
      ))}
    </Select>
  );
};
export const MultiAlarmTypeSelectOption=(props)=>{
  const {t} = useTranslation();
  return (
    <Select {...props} mode="multiple">
      {alarmTypes&&alarmTypes.map((item)=>(
        <Select.Option
          key={item.alarmTypeName}
          value={item.alarmType.toString()}
        >{t('flag')==='1'?item.alarmTypeName:item.cnAlarmTypeName}</Select.Option>
      ))}
    </Select>
  );
};

export const processBuildingLevelAreaData = (areas, setFunc, type, flag)=>{
  const dataNew = [
  ];
  if (areas) {
    areas.forEach((area)=>{
      const buildingName = area.buildingId;
      const building = dataNew.filter((building) => building.key === buildingName);
      if (building.length>0) {
        const levelName = area.buildingLevelId;
        const level = building[0].children.filter((level) => level.key === levelName);
        const buildingIndex = dataNew.findIndex((building) => building.key == buildingName);
        if (level.length>0) {
          const levelIndex = level[0].index;
          const newArea = {
            index: dataNew[buildingIndex].children[levelIndex].children.length,
            value: area.areaId,
            label: area.areaName,
            key: area.areaId,
          };
          if (levelIndex!==-1) {
            dataNew[buildingIndex].children[levelIndex].children.push(newArea);
          }
        } else {
          const newLevel = {
            index: dataNew[buildingIndex].children.length??0,
            value: area.buildingLevelId,
            label: area.buildingLevelName,
            key: area.buildingLevelId,
            children: [
              {
                index: 0,
                value: area.areaId,
                label: area.areaName,
                key: area.areaId,
              },
            ],
          };
          if (buildingIndex!==-1) {
            dataNew[buildingIndex].children.push(newLevel);
          }
        }
        // ADD AREA
      } else {
        // IF NEW BUILDING NOT ADDED YET
        const newBuilding = {
          index: dataNew.length,
          value: area.buildingId,
          label: area.buildingName,
          key: area.buildingId,
          children: (type==='multilayer')?[
            {
              index: 0,
              value: -1,
              label: flag===1?'All':'全部',
              key: '-1',
            },
            {
              index: 1,
              value: area.buildingLevelId,
              label: area.buildingLevelName,
              key: area.buildingLevelId,
              children: [
                {
                  index: 0,
                  value: -1,
                  label: flag===1?'All':'全部',
                  key: '-1',
                },
                {
                  index: 1,
                  value: area.areaId,
                  label: area.areaName,
                  key: area.areaId,
                },
              ],
            },
          ]: [
            {
              index: 0,
              value: area.buildingLevelId,
              label: area.buildingLevelName,
              key: area.buildingLevelId,
              children: [
                {
                  index: 0,
                  value: area.areaId,
                  label: area.areaName,
                  key: area.areaId,
                },
              ],
            },
          ],
        };
        dataNew.push(newBuilding);
      }
    });
    setFunc(dataNew);
  }
};

export const BuildCascader=(props)=>{
  /* eslint-disable */
  const {t} = useTranslation();
  const [buildingLevelAreaOptions, setBuildingLevelAreaOptions] = useState([]);
  const getFullAreaList = ()=>{
    getAreaSettingList({
      pageNum: 1,
      pageSize: 100000,
    }, t('flag')).then((res)=>{
      const items = res.data.items;
      items.map((item, index)=>{
        item.key = index;
      });
      processBuildingLevelAreaData(items, setBuildingLevelAreaOptions, props.type, t('flag'));
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  useEffect(()=>{
    getFullAreaList();
  }, []);
  return (
    <Cascader
      options={buildingLevelAreaOptions}
      expandTrigger="hover"
      style={{width: 300}}
      onChange={props.onChange}
    />
  );
  /* eslint-disable*/
};

export const AreaSelect=(props)=>{
  /* eslint-disable */
  const {t} = useTranslation();
  const [areaOptions, setAreaOptions] = useState([]);
  const getFullAreaList = ()=>{
    getAreaSettingList({
      pageNum: 1,
      pageSize: 100000,
    }, t('flag')).then((res)=>{
      setAreaOptions(res.data.items)
      
    }).catch((error)=>{
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    });
  };
  useEffect(()=>{
    getFullAreaList();
  }, []);
  return (
    <Select
      onChange={props.onChange}
    >
      {areaOptions.map(({areaId,areaName})=>(
        <Select.Option key={areaId+'area'} value={areaId}>{
          areaName
      }</Select.Option>
        ))}
    </Select>
  );
  /* eslint-disable*/
};
