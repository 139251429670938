import React, {useEffect, useState} from 'react';
import {Button, message, Space, Upload, Spin, Row, Col} from 'antd';

import PropTypes from 'prop-types';
import {camerasUpLoad} from '../../../../api';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
const MAXSIZE = 10*1024*1024;// 15M
// import map from '../../assets/1.png';
const CanvasPaint = ({url, id, finishFuc}) => {
  const {t} = useTranslation();
  let isDraw = false;
  let arr = [];
  const [active, setActive] = useState('');
  const [canvasFile, setCanvasFile] = useState(false);
  const [fileList, setFileList] = useState({file: null, url: ''});
  const [draw, setDraw] = useState(true);
  const [position, setPosition] = useState('');
  const [loading, setLoading] = useState(false);
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: (info)=>{
    },
    onChange: (e)=>{
      if (e.file.size<MAXSIZE) {
        fileList.file=e.file;
        getBase64(e.file.originFileObj, (imageUrl)=>{
          fileList.url=imageUrl;
          setFileList(fileList);
          const canvas2D = document.getElementById('canvas');
          const ctx = canvas2D.getContext('2d');
          const img = new Image();
          // 指定图片的URL
          img.src = fileList.url;
          // 浏览器加载图片完毕后再绘制图片
          img.onload = ()=> {
            ctx.clearRect(0, 0, 1920, 1080);
            // 以Canvas画布上的坐标(10,10)为起始点，绘制图像
            ctx.drawImage(img, 0, 0 );
            setCanvasFile(true);
          };
        });
        setDraw(false);
      } else {
        message.warn('The picture is too large, please upload the picture less than 10M.');
      }
    },
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const getLocation=(x, y)=> {
    const canvas = document.getElementById('canvas');
    const bbox = canvas.getBoundingClientRect();
    return [
      (x - bbox.left) * (canvas.width / bbox.width),
      (y - bbox.top) * (canvas.height / bbox.height),
    ];
  };
  const mouseEvent = (e) => {
    // let arr=[];
    const canvas2D = document.getElementById('canvas');
    const ctx = canvas2D.getContext('2d');
    e.persist();
    if (e.type === 'mousedown') {
      isDraw = true;
      arr = [];
    }
    if (e.type === 'mousemove' && isDraw) {
      switch (active) {
        case 'rectangle': {
          arr.push(getLocation(e.clientX, e.clientY));
          const left = arr[0][0];
          const top = arr[0][1];
          const width = arr[arr.length - 1][0] - left;
          const height = arr[arr.length - 1][1] - top;
          ctx.beginPath();
          ctx.lineWidth = '2';
          ctx.strokeStyle = 'red';
          const img = new Image();
          // 指定图片的URL
          img.src = fileList.url?fileList.url:url;
          // 浏览器加载图片完毕后再绘制图片
          img.onload = ()=> {
            ctx.clearRect(0, 0, 1920, 1080);
            // 以Canvas画布上的坐标(10,10)为起始点，绘制图像
            ctx.drawImage(img, 0, 0 );
            ctx.strokeRect(left, top, width, height);
          };
          return;
        }
        default:
          return;
      }
    }
    if (e.type === 'mouseup') {
      // const image=canvas2D.toDataURL('image/jpeg');
      // setCanvasFile(image);
      if (active) {
        const p=arr.length>1?[...arr[0], ...arr[arr.length-1]]:[];
        console.log(p.join(','));
        setPosition(p.join(','));
        arr = [];
        setActive('');
        setCanvasFile(true);
      }
      isDraw = false;
    }
  };

  const uploadImage=()=>{
    const canvas2D = document.getElementById('canvas');
    // const ctx = canvas2D.getContext('2d');
    const formData = new FormData();

    canvas2D.toBlob((blob)=>{
      setLoading(true);
      const name=moment().format('YYYYMMDDHHmmss')+'.jpg';
      formData.append('file', blob, name);
      // formData.append('file', canvasFile);
      setDraw(false);
      formData.append('ordinates', position);
      formData.append('id', id);
      // console.log(formData);
      camerasUpLoad( formData, t('flag')).then((res)=>{
        message.success(res.msg);
        finishFuc();
      }).catch((error)=>{
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(()=>{
        setLoading(false);
      });
    }, 'image/jpeg', 1);
  };

  useEffect(()=>{
    if (url) {
      const canvas2D = document.getElementById('canvas');
      const ctx = canvas2D.getContext('2d');
      const img = new Image();
      // URL
      img.src = url;
      img.crossOrigin = 'anonymous';
      img.onload = ()=> {
        ctx.drawImage(img, 0, 0 );
      };
    }
  }, []);

  return (
    <div>
      <Spin spinning={loading}>
        <Row>
          <Col span={16}>
            <Space>
              <Upload
                name="avatar"
                showUploadList={false}
                {...props}
              >
                <Button type="normal">{t('add')}</Button>
              </Upload>
              <Button onClick={() => setActive('rectangle')} type="normal" disabled={draw}>
                {t('mark')}
              </Button>
            </Space>
          </Col>
          <Col span={8} className="text-right" >
            <Button onClick={uploadImage} className="full-btn" disabled={!canvasFile}>
              {t('submit')}
            </Button>
          </Col>
        </Row>
        <div className="canvas-box">
          {(url&&!canvasFile) && <img src={url} />}
          <canvas
            id="canvas"
            width={1920}
            height={1080}
            onMouseDown={mouseEvent}
            onMouseMove={mouseEvent}
            onMouseUp={mouseEvent}
          />
        </div>
      </Spin>
    </div>
  );
};

CanvasPaint.propTypes = {
  url: PropTypes.string,
  id: PropTypes.number,
  finishFuc: PropTypes.func.isRequired,
};

export default CanvasPaint;
