
export const columnsForm=[
  {
    label: 'id',
    name: 'id',
    type: 'number',
    hidden: true,
  },
  {
    label: 'buildingName',
    name: 'buildingId',
    type: 'LevelForm',
    required: true,
    message1: ' Please select building name!',
    message2: '请选择楼栋!',
    hidden: false,
  },
  {
    label: 'levelName',
    name: 'levelName',
    type: 'primaryInput',
    required: true,
    message: 'Please select level name!',
    message2: '请选择楼层!',
    hidden: false,
  },
  {
    label: 'image',
    name: 'image',
    type: 'image',
    required: true,
    message1: 'Please add Image',
    message2: '请添加照片!',
    hidden: false,
  },
];
