import React, {useState, useEffect} from 'react';
import {Button, Drawer, Image, message, Modal, Popconfirm, Space, Switch, Tag, Spin} from 'antd';
import {addCameras, deleteCameras, updateCameras, updateCamerasStatus, getAIListByTrialType,
  getAIlistArray, unBindingBuilding,
} from '../../../api';
import {CAMERALIST} from '../../../api/Urls';
// import {alarmTypeFuc} from '../../../components/baseTypeSelect';

import './style.css';
import imgError from '../../../assets/imgError.png';
import {useTranslation} from 'react-i18next';

import CanvasPaint from './components/CanvasPaint';
import EditForms from '../../../components/forms/EditForms';
import CustomTable from '../../../components/customTable';
import PositionMap from './components/PositionMap';
import {storage} from '../../../utils/storage';

const CameraManagement = () => {
  const {t} = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const homeType =storage.getData( 'userInfo')?.buildingType===2?'map':'building';
  const [updateList, setUpdateList] = useState(0);
  const [allAIlistArray, setAllAIlistArray] = useState([]);
  const [initCasaderData, setInitCasaderData] = useState([]);
  const [initCasaderDataName, setInitCasaderDataName] = useState([]);
  // const [allCasaDerData, setAllCasaDerData] = useState([]);
  const [onSaving, setOnSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unBindModalVisible, setUnBindModalVisible] = useState(false);
  const [unBindModalTitle, setUnBindModalTitle] = useState('Unbinding buildings');
  // const [casaderChange, setCasaderChange] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [mapDrawer, setMapDrawer] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [initData, setInitData] = useState(null);
  const [cameraID, setCameraID] = useState(null);
  const [modalTitle, setModalTitle] = useState('add');
  const [cameraUrl, setCameraUrl] = useState('');
  // 算法列表的值
  const [getAIlist, setGetAIlist] = useState([]);
  const getSame=(arr1, arr2)=>{
    return arr1?.filter((item)=>arr2?.includes(item.id),
    );
  };
  const columnsForm=[
    {
      label: 'id',
      name: 'id',
      type: 'primaryInput',
      hidden: true,
    },
    {
      label: 'Type',
      name: 'type',
      type: 'radio',
      hidden: false,
    },
    {
      label: 'cameraName',
      name: 'cameraName',
      type: 'primaryInput',
      required: true,
      message1: 'Please input camera name!',
      message2: '请输入摄像头名称!',
      hidden: false,
    },
    {
      label: homeType==='map'?'area':'buildingLevelArea',
      name: 'location',
      type: homeType==='map'?'areaForm':'cascader',
      required: true,
      message1: homeType==='map'?
        'Please select area name!':'Please select building name and level name!',
      message2: '请选择摄像头所在区域！',
    },
    /* eslint-disable */  
    {
      label: 'coordinate',
      name: 'coordinate',
      regular: /^[\(\（]?\s*\d{1,2}(\.\d{1,2})?\s*[\,\，]\s*\d{1,2}(\.\d{1,2})?\s*[\)\）]?$/,
      regularMessage1: 'Please enter the correct coordinate format, such as (30,50)',
      regularMessage2: '请输入正确的坐标格式，例如（30,50）,数字大小在0~100之间',
      type: 'primaryInputLocation',
      required: true,
      hidden: homeType==='map',
      disabled: homeType==='map',
      message1: 'Please input coordinate!',
      message2: '请输入坐标',
      placeholders1: 'Enter the coordinate position of the camera (x, y), such as 30,50）Number size between 0 and 100',
      placeholders2: '请输入摄像头相对坐标，例如（30,50）,数字大小在0~100之间',
    },
    {
      label: 'rtspUrl',
      name: 'rtspUrl',
      type: 'primaryInput',
      required: true,
      maxLength: 200,
      message1: 'Please input rtsp url!',
      message2: '请输入rtsp url!',
    },
    {
      label: 'algorithmType',
      name: 'algorithmType',
      type: 'alarmTypes',
      required: false,
      message: '',
    },
    {
      label: 'cameraType',
      name: 'cameraType',
      type: 'select',
      required: true,
      message1: 'Please select type!',
      message2: '请选择摄像头类型！',
      options: [
        {value: 1,
          label1: 'Entrance',
          label2: '入口',
        },
        {value: 2,
          label1: 'Exit',
          label2: '出口',
        },
        {value: 3,
          label1: 'Other',
          label2: '其他',
        },
      ],
    },
    {
      label: 'cameraFramerate',
      name: 'cameraFramerate',
      type: 'number',
      required: true,
      message1: 'Please input camera framerate!',
      message2: '请选择摄像头刷新频率！',
      min: 1,
      max: 50,
      unit: '',
      step: 1,
    },
    {
      label: 'cameraDescribe',
      name: 'cameraDescribe',
      type: 'textArea',
      required: false,
      message: '',
    },
  ];

  const cameraEnable = (record, value) => {
    setLoadingSwitch(true);
    updateCamerasStatus({
      id: record.id,
      faceRecognitionState: value === true ? 1 : 0,
    }, t('flag')).then((res) => {
      message.success(res.msg);
      setUpdateList(updateList + 1);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      setUpdateList(updateList + 1);
      setLoadingSwitch(false);
    });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.isEnable === 1, // Column configuration not to be checked
    }),
  };
  const onFinish = (values) => {
    setOnSaving(true);
    console.log(values.location, initCasaderData,values.algorithmType, 'initCasaderData values');
    if (JSON.stringify(values.location) === JSON.stringify(initCasaderDataName)) {
      values.location=homeType==='map'?values.location:initCasaderData.join(',');
      const RequestPromise = modalTitle === 'add' ?
        addCameras(values, t('flag')) :
        updateCameras(values, t('flag'));
      RequestPromise.then((res)=> {
        message.success(res.msg);
        setOnSaving(false);
        setIsModalVisible(false);
        setModalTitle('add');
        setCameraID(null);
        setInitData(null);
        setUpdateList(updateList + 1);
      }).catch((error)=>{
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(()=>{
        setOnSaving(false);
      });
    } else {
      values.location=homeType==='map'?values.location:values.location.join(',');
      const RequestPromise = modalTitle === 'add' ?
        addCameras(values, t('flag')) :
        updateCameras(values, t('flag'));
      RequestPromise.then((res)=> {
        message.success(res.msg);
        setOnSaving(false);
        setIsModalVisible(false);
        setModalTitle('add');
        setCameraID(null);
        setInitData(null);
        setUpdateList(updateList + 1);
      }).catch((error)=>{
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(()=>{
        setOnSaving(false);
      });
    }
  };
  const getAIListFunc = async () => {
    // console.log('获取算法列表函数');
    const res = await getAIlistArray(t('flag'))
    if(res.code===200){
      setAllAIlistArray(res.data);
    }else if(res.code===457){
      setAllAIlistArray(res.data);
    }else{
      message.error(res.msg);
    }
  }
  useEffect(()=>{
    getAIListFunc();
    // getAIlistArray(t('flag')).then((res) => {
    //   console.log(res, '获取算法列表');
    //   setAllAIlistArray(res.data);
    // }).catch((error)=>{
    //   message.error(error);
    // },
    // );
  }, []);
  const onCancel=()=> {
    setIsModalVisible(false);
    setModalTitle('add');
    setInitData(null);
    setCameraID(null);
  };

  const onEdit = (data) => {
    console.log(getAIlist, '此时的AIlist的值');
    setModalTitle('edit');
    setCameraID(data.id);
    setInitData({
      ...data,
      location: homeType==='map'?data.areaId:data.buildingId?[
        data.buildingName, data.buildingLevelName, data.areaName]:[],
      cameraType: data.cameraType !== 0 ? data.cameraType : null,
      cameraFramerate: data.cameraFramerate||null,
    });
    setIsModalVisible(true);
  };
  const alarmTypeFucData = (typeCode) => {
    const flag = storage.getData('i18nextLng')==='zh'?'2':'1';
    const alarm=allAIlistArray.find((item)=>parseInt(item.id)===parseInt(typeCode));
    if (alarm) {
      return flag==='1'?alarm.algorithmName:alarm.algorithmNameCn;
    } else {
      return '';
    }
  };
  const handleDelete = (id) => {
    deleteCameras(id, t('flag')).then((res) => {
      message.success(res.msg);
      setIsModalVisible(false);
    }).catch((error) => {
      message.error({
        content: error.toString(),
        key: 'netError',
        duration: 2,
      });
    }).finally(() => setUpdateList(updateList + 1));
  };

  const marker = () => {
    setCameraUrl('');
    setCameraID('');
    setEditDrawer(false);
    setUpdateList(updateList+1);
  };

  /*eslint-disable*/
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      width: 60,
      
    },
    {
      title: t('Type'),
      dataIndex: 'Type',
      key: 'Type',
      width: 82,
      // ellipsis: true,
      
      render: (_,state) => state.type === 1 ? t('officialItem') : t('trialItem'),
    },
    {
      title: t('cameraName'),
      dataIndex: 'cameraName',
      key: 'cameraName',
      onExport: true,
      search: true,
      width: 150,
     
    },
    {
      title: t('areaNameNew'),
      dataIndex: 'areaName',
      key: 'areaName',
      onExport: true,
      search: true,
      width: 100,
      ellipsis: true,
    },
    {
      title: t('rtspUrl'),
      dataIndex: 'rtspUrl',
      key: 'rtspUrl',
      onExport: true,
      width: 190,
      ellipsis: true,
    },
    {
      title: t('status'),
      dataIndex: 'state',
      key: 'state',
      width: 80,
      onExport: true,
      // ellipsis: true,
      render: (state) => state === 1 ? t('online') : t('offline'),
      exportRender: (state) => state.state === 1 ? t('online') : t('offline'),
    },
    {
      title: t('validDate'),
      dataIndex: 'startTime',
      width: 150,
      key: 'startTime',
      onExport: true,
      ellipsis: true,
      render: (_,state) => {
        console.log(state, 'state');
        if(state.startTime && state.endTime){
          return (<div><span>{t('cameraStartTime')}{state.startTime}</span><br/><span>{t('cameraEndTime')}{state.endTime}</span></div>);
        }
        else if(state.startTime && !state.endTime){
          return (<div><span>{t('permanent')}</span></div>);
        }
        else if(!state.startTime && !state.endTime){
          return (<div><span>{t('cameraStartTime')}{'-'}</span><br/><span>{t('cameraEndTime')}{'-'}</span></div>);
        }
        else if(!state.startTime && state.endTime){
          return (<div><span>{t('cameraStartTime')}{'-'}</span><br/><span>{t('cameraEndTime')}{state.endTime}</span></div>);
        }
      },
    },
    {
      title: t('description'),
      dataIndex: 'cameraDescribe',
      key: 'cameraDescribe',
      onExport: true,
      width: 100,
      ellipsis: true,
    },
    {
      title: t('cameraPhotos'),
      dataIndex: 'cameraPhotos',
      key: 'cameraPhotos',
      width: 110,
      onExport: true,
      ellipsis: true,
      render: (cameraPhotos) => (cameraPhotos?<Image src={cameraPhotos} width={100} fallback={imgError}/>:<div></div>),
    },
    {
      title: t('algorithm'),
      dataIndex: 'algorithmType',
      key: 'algorithm',
      width: 190,
      onExport: true,
      ellipsis: true,
      render: (algorithmType) => algorithmType && (<Space wrap size="small">
        {algorithmType.split(',').map((item) => (
          <Tag key={'algorithm' + item}>
            {alarmTypeFucData(item)}
          </Tag>
        ))}
      </Space>),
      exportRender: (algorithmType) => algorithmType.algorithmType ?
        algorithmType.algorithmType.split(',').map((item) => alarmTypeFucData(item)).join(',') : '',
    },
    {
      title: t('cameraTypeNew'),
      dataIndex: 'cameraType',
      key: 'cameraType',
      onExport: true,
      ellipsis: true,
      width: 80,
      render: (cameraType) => cameraType === 1 ? t('entrance') :
        cameraType === 2 ? t('exit') :
          cameraType === 3 ? t('others') : '',
      exportRender: (cameraType) => cameraType.cameraType === 1 ? t('entrance') :
        cameraType.cameraType === 2 ?  t('exit') :
          cameraType.cameraType === 3 ? t('others') : '',
    },
    {
      title: t('switch'),
      dataIndex: 'isEnable',
      key: 'isEnable',
      width: 75,
      ellipsis: true,
      render: (isEnable, record) => <Switch
        loading={loadingSwitch}
        checked={isEnable === 1}
        size="small"
        onChange={(value) => cameraEnable(record, value)}
      />,
    },
    {
      title: t('action'),
      key: 'Action',
     
      width: 250,
      render: (record) => (<Space size="small">
        <Button type="link" disabled={record.isEnable === 1} size="small" onClick={() => {
          setInitCasaderData([ record.buildingId, record.buildingLevelId, record.areaId]);
          setInitCasaderDataName([ record.buildingName, record.buildingLevelName, record.areaName]);
          // 获取算法列表
          getAIListByTrialType(record.type===1?1:2,t('flag')).then((res)=>{
            console.log(res, '点击成功获取算法列表值')
            setGetAIlist(res.data);
            
            onEdit({...record, algorithmType: record.algorithmType ? getSame(res.data, record.algorithmType.split(','))?.map((item)=>item.id) : [],});
          }).catch((error) => {
            message.error(error);
          });

        }}>
          {t('edit')}
        </Button>
        <Button type="link" size="small" onClick={() => {
          setCameraID(record.id);
          setCameraUrl(record.cameraPhotos);
          setEditDrawer(true);
        }}>
          {t('mark')}
        </Button>
        {homeType==='map'&&(<Button type="link" size="small" onClick={() => {
          setLocationData({
            id: record.id,
            coordinate: record.coordinate ? record.coordinate.split(',') : '',
            cameraAddress: record.cameraAddress || '',
          })
          setMapDrawer(true);
        }}>
          {t('location')}
        </Button>)}
        <Popconfirm
          title={t('cameraDelMsg')}
          onConfirm={() => handleDelete(record.id)}
          color="#102864"
        >
          <Button type="link">{t('delete')}</Button>
        </Popconfirm>
      </Space>),
    },
  ];
  /*eslint-disable*/
  return (
    <div>
      <Spin spinning={loadingSwitch} className="loading-container">
      <CustomTable
       rowSelection={rowSelection}
      // 去掉本页面添加按钮
        unBindButton={() => {
          setUnBindModalVisible(true);
          setUnBindModalTitle('Unbinding buildings');
        }}
        width={700}
        scroll={true}
        columns={columns}
        InitApi={CAMERALIST}
        updateList={updateList}/>
      <Modal
        title={t(unBindModalTitle)}
        destroyAll
        visible={unBindModalVisible}
        width={700}
        onOk={()=>{
          unBindingBuilding(selectedRowKeys.toString(), t('flag')).then((res) => {
            console.log(res, '解绑楼宇信息成功');
            message.success(t('Unbind successfully'));
            setSelectedRowKeys([]);
            setUpdateList(updateList + 1);
            setUnBindModalVisible(false);
          }).catch((error)=>{
            message.error(error);
            // setUnBindModalVisible(false);
          },
          );
        }}
        onCancel={()=>{
          setUnBindModalVisible(false);
        }}>
        {t('UnbindBuilding')}
      </Modal>
      <Modal
        title={t(modalTitle)}
        destroyAll
        visible={isModalVisible}
        footer={null}
        width={700}
        onCancel={onCancel}>
        <EditForms
          columnsForm={columnsForm}
          loading={onSaving}
          initData={initData}
          onCancel={onCancel}
          isEdit={modalTitle === 'edit'}
          onFinish={onFinish}
          getAIlist={getAIlist}
          // casaderChange={casaderChange}
          // setCasaderChange={setCasaderChange}
        />
      </Modal>
      <Drawer
        onClose={marker}
        destroyOnClose
        title={t('mark')}
        visible={editDrawer}
        width={900}>
        <CanvasPaint url={cameraUrl} id={cameraID} finishFuc={marker}/>
      </Drawer>
      <Drawer
        onClose={()=>setMapDrawer(false)}
        destroyOnClose
        title={t('cameraLocation')}
        visible={mapDrawer}
        width={900}
      >
        <PositionMap
          locationData={locationData}
          map={{}}
          onFinish={()=>{
            setUpdateList(updateList+1);
          }} />
      </Drawer>
      </Spin>
    </div>
  );
};

export default CameraManagement;
