
import {Card, Skeleton} from 'antd';
import React from 'react';

const Loading = () => {
  return <Card>
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
  </Card>;
};
export default Loading;
