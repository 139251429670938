export const columnsForm=[
  {
    label: 'id',
    name: 'id',
    type: 'number',
    hidden: true,
  },
  {
    label: 'alarmTypeName',
    name: 'alarmType',
    type: 'primaryInput',
    disabled: true,
  },
  {
    label: 'sendType',
    name: 'sendType',
    type: 'select',
    required: true,
    options: [
      {
        value: 'sms',
        label1: 'sms',
        label2: '短信',
      },
      {
        value: 'Portal Alert',
        label1: 'portalAlert',
        label2: '站内信',
      },
      {
        value: 'whatsapp',
        label1: 'whatsapp',
        label2: 'whatsapp',
      },
      {
        value: 'telegram',
        label1: 'telegram',
        label2: 'telegram',
      },
      {
        value: 'email',
        label1: 'Email',
        label2: 'Email',
      },
      // {text: t('whatsapp'), value: 'whatsapp'},
      // {text: t('telegram'), value: 'telegram'},
      // {text: t('email'), value: 'email'},
    ],
    message: 'Please select send type!',
    message2: '请选择警报方式！',
    mode: 'multiple',
  },
  {
    required: true,
    label: 'receiver',
    name: 'receiverId',
    type: 'alarmSetForm',
    message: 'Please select receiver!',
    message2: '请选择警报提醒用户!',
  },
];
