
/* Input box type and label:
1.duration (InputNumber/unit)
2.number (InputNumber)
3.primaryInput (Input)
4.radio (Radio.Group/Radio)
5.timePicker (TimePicker.RangePicker)
6.select (Select/Select.Option)
7.image (Upload)
*/
export const columnsForm=[
  {
    label: 'id',
    name: 'id',
    type: 'number',
    hidden: true,
  },
  {
    label: 'buildingName',
    name: 'buildingName',
    type: 'primaryInput',
    required: true,
    message1: 'Please input building name!',
    message2: '请输入楼栋名称!',
    hidden: false,
  },
  {
    label: 'buildingFullName',
    name: 'buildingFullName',
    type: 'primaryInput',
    required: false,
    message1: 'Please input building full name!',
    message2: '请输入楼栋全称!',
    hidden: false,
  },
  {
    label: 'address',
    name: 'address',
    type: 'primaryInput',
    required: false,
    message: 'Please input building address!',
    message2: '请输入楼栋地址!',
    hidden: false,
  },
  {
    label: 'cameraNumber',
    name: 'cameraNumber',
    type: 'primaryInputOfAccess',
    required: true,
    message: 'Please input building camera number!',
    message2: '请输入摄像头数量!',
    hidden: false,
  },

];
